import React, { Component } from 'react'
import style from 'styled-components'
import {
  Form,
  Input,
  Icon,
  Select,
  Row,
  Col,
  Button,
  DatePicker,
  Spin
} from 'antd'

const dateFormat = 'DD/MM/YYYY'

const FormItem = Form.Item
const StyledJobInfoForm = style.div``

class AddressInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filled: false,
      showReasonText: false,
      loadingSave: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.prev = this.prev.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.form.setFieldsValue(this.props.data)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && !this.state.filled) {
      this.props.form.setFieldsValue(nextProps.data)
      this.setState({
        filled: true
      })
    }
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loadingSave: true })
        const { getFieldValue } = this.props.form
        let newValues = { ...values }
        if (getFieldValue('website.hasWebsite') === 'No') {
          newValues['website']['url'] = ''
        }
        if (getFieldValue('signBoard.hasSignBoard') === 'No') {
          newValues['signBoard']['nameOnBoard'] = ''
        }
        if (
          getFieldValue('neighbourhood.feedback') !== 'Negative' &&
          getFieldValue('neighbourhood.feedback') !== 'Poor'
        ) {
          newValues['neighbourhood']['reason'] = undefined
        }
        if (getFieldValue('assessmentByAgent.feedback') !== 'Negative') {
          newValues['assessmentByAgent']['reason'] = undefined
        }
        if (getFieldValue('tradeLicense.seen') !== 'No') {
          newValues['tradeLicense']['reason'] = undefined
        }
        this.props.onSubmit(newValues)
      } else this.setState({ loadingSave: false })
    })
  }

  prev() {
    this.props.onPrev()
  }
  fillPermanentAddress = () => {
    let values = this.props.form.getFieldsValue()
    this.props.form.setFieldsValue({ permanentAddress: values.homeAddress })
  }
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form

    return (
      <StyledJobInfoForm>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('facilities.housing')}
                label="Additional Facilities / Housing*">
                {getFieldDecorator('facilities.housing', {
                  rules: [
                    {
                      required: true,
                      message:
                        'Please select your Additional Facilities / Housing'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Additional Facilities / Housing*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option key="N/A" value="N/A">
                      N/A
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('facilities.uniform')}
                label="Additional Facilities / Uniform*">
                {getFieldDecorator('facilities.uniform', {
                  rules: [
                    {
                      required: true,
                      message:
                        'Please select your Additional Facilities / Uniform'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Facilities / Uniform*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option key="N/A" value="N/A">
                      N/A
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('facilities.transportation')}
                label="Additional Facilities / Transportation*">
                {getFieldDecorator('facilities.transportation', {
                  rules: [
                    {
                      required: true,
                      message:
                        'Please enter the Additional Facilities / Transportation'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Facilities / Transportation*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option key="N/A" value="N/A">
                      N/A
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('facilities.variablePay')}
                label="Additional Facilities / Variable Pay*">
                {getFieldDecorator('facilities.variablePay', {
                  rules: [
                    {
                      required: true,
                      message: 'Additional Facilities / Variable Pay'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Facilities / Variable Pay*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option key="N/A" value="N/A">
                      N/A
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('airTicket')}
                label="Air ticket with frequency">
                {getFieldDecorator('airTicket', {
                  rules: [
                    {
                      required: getFieldValue('facilities.variablePay') === 'Yes' ? true : false,
                      message: 'Please enter the Air ticket with frequency'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select the Air ticket with frequency*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option key="N/A" value="N/A">
                      N/A
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('airTicketType')}
                label="Air ticket with type*">
                {getFieldDecorator('airTicketType', {
                  rules: [ 
                    {
                      required: getFieldValue('airTicket') === 'Yes' ? true : false,
                      message: 'Please enter the Air ticket with type'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select the Air ticket with type*">
                    <Select.Option key="Annually" value="Annually">
                      Annually
                    </Select.Option>
                    <Select.Option key="Bi-annually" value="Bi-annually">
                      Bi-annually
                    </Select.Option>
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('cntEmp')}
                label="Employee Count*">
                {getFieldDecorator('cntEmp', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the Employee Count'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Employee Count*">
                    <Select.Option key="Less than 5" value="Less than 5">
                      Less than 5
                    </Select.Option>
                    <Select.Option key="Less than 10" value="Less than 10">
                      Less than 10
                    </Select.Option>
                    <Select.Option key="Less than 15" value="Less than 15">
                      Less than 15
                    </Select.Option>
                    <Select.Option key="Less than 20" value="Less than 20">
                      Less than 20
                    </Select.Option>
                    <Select.Option key="20-30" value="20-30">
                      20-30
                    </Select.Option>
                    <Select.Option key="30-50" value="30-50">
                      30-50
                    </Select.Option>
                    <Select.Option key="50-75" value="50-75">
                      50-75
                    </Select.Option>
                    <Select.Option key="75-100" value="75-100">
                      75-100
                    </Select.Option>
                    <Select.Option key="100-125" value="100-125">
                      100-125
                    </Select.Option>
                    <Select.Option key="125-200" value="125-200">
                      125-200
                    </Select.Option>
                    <Select.Option key="200+" value="200+">
                      200+
                    </Select.Option>
                    <Select.Option key="N/A" value="N/A">
                      N/A
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('totalNumOfEmpl')}
                label="Total Number of Employees">
                {getFieldDecorator('totalNumOfEmpl', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the Total Number of Employees'
                    }
                  ]
                })(<Input placeholder="Total Number of Employees" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('dateOfInception')}
                label="Business inception date">
                {getFieldDecorator('dateOfInception', {})(
                  <DatePicker
                    placeholder="Date"
                    format={dateFormat}
                    disabled={this.props.view}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('website.hasWebsite')}
                label="Website URL*">
                {getFieldDecorator('website.hasWebsite', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select one Option'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Has Website*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option
                      key="Some other / group company"
                      value="Some other / group company">
                      Some other / group company
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue(`website.url`)}
                label="Website URL">
                {getFieldDecorator(`website.url`, {
                  rules: [
                    {
                      required: getFieldValue('website.hasWebsite') === 'Yes' ? true : false,
                      message: 'Please select one Option'
                    }
                  ]
                })(<Input placeholder="Website URL" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('signBoard.hasSignBoard')}
                label="Whether sign board displayed*">
                {getFieldDecorator('signBoard.hasSignBoard', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select one Option'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Whether sign board displayed*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option
                      key="Some other / group company"
                      value="Some other / group company">
                      Some other / group company
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('signBoard.nameOnBoard')}
                label="SignBoard Name on Board*">
                {getFieldDecorator('signBoard.nameOnBoard', {
                  rules: [
                    {
                      required:
                        getFieldValue('signBoard.hasSignBoard') !== 'No'
                          ? true
                          : false,
                      message: 'Please enter the Name On Board'
                    }
                  ]
                })(<Input placeholder="SignBoard Name On Board" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('neighbourhood.feedback')}
                label="Neighbourhood Feedback*">
                {getFieldDecorator('neighbourhood.feedback', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the feedback'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Neigbourhood Feedback*">
                    <Select.Option key="Positive" value="Positive">
                      Positive
                    </Select.Option>
                    <Select.Option key="Negative" value="Negative">
                      Negative
                    </Select.Option>
                    <Select.Option key="Poor" value="Poor">
                      Poor
                    </Select.Option>
                    <Select.Option key="N/A" value="N/A">
                      N/A
                    </Select.Option>
                    <Select.Option key="Others" value="Others">
                      Others
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('neighbourhood.reason')}
                label="Neighbourhood Reason*">
                {getFieldDecorator('neighbourhood.reason', {
                  rules: [
                    {
                      required:
                        getFieldValue('neighbourhood.feedback') ===
                          'Negative' ||
                        getFieldValue('neighbourhood.feedback') === 'Poor'
                          ? true
                          : false,
                      message: 'Please enter the Neighbourhood Reason'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Neigbourhood Reason*">
                    <Select.Option
                      key="No source in neighbourhood"
                      value="No source in neighbourhood">
                      No source in neighbourhood
                    </Select.Option>
                    <Select.Option key="No Security" value="No Security">
                      No Security
                    </Select.Option>
                    <Select.Option key="Access Denied" value="Access Denied">
                      Access Denied
                    </Select.Option>
                    <Select.Option key="Not Allowed" value="Not Allowed">
                      Not Allowed
                    </Select.Option>
                    <Select.Option
                      key="No Feedack provided"
                      value="No Feedack provided">
                      No Feedack provided
                    </Select.Option>
                    <Select.Option key="Others" value="Others">
                      Others
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('neighbourhood.reasonothers')}
                label="Neighbourhood Other Reason*">
                {getFieldDecorator('neighbourhood.reasonothers', {
                  rules: [
                    {
                      required:
                        getFieldValue('neighbourhood.reason') === 'Others'
                          ? true
                          : false,
                      message: 'Please enter another feedback reason'
                    }
                  ]
                })(<Input placeholder="Neighbourhood another Reason" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('residenceRef.relationship')}
                label="Reference Relationship">
                {getFieldDecorator('residenceRef.relationship')(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Reference Relationship">
                    <Select.Option key="Colleague" value="Colleague">
                      Colleague
                    </Select.Option>
                    <Select.Option key="Friend" value="Friend">
                      Friend
                    </Select.Option>
                    <Select.Option key="Relative" value="Relative">
                      Relative
                    </Select.Option>
                    <Select.Option key="Roommate" value="Roommate">
                      Roommate
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('residenceRef.name')}
                label="Reference Name">
                {getFieldDecorator('residenceRef.name')(<Input placeholder="Reference Name" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('residenceRef.phoneNumber')}
                label="Reference Phone Number">
                {getFieldDecorator('residenceRef.phoneNumber')(<Input placeholder="Reference Phone Number" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('homeCountryRef.relationship')}
                label="Home Country Reference Relationship">
                {getFieldDecorator('homeCountryRef.relationship')(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Home Country Reference Relationship*">
                    <Select.Option key="Colleague" value="Colleague">
                      Colleague
                    </Select.Option>
                    <Select.Option key="Friend" value="Friend">
                      Friend
                    </Select.Option>
                    <Select.Option key="Relative" value="Relative">
                      Relative
                    </Select.Option>
                    <Select.Option key="Roommate" value="Roommate">
                      Roommate
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`homeCountryRef.name`)}
                label="Home Country Reference Name">
                {getFieldDecorator(`homeCountryRef.name`)(<Input placeholder="Home Country Reference Name" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('homeCountryRef.phoneNumber')}
                label="Home Country Reference Phone Number">
                {getFieldDecorator('homeCountryRef.phoneNumber')(<Input placeholder="Home Country Reference Phone Number" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('homeCountryAddress.line1')}
                label="Home Country Address Line">
                {getFieldDecorator('homeCountryAddress.line1')(<Input placeholder="Home Country Address Line 1" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('homeCountryAddress.line2')}
                label="Home Country Address Line 2">
                {getFieldDecorator('homeCountryAddress.line2', {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Home Country Address Line 2'
                    }
                  ]
                })(<Input placeholder="Home Country Address Line 2" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('homeCountryAddress.line3')}
                label="Home Country Address Line 3">
                {getFieldDecorator('homeCountryAddress.line3', {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Home Country Address Line 3'
                    }
                  ]
                })(<Input placeholder="Home Country Address Line 3" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('homeCountryAddress.landmark')}
                label="Home Country Address Landmark">
                {getFieldDecorator(`homeCountryAddress.landmark`, {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Home Country Address Landmark'
                    }
                  ]
                })(<Input placeholder="Home Country Address Landmark" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue('homeCountryAddress.contactNumber')}
                label="Home Country  Contact Number">
                {getFieldDecorator('homeCountryAddress.contactNumber', {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Home Country Contact Number'
                    }
                  ]
                })(<Input placeholder="Home Country Contact Number" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('homeCountryAddress.country')}
                label="Home Country">
                {getFieldDecorator('homeCountryAddress.country', {
                  rules: [
                    { required: false, message: 'Please enter the country' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Home Country*">
                    {this.props.selectData.country
                      ? Object.keys(this.props.selectData.country).map(key => (
                          <Select.Option key={key} value={key}>
                            {this.props.selectData.country[key]}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('familyInUAE')}
                label="Family in UAE">
                {getFieldDecorator('familyInUAE', {
                  rules: [
                    {
                      required: false,
                      message: 'Please select one Option'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Family in UAE">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('assessmentByAgent.feedback')}
                label="Overall Assesment by Agent*">
                {getFieldDecorator('assessmentByAgent.feedback', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select one Option'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Overall Assesment by Agent*">
                    <Select.Option key="Positive" value="Positive">
                      Positive
                    </Select.Option>
                    <Select.Option key="Negative" value="Negative">
                      Negative
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('assessmentByAgent.reason')}
                label="Overall Assesment Reason*">
                {getFieldDecorator('assessmentByAgent.reason', {
                  rules: [
                    {
                      required:
                        getFieldValue('assessmentByAgent.feedback') ===
                        'Negative'
                          ? true
                          : false,
                      message: 'Please enter a negative Assessment Reason'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="AssessmentByAgent Reason*">
                    <Select.Option
                      key="Negative nghb check"
                      value="Negative nghb check">
                      Negative nghb check
                    </Select.Option>
                    <Select.Option key="Low Bus Act" value="Low Bus Act">
                      Low Bus Act
                    </Select.Option>
                    <Select.Option
                      key="Closed Office/Shop"
                      value="Closed Office/Shop">
                      Closed Office/Shop
                    </Select.Option>
                    <Select.Option
                      key="Unapproved Business"
                      value="Unapproved Business">
                      Unapproved Business
                    </Select.Option>
                    <Select.Option key="Staged Set-up" value="Staged Set-up">
                      Staged Set-up
                    </Select.Option>
                    <Select.Option key="Identity Theft" value="Identity Theft">
                      Identity Theft
                    </Select.Option>
                    <Select.Option
                      key="Only issuing visa - no business"
                      value="Only issuing visa - no business">
                      Only issuing visa - no business
                    </Select.Option>
                    <Select.Option key="Poor set-up" value="Poor set-up">
                      Poor set-up
                    </Select.Option>
                    <Select.Option
                      key="Owner/Applicate attempting to manipulate verf."
                      value="Owner/Applicate attempting to manipulate verf.">
                      Owner/Applicate attempting to manipulate verf.
                    </Select.Option>
                    <Select.Option key="No signboard" value="No signboard">
                      No signboard
                    </Select.Option>
                    <Select.Option
                      key="Office In Res build"
                      value="Office In Res build">
                      Office In Res build
                    </Select.Option>
                    <Select.Option
                      key="Others"
                      value="Others">
                      Others
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('tradeLicense.seen')}
                label="Trade License Seen and Clicked*">
                {getFieldDecorator('tradeLicense.seen', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the Trade License'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Trade License Seen and Clicked*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('tradeLicense.reason')}
                label="Trade License Reason*">
                {getFieldDecorator('tradeLicense.reason', {
                  rules: [
                    {
                      required:
                        getFieldValue('tradeLicense.seen') === 'No'
                          ? true
                          : false,
                      message: 'Please enter the Trade License Reason'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Trade License Seen and Clicked*">
                    <Select.Option key="Not Allowed" value="Not Allowed">
                      Not Allowed
                    </Select.Option>
                    <Select.Option
                      key="Only Seen not clicked"
                      value="Only Seen not clicked">
                      Only Seen not clicked
                    </Select.Option>
                    <Select.Option key="No TL present" value="No TL present">
                      No TL present
                    </Select.Option>
                    <Select.Option
                      key="TL of difference"
                      value="TL of difference">
                      TL of difference
                    </Select.Option>
                    <Select.Option
                      key="TL of difference"
                      value="TL of difference">
                      TL of difference
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`salaryTransfDate`)}
                label="Salary Transfer Date">
                {getFieldDecorator(`salaryTransfDate`, {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Home Salary Transfer Date'
                    }
                  ]
                })(<Input placeholder="Salary Transfer Date" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                className={this.haveValue('officeAppearance')}
                label="Office Appearance*">
                {getFieldDecorator('officeAppearance', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select the Office Appearance'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Office Appearance*">
                    <Select.Option key="Very good" value="Very good">
                      Very good
                    </Select.Option>
                    <Select.Option key="Good" value="Good">
                      Good
                    </Select.Option>
                    <Select.Option key="Average" value="Average">
                      Average
                    </Select.Option>
                    <Select.Option key="Poor" value="Poor">
                      Poor
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`feedback.officeLoc`)}
                label="How did you find the office location">
                {getFieldDecorator(`feedback.officeLoc`, {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Home Country Address Line 3'
                    }
                  ]
                })(
                  <Input.TextArea placeholder="How did you find the office location" />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`feedback.aboutApplicant`)}
                label="About Applicant">
                {getFieldDecorator(`feedback.aboutApplicant`, {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the applicant'
                    }
                  ]
                })(<Input.TextArea placeholder="About applicant" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`feedback.aboutCompany`)}
                label="About Company">
                {getFieldDecorator(`feedback.aboutCompany`, {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Company'
                    }
                  ]
                })(<Input.TextArea placeholder="About Company" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`feedback.additionalPoints`)}
                label="Additional Points*">
                {getFieldDecorator(`feedback.additionalPoints`, {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Additional Points'
                    }
                  ]
                })(<Input.TextArea placeholder="Additional Points*" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`feedback.overallComment`)}
                label="Overall Comments*">
                {getFieldDecorator(`feedback.overallComment`, {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Overall Comments'
                    }
                  ]
                })(<Input.TextArea placeholder="Overall Comments*" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`liability`)}
                label="Liability with other Banks/Finance companies">
                {getFieldDecorator(`liability`, {
                  rules: [
                    {
                      required: false,
                      message: 'Please enter the Additional Points'
                    }
                  ]
                })(
                  <Input.TextArea placeholder="Liability with other Banks/Finance companies" />
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`makaniNo`)}
                label="MakaniNo*">
                {getFieldDecorator(
                  `makaniNo`,
                  {}
                )(<Input.TextArea placeholder="Makani No*" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`latitude`)}
                label="Latitude*">
                {getFieldDecorator(
                  `latitude`,
                  {}
                )(<Input placeholder="Latitude*" /* disabled */ />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                className={this.haveValue(`longitude`)}
                label="Longitude*">
                {getFieldDecorator(
                  `longitude`,
                  {}
                )(<Input placeholder="Longitude*" /* disabled */ />)}
              </FormItem>
            </Col>
          </Row>
          <Row className="button-row">
            <Button style={{ marginRight: '8px' }} onClick={this.prev}>
              <Icon type="left" />
              Previous
            </Button>
            {!this.props.view && (
              <span>
                <Button type="primary" htmlType="submit">
                  Save
                  <Icon type="right" />
                </Button>
                {this.state.loadingSave && (
                  <span style={{ margin: '10px' }}>
                    <Spin />
                  </span>
                )}
              </span>
            )}
          </Row>
        </Form>
      </StyledJobInfoForm>
    )
  }
}
export default Form.create()(AddressInfo)
