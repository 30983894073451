import React, { Component } from 'react'
import request from '../utils/requestWrapper'
import { getMessages, parseJwt } from '../utils/utils'
import UserPassword from './UserPassword'

export default class UserVerification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openPasswordModal: true,
      user: null
    }
    this.closePasswordModal = this.closePasswordModal.bind(this)
  }

  closePasswordModal() {
    this.setState({
      openPasswordModal: false
    })
    this.props.history.push('/login')
  }

  componentDidMount() {
    let token = this.props.match.params.token

    request('/user/verify', 'POST', null, false, token).then(response => {
      if (response.status === 401) {
        getMessages(response)
        this.props.history.push('/login')
      } else if (response.status === 200) {
        let userInfo = JSON.parse(parseJwt(token).profile)
        let user = {
          id: userInfo['_id'],
          token
        }
        this.setState({
          user
        })
      }
    })
  }

  render() {
    return (
      <UserPassword
        user={this.state.user}
        show={this.state.openPasswordModal}
        handleClose={this.closePasswordModal}
        notLogged
      />
    )
  }
}
