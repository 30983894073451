import React from 'react'
import { Drawer, Button, Timeline } from 'antd'
import styled from 'styled-components'
import request from '../../utils/requestWrapper'
import Spinner from '../../utils/Spinner'
import moment from 'moment'

const Wrapper = styled.div`
  .ant-btn {
    border: none;
    background: transparent;
    width: 100%;
    text-align: left;
    &:hover,
    &:focus,
    &:active {
      border: none;
      background: transparent;
      color: initial;
    }
  }
`
const ContentWrapper = styled.div`
  .title {
    font-size: 25px;
    margin-bottom: 0.5em;
  }
  i.anticon {
    margin-right: 0.5em;
  }
  .ant-btn {
    width: 100%;
    height: 42px;
  }
  .details {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    span {
      font-size: 18px;
      margin-bottom: 1em;
    }
  }
`

export default class History extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false,
      data: []
    }
  }

  showDrawer = () => {
    let endPoint = `/${this.props.type}/${this.props.resource}/${this.props.id}/history`
    this.setState({ loading: true })
    request(endPoint).then(response => {
      this.setState({ data: response.data, visible: true, loading: false })
    })
  }

  onClose = () => {
    this.setState({ visible: false })
  }

  renderOperation = type => {
    let text = null
    let color = null
    switch (type) {
      case 'post':
        text = 'Created'
        color = 'green'
        break
      case 'get':
        text = 'View or downloaded'
        color = 'blue'
        break
      case 'move':
        text = 'Moved'
        color = 'blue'
        break
      case 'share':
        text = 'Shared'
        color = 'blue'
        break
      case 'delete':
        text = 'Deleted'
        color = 'red'
        break
      case 'patch':
        text = 'Updated'
        color = 'green'
        break
      default:
        text = 'Created'
        color = 'green'
    }
    return {
      text,
      color
    }
  }

  render() {
    const { data } = this.state
    let size = null
    if (data) {
      if (data.size >= 1000000) {
        size = `${(data.size / 1000000).toFixed(2)} Mb`
      } else {
        size = `${(data.size / 1000).toFixed(2)} Kb`
      }
    }
    return (
      <Wrapper>
        <Spinner loading={this.state.loading} />
        <Button icon="exclamation-circle" onClick={this.showDrawer}>
          History
        </Button>
        <Drawer
          closable={false}
          width="35%"
          placement="right"
          onClose={this.onClose}
          visible={this.state.visible}>
          {data.length > 0 ? (
            <ContentWrapper>
              <h3>History Detail</h3>
              <br />
              <hr />
              <Timeline>
                {data.map((item, key) => (
                  <Timeline.Item
                    key={key}
                    color={`${this.renderOperation(item.operation).color}`}>
                    {`${this.renderOperation(item.operation).text} by ${
                      item.createdBy.firstName
                    } ${item.createdBy.middleName} ${
                      item.createdBy.lastName
                    } on dated ${moment(item.createdAt).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}`}
                  </Timeline.Item>
                ))}
              </Timeline>
            </ContentWrapper>
          ) : (
            <ContentWrapper>
              <h3>History Detail</h3> <br /> <p>{`No History Found`}</p>
            </ContentWrapper>
          )}
        </Drawer>
      </Wrapper>
    )
  }
}
