import React, { Component } from 'react'
import { Spin, Modal } from 'antd'
import styled from 'styled-components'
import theme from './style-utils'

const StyledSpinner = styled.div`
  & .ant-spin-dot i {
    background-color: ${theme.primary};
  }
`
export default class Spinner extends Component {
  render() {
    return (
      <Modal
        visible={this.props.loading}
        title={null}
        closable={false}
        wrapClassName="ant-modal-spin-wrap"
        footer={null}
        className="ant-modal-spin">
        <StyledSpinner>
          <Spin size="large" spinning={true} />
        </StyledSpinner>
      </Modal>
    )
  }
}
