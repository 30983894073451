import React, { Component } from 'react'
import { Card, Col, Row } from 'antd'
import style from 'styled-components'
import Icon from '../utils/icon/Icon'
import { themes } from '../utils/style-utils'

const StyledDashboard = style.div`
section{
  display:flex;
  flex-direction:column;
}
& .ant-card-body{
  width:100%;
  padding:1em 1.5em;
  height:110px;
}
& .ant-card-body>div{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}
& .card-col{
  padding-bottom:20px;
}
h2{
  font-weight:bold;
}
& .card-first-row{
  display:flex;
  flex-direction:column;
  span{
    margin:6px 0;
    color: #777;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
& .card-second-row{
  with:50%;
  font-family: inherit;
  color: #333;
  font-size: 40px;
  font-weight: 400;
}
& .material-icon{
  color:${props => themes[props.theme].primary};
}
@media screen and (min-width: 768px)  {
  & .card-col{
    padding-left:20px;
    padding-right:20px;
  }
  h2{
    margin:1em;  
  }
}
`

export default class Dashboard extends Component {
  render() {
    return (
      <StyledDashboard theme={this.props.theme}>
        <h2>PROJECT BOARD</h2>
        <Row>
          <Col className="card-col" sm={24} md={12} lg={6}>
            <Card hoverable>
              <div className="card-first-row">
                <Icon type="&#xE8D3;" />
                <span>Team Members</span>
              </div>
              <div className="card-second-row">00</div>
            </Card>
          </Col>
          <Col className="card-col" sm={24} md={12} lg={6}>
            <Card hoverable>
              <div className="card-first-row">
                <Icon type="&#xE2C8;" />
                <span>Total Tasks</span>
              </div>
              <div className="card-second-row">00</div>
            </Card>
          </Col>
          <Col className="card-col" sm={24} md={12} lg={6}>
            <Card hoverable>
              <div className="card-first-row">
                <Icon type="&#xE0E0;" />
                <span>Active Tasks</span>
              </div>
              <div className="card-second-row">00</div>
            </Card>
          </Col>
          <Col className="card-col" sm={24} md={12} lg={6}>
            <Card hoverable>
              <div className="card-first-row">
                <Icon type="&#xE258;" />
                <span>issues</span>
              </div>
              <div className="card-second-row">00</div>
            </Card>
          </Col>
        </Row>
      </StyledDashboard>
    )
  }
}
