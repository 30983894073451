import React, { Component } from 'react'
import style from 'styled-components'
import {
  Form,
  Icon,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Button,
  Upload,
  message
} from 'antd'
import { getBase64 } from '../../utils/utils'
const FormItem = Form.Item
const dateFormat = 'DD/MM/YYYY'

const StyledPersonalInfo = style.div`
  & .upload-col{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
  }
  & .ant-upload,
  & .ant-upload-list-picture-card .ant-upload-list-item,
  & .ant-upload.ant-upload-select.ant-upload-select-picture-card{
    margin:0;
  }
  & .image-upload {
    width:102px;
    height:102px;
  }
  @media (min-width: 992px) {
    & .ant-col-lg-8{
      padding-right:10px;
    }
    & .ant-upload,
    & .ant-upload-list-picture-card .ant-upload-list-item,
    & .ant-upload.ant-upload-select.ant-upload-select-picture-card{
      margin:45px 0;
    }
  }
`
class PersonalIInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      loading: false,
      percent: null,
      previewVisible: false,
      previewImage: '',
      filled: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.haveValue = this.haveValue.bind(this)
    this.beforeUpload = this.beforeUpload.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePreview = this.beforeUpload.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && !this.state.filled) {
      this.props.form.setFieldsValue(nextProps.data)
      this.setState({
        imageUrl: nextProps.data.displayPic
          ? nextProps.data.displayPic.original
          : null,
        filled: true
      })
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.form.setFieldsValue(this.props.data)
      this.setState({ imageUrl: this.props.data.displayPic.original })
    }
  }
  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    })
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleCancel() {
    this.setState({ previewVisible: false })
  }

  beforeUpload(file, fileList) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJPG) {
      message.error('You can only upload JPG or PNG file!')
    }
    const isLt2M = file.size < 400000
    if (!isLt2M) {
      message.error('Image must smaller than 400kb!')
    }
    return isJPG && isLt2M
  }

  handleChange(info) {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true, percent: info.file.percent })
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      )
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.displayPic.original = this.state.imageUrl
        this.props.onSubmit(values)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { imageUrl } = this.state
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">
          {this.state.loading ? `${this.state.percent}%` : 'Choose'}
        </div>
      </div>
    )
    return (
      <StyledPersonalInfo>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={24} lg={4} className="upload-col">
              <FormItem colon={false} label="First Name">
                {getFieldDecorator(
                  'displayPic.original',
                  {}
                )(
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="//jsonplaceholder.typicode.com/posts/"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    disabled={this.props.view}>
                    {imageUrl ? (
                      <img className="image-upload" src={imageUrl} alt="" />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={20}>
              <Row>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('firstName')}
                    label="First Name*">
                    {getFieldDecorator('firstName', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the First Name'
                        }
                      ]
                    })(
                      <Input
                        disabled={this.props.view}
                        placeholder="First Name*"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('middleName')}
                    label="Middle Name">
                    {getFieldDecorator(
                      'middleName',
                      {}
                    )(
                      <Input
                        disabled={this.props.view}
                        placeholder="Middle Name"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('lastName')}
                    label="Last Name*">
                    {getFieldDecorator('lastName', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the Last Name'
                        }
                      ]
                    })(
                      <Input
                        disabled={this.props.view}
                        placeholder="Last Name*"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('email')}
                    label="Login/Office Email*">
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Login/Office Email!'
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!'
                        }
                      ]
                    })(
                      <Input
                        disabled={this.props.view}
                        placeholder="Login/Office Email*"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('personalEmail')}
                    label="Personal Email*">
                    {getFieldDecorator('personalEmail', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your personal email!'
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!'
                        }
                      ]
                    })(
                      <Input
                        disabled={this.props.view}
                        placeholder="Personal Email*"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('contactNumber1')}
                    label="Mobile Number*">
                    {getFieldDecorator('contactNumber1', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Mobile Number'
                        }
                      ]
                    })(
                      <Input
                        disabled={this.props.view}
                        placeholder="Mobile Number*"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('contactNumber2')}
                    label="Personal Number">
                    {getFieldDecorator('contactNumber2', {
                      rules: []
                    })(
                      <Input
                        disabled={this.props.view}
                        placeholder="Personal Number"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('gender')}
                    label="Gender*">
                    {getFieldDecorator('gender', {
                      rules: [
                        { required: true, message: 'Please select the gender' }
                      ]
                    })(
                      <Select
                        optionFilterProp="children"
                        showSearch
                        disabled={this.props.view}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .replace(/,/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Gender*">
                        <Select.Option key={'Male'} value={'Male'}>
                          Male
                        </Select.Option>
                        <Select.Option key={'Female'} value={'Female'}>
                          Female
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('dateOfBirth')}
                    label="Date Of Birth*">
                    {getFieldDecorator('dateOfBirth', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select your date of birth!'
                        }
                      ]
                    })(
                      <DatePicker
                        disabled={this.props.view}
                        format={dateFormat}
                        placeholder="Date of Birth*"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} lg={8}>
                  <FormItem
                    className={this.haveValue('nationality')}
                    colon={false}
                    label="Nationality*">
                    {getFieldDecorator('nationality', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the nationality'
                        }
                      ]
                    })(
                      <Select
                        optionFilterProp="children"
                        showSearch
                        disabled={this.props.view}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .replace(/,/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Nationality*">
                        {this.props.selectData.country
                          ? Object.keys(this.props.selectData.country).map(
                              key => (
                                <Select.Option key={key} value={key}>
                                  {this.props.selectData.country[key]}
                                </Select.Option>
                              )
                            )
                          : null}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('marital')}
                    label={'Marital Status'}>
                    {getFieldDecorator(
                      'marital',
                      {}
                    )(
                      <Select
                        optionFilterProp="children"
                        showSearch
                        disabled={this.props.view}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .replace(/,/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Marital Status">
                        <Select.Option key={'Single'} value={'Single'}>
                          Single
                        </Select.Option>
                        <Select.Option key={'Married'} value={'Married'}>
                          Married
                        </Select.Option>
                        <Select.Option key={'Separated'} value={'Separated'}>
                          Separated
                        </Select.Option>
                        <Select.Option key={'Divorced'} value={'Divorced'}>
                          Divorced
                        </Select.Option>
                        <Select.Option key={'Widowed'} value={'Widowed'}>
                          Widowed
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="button-row">
            <Button type="primary" htmlType="submit">
              Next
              <Icon type="right" />
            </Button>
          </Row>
        </Form>
      </StyledPersonalInfo>
    )
  }
}
export default Form.create()(PersonalIInfo)
