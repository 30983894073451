import React from 'react'
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'
import PropTypes from 'prop-types'

class MainMap extends React.PureComponent {
  render() {
    return (
      <GoogleMap
        ref={c => {
          this.props.onMapMounted(c, this.props)
        }}
        {...this.props}>
        {this.props.children}
      </GoogleMap>
    )
  }
}
export default withScriptjs(withGoogleMap(MainMap))

MainMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onMapMounted: PropTypes.func
}

MainMap.defaultProps = {
  onMapMounted: () => {}
}
