import React from 'react'
import {
  Button,
  Dropdown,
  Row,
  Col,
  Tooltip,
  Card,
  Menu,
  Upload,
  Icon,
  notification,
  Modal,
  Breadcrumb,
  message,
  Divider,
  Input
} from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import request from '../../utils/requestWrapper'
import ModalForm from './ModalForm'
import MaterialIcon from '../../utils/icon/Icon'
import FileViewer from './FileViewer'
import { getMessages } from '../../utils/utils'
import Spinner from '../../utils/Spinner'
import Details from './Details'
import History from './History'
import Move from './Move'
import Share from './Share'

const { Search } = Input
const { confirm } = Modal

notification.config({
  placement: 'bottomRight',
  bottom: 50
})
const StyledMenu = styled(Menu)`
  width: 150px !important;
  transform: translateX(12px);
  .without-padding {
    padding: 0;
  }
`
const StyledButton = styled(Button)`
  background: transparent !important;
  border-radius: 4px !important;
  border: none !important;
  margin-right: 1em;
  text-transform: capitalize;
  &:hover {
    background: #cccccc !important;
    border: none !important;
  }
`
const StyledNewMenu = styled(Menu)`
  .ant-btn,
  button.ant-btn.ant-btn-primary {
    padding: 0.5em 0.8em;
    border: none;
    width: 100%;
    height: 42px;
    width: 83px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &:hover {
      background: transparent;
    }
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0;
    height: 42px;
  }
  .upload-menu-item {
    span {
    }
  }
`
const StyledCol = styled(Col)`
  i.anticon.anticon-file {
    font-size: 100px !important;
    position: relative;
    top: 15px;
  }
  .ant-card.ant-card-bordered .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  a.download {
    position: relative;
    top: 18px;
    right: 5px;
  }
  .format {
    position: relative;
    bottom: 46px;
    left: -30px;
    text-align: center;
    color: #ffffff;
    height: 30px;
    font-size: 18px;
    padding: 0.1em;
    width: 55px;
    border-radius: 4px;
    background: #1253fc;
  }
  .jpg,
  .jpeg,
  .png,
  .gif {
    background: #50bee8;
  }
  .zip,
  .tar {
    background: #84bd5a;
  }
  .php,
  .txt,
  .json,
  .js,
  .rtf,
  .css,
  .html {
    background: #576d7e;
  }
  .doc,
  .xls,
  .odt,
  .docx,
  .pdf,
  .pptx,
  .ppt,
  .xlsx {
    background: #f15642;
  }
`
const FolderWrapper = styled.div`
  .folder-link {
    text-decoration: none !important;
  }
  .share-icon {
    position: absolute !important;
    top: 5px;
    right: -13px;
    font-size: 13px;
    margin: 0 !important;
  }
`
const Wrapper = styled.div`
  .ant-card.ant-card-bordered,
  .ant-card.ant-card-bordered .ant-card-body {
  }
  .top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .ant-progress.ant-progress-line {
    position: relative;
    height: 11px;
    width: 110%;
    bottom: 36px;
    margin-left: -2em;
  }
  .ant-progress-outer,
  .ant-progress.ant-progress-line > div {
    height: 5px;
  }
  .resource-title {
    display: flex;
    flex-direction: column;
    padding: 1em;
    .ant-select.ant-select-enabled {
      width: 100%;
    }
  }
  .ant-row h4 {
    font-weight: bold;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.65);
    line-height: 30px;
  }
  span.loading {
    margin-left: 1em;
  }
  .file-col .ant-card.ant-card-bordered .ant-card-body {
    height: 180px;
    padding: 0.8em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
  div#msccBanner {
    display: none !important;
  }
  .ant-btn {
    margin: 0;
  }
  .file-col .ant-card.ant-card-bordered .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .folder-col .ant-card.ant-card-bordered .ant-card-body {
    height: 42px;
    padding: 1em;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  i.anticon.anticon-folder {
    font-size: 100px;
  }
  span {
    font-weight: bold;
    height: 20px;
    overflow: hidden;
    word-break: break-all;
  }
  i.anticon.anticon-download {
    font-size: 15px;
  }
  iframe,
  embed,
  img {
    width: 100%;
    height: 90%;
  }
  .card-col.ant-col-xs-24 {
    padding: 1em;
  }
  .download {
    align-self: flex-end;
  }
  span.ant-input-search.ant-input-search-enter-button.ant-input-affix-wrapper {
    height: 30px;
    input,
    span {
      height: 30px;
    }
    button {
      border-radius: 0;
    }
  }
  @media screen and (min-width: 768px) {
    .first-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .first-row::before,
    .first-row::after {
      content: inherit !important;
    }
    .ant-row h4 {
      margin-left: 1em;
    }
    .resource-title {
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      .ant-select.ant-select-enabled {
        width: 90px;
      }
      span {
        margin-right: 1em;
      }
    }

    span.ant-input-search.ant-input-search-enter-button.ant-input-affix-wrapper {
      width: 200px;
    }
  }
`

export default class Files extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      breadcrumbs: [],
      routes: {},
      requesting: false,
      filesCount: 0,
      downloading: false,
      employees: null,
      activeSearch: null,
      search: '',
      sortDirection: 'asc',
      activeSortKey: 'name',

      // wheel / scrolling flags
      isNextReady: true,
      hasFolderData: true,
      hasFilesData: true,
      hasFolderLimit: 50,
      hasFolderTotal: 50,
      hasFileLimit: 10,
      hasFileTotal: 10
    }
  }

  /* TODO try to replace with new lifecycle getDerivedStateFromProps */
  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      let { breadcrumbs } = this.state
      let index = breadcrumbs.findIndex(
        element => element.id === nextProps.match.params.id
      )
      if (index !== -1 && nextProps.match.params.id !== 'root') {
        breadcrumbs.splice(index + 1, breadcrumbs.length - index)
      } else if (nextProps.match.params.id !== 'root') {
        breadcrumbs.push({
          id: nextProps.match.params.id,
          name: nextProps.location.state.name
        })
      }
      let id =
        nextProps.match.params.id !== 'root' ? nextProps.match.params.id : null
      let query = `${id ? '&' : '?'}sort=${this.state.activeSortKey}:${
        this.state.sortDirection
      }`
      if (nextProps.match.params.id === 'root') {
        breadcrumbs = []
      }
      this.getFolders(id, query)
      localStorage.breadcrumbs = JSON.stringify(breadcrumbs)
      this.setState({ breadcrumbs, activeSearch: null })
    }
  }

  componentDidMount() {
    // attach event listener here, third true param is a hack to force scroll with custom css design
    window.addEventListener('scroll', this.handleScroll, true)

    this.loadFolders()

    request('/helper/employee?fields=firstName,lastName,_id').then(response => {
      this.setState({ employees: response.data })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (this.state.isNextReady) {
      this.setState({ isNextReady: false })
      if (this.state.hasFolderData) {
        this.loadFolders()
      } else {
        this.loadFiles()
      }
    }
  }

  loadFolders = () => {
    this.setState({ search: '' })
    let id =
      this.props.match.params.id !== 'root' ? this.props.match.params.id : null
    let query = `${id ? '&' : '?'}sort=${this.state.activeSortKey}:${
      this.state.sortDirection
    }`
    query += `&limit=${this.state.hasFolderTotal}`

    if (this.props.match.params.id && this.props.match.params.id !== 'root') {
      this.getFolders(this.props.match.params.id, query)
    } else {
      this.getFolders(null, query)
    }
  }

  getFolders = (id = null, query = null, loader = true) => {
    if (id) {
      this.setState({
        files: [],
        hasFileLimit: 10,
        hasFileData: true,
        hasFileTotal: 10
      })
    }
    let endPoint = id
      ? `/${this.props.type}/folder?filters=parent:${id}`
      : `/${this.props.type}/folder`

    if (query) {
      endPoint += query
    }
    if (loader) {
      this.setState({ loading: true })
    }
    request(endPoint).then(response => {
      let { navigation } = response
      if (id) {
        let { routes } = this.state
        if (!routes[id]) {
          routes[id] = response.name
        }
      }
      if (response.status === 401) {
        getMessages(response)
        this.setState({
          showLogin: true,
          action: this.reloadData,
          loading: false
        })
      } else if (response.status === 200) {
        if (response.data.length) {
          this.setState({
            folders: response.data,
            loading: false
          })
        } else {
          let folders = []
          if (response.data.folders) {
            folders = response.data.folders
          }
          this.setState({ folders, loading: false })
        }

        if (navigation.total <= navigation.page * navigation.limit) {
          this.setState({ hasFolderData: false, isNextReady: true })
          this.loadFiles(id)
        } else {
          this.setState({
            hasFolderTotal:
              this.state.hasFolderTotal + this.state.hasFolderLimit,
            isNextReady: false
          })
        }
      }
    })
  }

  loadFiles = id => {
    this.setState({ loader: true })

    let fileEndpoint = id
      ? `/${this.props.type}/file?filters=parent:${id}&limit=${this.state.hasFileTotal}`
      : `/${this.props.type}/file?limit=${this.state.hasFileTotal}`

    request(fileEndpoint).then(response => {
      let { navigation } = response
      if (response.status === 200) {
        this.setState({
          files: response.data,
          loading: false,
          hasFilesData: navigation.total >= navigation.page * navigation.limit,
          hasFileTotal: this.state.hasFileTotal + this.state.hasFileLimit,
          isNextReady: navigation.total >= navigation.page * navigation.limit
        })
      } else {
        getMessages(response)
      }
    })
  }

  sort = (value, option = null, sortChanged = false) => {
    let direction = sortChanged
      ? this.state.sortDirection === 'asc'
        ? 'desc'
        : 'asc'
      : this.state.sortDirection
    if (value) {
      this.setState({ activeSortKey: value })
    }
    if (sortChanged) {
      this.setState(prevState => ({
        sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc'
      }))
    }
    value = value || this.state.activeSortKey
    let id =
      this.props.match.params.id !== 'root' ? this.props.match.params.id : null
    let query = `${id ? '&' : '?'}sort=${value}:${direction}`
    this.getFolders(id, query, false)
  }

  handleActionsMenu = (id, key, resource, name) => {
    if (key === 'delete') {
      let current = this
      confirm({
        title: `Do you want to delete this ${resource}?`,
        onOk() {
          current.delete(id, resource)
        },
        onCancel() {}
      })
    } else if (key === 'download') {
      let endPoint = `/${this.props.type}/${resource}/${id}`
      notification.info({
        message: (
          <span
            style={{
              fontSize: '12px'
            }}>{`Getting link to download ${name}`}</span>
        ),
        icon: <Icon type="loading" />,
        duration: 0,
        key: `download`
      })

      request(endPoint).then(response => {
        this.setState({ loading: false })
        if (response.status === 401) {
          getMessages(response)
        } else {
          notification.close(`download`)
          window.open(response.data.url)
          //download(response.data.url, response.data.name)
        }
      })
    }
  }

  handleSortActionsMenu = menu => {
    this.sort(menu.key)
  }

  uploadFile = file => {
    let data = new FormData()
    let endPoint =
      this.props.match.params.id !== 'root'
        ? `/${this.props.type}/file?parent=${this.props.match.params.id}`
        : `/${this.props.type}/file`
    data.append('file', file)
    this.setState({ requesting: true })
    notification.info({
      message: <span style={{ fontSize: '12px' }}>{file.name}</span>,
      icon: <Icon type="loading" />,
      duration: 0,
      key: `loading-${this.state.filesCount}`
    })
    return request(endPoint, 'POST', data, true, null, true).then(response => {
      if (response.status === 201) {
        this.setState({ files: response.data })
        notification.close(`loading-${this.state.filesCount}`)
        notification.success({
          message: <span style={{ fontSize: '12px' }}>{file.name}</span>,
          duration: 5
        })
        this.setState(prevState => ({
          requesting: false,
          filesCount: prevState.filesCount + 1
        }))
      }
      return 'https://erp-api.techmnh.com/user/test-call'
    })
  }

  delete(id, resource) {
    let endPoint = `/${this.props.type}/${resource}/${id}`
    this.setState({ downloading: true })
    request(endPoint, 'DELETE').then(response => {
      getMessages(response)
      this.setState({ downloading: false })
      if (response.status === 401) {
      } else if (response.status === 200) {
        this.deleteFromList(resource, id)
      }
    })
  }

  deleteFromList = (resource, id) => {
    if (resource === 'folder') {
      let { folders } = this.state
      folders.forEach((folder, i) => {
        if (folder._id === id) {
          folders.splice(i, 1)
          return
        }
      })
      this.setState({ folders })
    }
    if (resource === 'file') {
      let { files } = this.state
      files.forEach((file, i) => {
        if (file._id === id) {
          files.splice(i, 1)
          return
        }
      })
      this.setState({ files })
    }
  }

  updateFolders = (data, update = false) => {
    let { folders } = this.state
    if (update) {
      folders.forEach(folder => {
        if (folder._id === data._id) {
          folder.name = data.name
          return
        }
      })
    } else {
      folders.push(data)
    }
    this.setState({
      folders
    })
  }

  updateFiles = data => {
    let { files } = this.state

    files.forEach(file => {
      if (file._id === data._id) {
        file.name = data.name
        return
      }
    })

    this.setState({ files })
  }

  onSearch = name => {
    this.setState({ activeSearch: name === '' ? null : name, loading: true })
    let endPoint = ''
    if (name === '') {
      endPoint = `/${this.props.type}/search`
    } else {
      endPoint = `/${this.props.type}/search?filters=name:${name}`
      if (this.props.match.params.id !== 'root') {
        endPoint += `,parent:${this.props.match.params.id}`
      }
    }
    request(endPoint).then(response => {
      this.setState({ loading: false })
      if (response.status === 401) {
      } else if (response.status === 200) {
        if (response.data.length === 0) {
          message.info('No results found')
        }
        let files = []
        let folders = []
        response.data.forEach(item => {
          if (item.type === 'folder') {
            folders.push(item)
          } else if (item.type === 'file') {
            files.push(item)
          }
        })
        this.setState({ files, folders })
      }
    })
  }

  onShared = (type, record) => {
    let data = this.state[`${type}s`]
    data.forEach((item, i) => {
      if (item._id === record._id) {
        data[i].share = record.share
      }
    })
    this.setState({ [`${type}s`]: data })
  }

  render() {
    const { id } = this.props.match.params
    const menu = (
      <StyledNewMenu>
        <Menu.Item>
          <ModalForm
            parent={id}
            onSuccess={this.updateFolders}
            resource="folder"
            type={this.props.type}
          />
        </Menu.Item>
        <Menu.Item className="upload-menu-item">
          <Upload action={this.uploadFile} showUploadList={false} multiple>
            <Button>
              <Icon type="upload" /> File
            </Button>
          </Upload>
        </Menu.Item>
      </StyledNewMenu>
    )
    const sortMenu = (
      <Menu onClick={this.handleSortActionsMenu}>
        <Menu.Item key="name">Name</Menu.Item>
        <Menu.Item key="createdAt">Created At</Menu.Item>
      </Menu>
    )
    return (
      <Wrapper>
        <div className="first-row">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link
                style={{ textTransform: 'capitalize' }}
                to={`/home/file-system/${this.props.customRoute ||
                  this.props.type}/folder/root`}>
                {this.props.type.replace('-', ' ')}
              </Link>
            </Breadcrumb.Item>
            {this.state.breadcrumbs.map(breadcrumb => {
              return (
                <Breadcrumb.Item key={breadcrumb.id}>
                  <Link
                    to={`/home/file-system/${this.props.customRoute ||
                      this.props.type}/folder/${breadcrumb.id}`}>
                    {breadcrumb.name}
                  </Link>
                </Breadcrumb.Item>
              )
            })}
          </Breadcrumb>
          <Search
            placeholder="Search file"
            value={this.state.search}
            onChange={e => this.setState({ search: e.target.value })}
            onSearch={this.onSearch}
            enterButton
          />
        </div>
        <br />
        <div className="top-row">
          <div>
            {!this.props.sharedDrive && (
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                trigger={['click']}>
                <Button style={{ marginRight: '10px' }}>
                  <Icon type="plus" />
                  New
                </Button>
              </Dropdown>
            )}
            {id && id !== 'root' && (
              <Button onClick={() => this.props.history.goBack()}>
                <Icon type="rollback" />
                Back
              </Button>
            )}
          </div>
          {!this.state.loading && (
            <Button
              shape="circle"
              title="Refresh folder"
              icon="sync"
              onClick={this.loadFolders}
            />
          )}
        </div>
        <Divider />
        {!this.state.loading ? (
          <React.Fragment>
            <Row>
              {this.state.folders && this.state.folders.length > 0 && (
                <React.Fragment>
                  <div className="resource-title">
                    <h4>Folders</h4>
                    <div>
                      {!this.state.activeSearch && (
                        <React.Fragment>
                          <Dropdown overlay={sortMenu} placement="bottomLeft">
                            <StyledButton>
                              {this.state.activeSortKey}
                            </StyledButton>
                          </Dropdown>
                          <Button
                            shape="circle"
                            icon={
                              this.state.sortDirection === 'asc'
                                ? 'arrow-down'
                                : 'arrow-up'
                            }
                            onClick={() =>
                              this.sort(this.state.activeFileSort, null, true)
                            }
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  {this.state.folders.map(element => {
                    const folderMenu = (
                      <StyledMenu
                        onClick={event => {
                          this.handleActionsMenu(
                            element._id,
                            event.key,
                            'folder'
                          )
                        }}>
                        {!this.props.sharedDrive && (
                          <Menu.Item className="without-padding">
                            <ModalForm
                              parent={id}
                              onSuccess={data => this.updateFolders(data, true)}
                              editId={element._id}
                              resource={'folder'}
                              type={this.props.type}
                              name={element.name}
                            />
                          </Menu.Item>
                        )}
                        {this.props.type !== 'public-network' &&
                          this.props.type !== 'shared-drive' && (
                            <Menu.Item className="without-padding" key="share">
                              <Share
                                employees={this.state.employees}
                                type={this.props.type}
                                id={element._id}
                                share={element.share}
                                resource="folder"
                                onShared={this.onShared}
                              />
                            </Menu.Item>
                          )}
                        {this.props.type !== 'public-network' &&
                          this.props.type !== 'shared-drive' && (
                            <Menu.Item className="without-padding" key="move">
                              <Move
                                resourceType="folder"
                                type={this.props.type}
                                id={element._id}
                                delete={this.deleteFromList}
                                currentFolderId={id !== 'root' ? id : null}
                                selectedId={element._id}
                              />
                            </Menu.Item>
                          )}
                        <Menu.Item className="without-padding" key="details">
                          <Details
                            type={this.props.type}
                            resource="folder"
                            id={element._id}
                          />
                        </Menu.Item>
                        <Menu.Item className="without-padding" key="history">
                          <History
                            type={this.props.type}
                            resource="folder"
                            id={element._id}
                          />
                        </Menu.Item>

                        {!this.props.sharedDrive && (
                          <Menu.Item key="delete">
                            <Icon type="delete" />
                            &nbsp;Delete
                          </Menu.Item>
                        )}
                      </StyledMenu>
                    )
                    return element.type === 'folder' ? (
                      <Dropdown
                        key={element._id}
                        overlay={folderMenu}
                        trigger={['contextMenu']}>
                        <FolderWrapper>
                          <Tooltip title={element.name}>
                            <Col
                              key={element._id}
                              className="card-col folder-col"
                              xs={24}
                              sm={6}
                              md={4}>
                              <Link
                                className="folder-link"
                                to={{
                                  pathname: `/home/file-system/${this.props
                                    .customRoute || this.props.type}/folder/${
                                    element._id
                                  }`,
                                  state: { name: element.name }
                                }}>
                                <Card>
                                  <MaterialIcon type="folder" />
                                  <span>{element.name}</span>
                                  {element.share &&
                                    element.share.length > 0 && (
                                      <MaterialIcon
                                        type="folder_shared"
                                        className="share-icon"
                                        title="Shared"
                                      />
                                    )}
                                </Card>
                              </Link>
                            </Col>
                          </Tooltip>
                        </FolderWrapper>
                      </Dropdown>
                    ) : null
                  })}
                </React.Fragment>
              )}
            </Row>
            <Spinner loading={this.state.downloading} />
            <Row>
              {this.state.files && this.state.files.length > 0 && (
                <React.Fragment>
                  <div className="resource-title">
                    <h4>Files</h4>
                    <div>
                      {this.state.folders &&
                        this.state.folders.length === 0 &&
                        !this.state.activeSearch && (
                          <React.Fragment>
                            <Dropdown overlay={sortMenu} placement="bottomLeft">
                              <StyledButton>
                                {this.state.activeSortKey}
                              </StyledButton>
                            </Dropdown>
                            <Button
                              shape="circle"
                              icon={
                                this.state.sortDirection === 'asc'
                                  ? 'arrow-down'
                                  : 'arrow-up'
                              }
                              onClick={() =>
                                this.sort(this.state.activeFileSort, null, true)
                              }
                            />
                          </React.Fragment>
                        )}
                    </div>
                  </div>
                  {this.state.files.map((file, i) => {
                    const fileMenu = (
                      <StyledMenu
                        onClick={event => {
                          this.handleActionsMenu(
                            file._id,
                            event.key,
                            'file',
                            file.name
                          )
                        }}>
                        {!this.props.sharedDrive && (
                          <Menu.Item className="without-padding">
                            <ModalForm
                              parent={id}
                              onSuccess={data => this.updateFiles(data)}
                              resource="file"
                              editId={file._id}
                              type={this.props.type}
                              name={file.name}
                            />
                          </Menu.Item>
                        )}
                        <Menu.Item className="without-padding" key="details">
                          <Details
                            type={this.props.type}
                            resource="file"
                            id={file._id}
                          />
                        </Menu.Item>
                        {this.props.type !== 'public-network' &&
                          this.props.type !== 'shared-drive' && (
                            <Menu.Item className="without-padding" key="share">
                              <Share
                                employees={this.state.employees}
                                type={this.props.type}
                                id={file._id}
                                resource="file"
                                share={file.share}
                                onShared={this.onShared}
                              />
                            </Menu.Item>
                          )}
                        {this.props.type !== 'public-network' &&
                          this.props.type !== 'shared-drive' && (
                            <Menu.Item className="without-padding" key="move">
                              <Move
                                resourceType="file"
                                type={this.props.type}
                                id={file._id}
                                currentFolderId={id !== 'root' ? id : null}
                                delete={this.deleteFromList}
                              />
                            </Menu.Item>
                          )}

                        <Menu.Item key="download">
                          <Icon type="download" />
                          &nbsp;Download
                        </Menu.Item>

                        <Menu.Item className="without-padding" key="history">
                          <History
                            type={this.props.type}
                            resource="file"
                            id={file._id}
                          />
                        </Menu.Item>

                        {!this.props.sharedDrive && (
                          <Menu.Item key="delete">
                            <Icon type="delete" />
                            &nbsp;Delete
                          </Menu.Item>
                        )}
                      </StyledMenu>
                    )
                    return (
                      <Dropdown
                        key={i}
                        overlay={fileMenu}
                        trigger={['contextMenu']}>
                        <StyledCol
                          className="card-col file-col"
                          xs={24}
                          sm={6}
                          md={4}>
                          <FileViewer type={this.props.type} file={file} />
                        </StyledCol>
                      </Dropdown>
                    )
                  })}
                </React.Fragment>
              )}
            </Row>
          </React.Fragment>
        ) : (
          <Spinner loading={true} />
        )}
      </Wrapper>
    )
  }
}
