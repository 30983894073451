import React from 'react'
import { Form, Icon, Row, Button, Modal, Input } from 'antd'
import request from '../utils/requestWrapper'
import { getMessages, scorePassword } from '../utils/utils'
const FormItem = Form.Item

class UserPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDirty: false,
      validatePassword: null,
      visible: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleConfirmBlur = this.handleConfirmBlur.bind(this)
    this.checkPassword = this.checkPassword.bind(this)
    this.checkConfirm = this.checkConfirm.bind(this)
    this.checkPasswordStrength = this.checkPasswordStrength.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.state.visible) {
      this.setState({ visible: nextProps.show })
    }
  }

  handleConfirmBlur(e) {
    const value = e.target.value
    this.setState({ confirmDirty: this.state.confirmDirty || !!value })
  }

  checkPasswordStrength(rule, value, callback) {
    if (value && scorePassword(value) < 30) {
      callback(
        'very weak password ( Password must contain A-Z, a-z, 0-9 and special char )'
      )
    } else if (value && scorePassword(value) < 70) {
      callback(
        'Weak password ( Password must contain A-Z, a-z, 0-9 and special char )'
      )
    } else {
      callback()
    }
  }

  checkPassword(rule, value, callback) {
    const form = this.props.form
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!')
    } else {
      callback()
    }
  }

  checkConfirm(rule, value, callback) {
    const form = this.props.form
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  showModal() {
    this.setState({
      visible: true
    })
  }

  handleCancel() {
    this.setState({ visible: false })
    this.props.form.resetFields()
    this.props.handleClose()
  }

  handleSubmit(e = null) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let data = {}
        let user = this.props.user
        data.password = values.repassword
        data.password2 = values.repassword
        let endPoint = '/user/set-password'
        let method = 'POST'
        this.setState({ showLogin: false })
        request(
          endPoint,
          method,
          data,
          this.props.notLogged ? false : true,
          typeof user === 'object' ? user.token : null
        ).then(response => {
          getMessages(response)
          if (response.status === 401) {
            this.setState({ showLogin: true })
          } else if (response.status === 200) {
            this.props.handleClose()
          }
        })
      }
    })
  }

  render() {
    const { visible } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Modal
        visible={visible}
        title="Set Password"
        width={350}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        bodyStyle={{
          height: '230px'
        }}>
        <Form
          onSubmit={this.handleSubmit}
          style={{ margin: '20px' }}
          className="login-form">
          <Row>
            <FormItem colon={false}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!'
                  },
                  {
                    validator: this.checkConfirm
                  },
                  {
                    validator: this.checkPasswordStrength
                  }
                ]
              })(
                <Input
                  style={{ width: '100%' }}
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  type="password"
                  placeholder="Password"
                />
              )}
            </FormItem>
          </Row>
          <Row>
            <FormItem colon={false}>
              {getFieldDecorator('repassword', {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  {
                    validator: this.checkPassword
                  }
                ]
              })(
                <Input
                  type="password"
                  onBlur={this.handleConfirmBlur}
                  style={{ width: '100%' }}
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  placeholder="Repeat  Password"
                />
              )}
            </FormItem>
          </Row>
          <Row style={{ textAlign: 'center' }}>
            <Button
              style={{ minWidth: '190px' }}
              type="primary"
              htmlType="submit">
              Set Password
            </Button>
          </Row>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(UserPassword)
