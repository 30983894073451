import React from 'react'
import { Modal, Form, Icon, Select, Button, Row, Col } from 'antd'
import request from '../../utils/requestWrapper'
import { getMessages } from '../../utils/utils'
import styled from 'styled-components'

const FormItem = Form.Item
const Wrapper = styled.div`
  button.ant-btn.ant-btn-primary {
    background: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    width: 100%;
  }
`
const FormWrapper = styled.div`
  .ant-form-item-label {
    display: none;
  }
  .save-button {
    width: 100px;
  }
`
class Resource extends React.Component {
  state = {
    loading: false,
    visible: false
  }

  showModal = () => {
    if (this.props.share && this.props.share.length > 0) {
      let share = []
      this.props.employees.forEach(employee => {
        if (this.props.share.indexOf(employee._id) !== -1) {
          share.push(
            `${employee.firstName} ${employee.lastName}---${employee._id}`
          )
        }
      })
      this.props.form.setFieldsValue({ share })
    }
    this.setState({
      visible: true
    })
  }

  handleOk = () => {
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false, visible: false })
    }, 3000)
  }

  handleCancel = () => {
    this.setState({ visible: false })
    this.props.form.resetFields()
  }
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let share = values.share.map(value => value.split('---')[1])
        this.setState({ loading: true })
        let endPoint = `/${this.props.type}/${this.props.resource}/${this.props.id}/share`
        request(endPoint, 'PATCH', { share }).then(response => {
          this.setState({ loading: false })
          getMessages(response)
          if (response.status === 401) {
            this.setState({ data: response.data })
          } else if (response.status === 200 || response.status === 201) {
            this.props.onShared(this.props.resource, response.data)
            this.handleCancel()
          }
        })
      }
    })
  }
  render() {
    const { visible, loading } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Wrapper>
        <Button type="primary" onClick={this.showModal}>
          <Icon type="share-alt" theme="outlined" />
          Share
        </Button>
        <Modal
          visible={visible}
          title={`Share this ${this.props.resource}`}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}>
          {' '}
          <FormWrapper>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <FormItem colon={false} label="Name">
                    {getFieldDecorator(
                      'share',
                      {}
                    )(
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select Employees"
                        disabled={this.state.disabled}>
                        {this.props.employees.map(employee => (
                          <Select.Option
                            value={`${employee.firstName} ${employee.lastName}---${employee._id}`}
                            key={
                              employee._id
                            }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24}>
                  <Button
                    className="save-button"
                    type="primary"
                    htmlType="submit"
                    loading={loading}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        </Modal>
      </Wrapper>
    )
  }
}
export default Form.create()(Resource)
