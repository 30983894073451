import React, { Component } from 'react'
import { Modal, Form, Select, Input, Button } from 'antd'
import request from '../../utils/requestWrapper'
import style from 'styled-components'
import { getMessages } from '../../utils/utils'
import { formUtil } from '../../utils/style-utils'

const FormItem = Form.Item

let StyledDepartmentForm = style.div`
  ${formUtil}
`
let StyleBottonModal = style.div`
  & .menu-button{
    border: none;
    padding: 0;
    background: none;
    &:hover{
      background:transparent !important;
      border: none !important;
    }
  }
`

class DepartmentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectData: [],
      visible: false,
      disabled: false,
      icon: 'plus',
      action: 'Add',
      buttonType: 'primary',
      classButton: null
    }

    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.reloadData = this.reloadData.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    this.getAllEmployees()
    if (this.props.edit) {
      let { data } = this.props
      let departmentData = {
        name: data.name,
        description: data.description,
        manager: data.manager._id
      }
      this.props.form.setFieldsValue(departmentData)
      this.setState({
        icon: this.props.view ? 'eye' : 'edit',
        disabled: this.props.view ? true : false,
        action: this.props.view ? 'View' : 'Edit',
        buttonType: 'default',
        classButton: 'menu-button'
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    console.debug('Data --> ', this.props.data)
    console.debug('Next --> ', nextProps.data)
  }

  showModal() {
    this.setState({
      visible: true
    })
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleCancel() {
    this.setState({ visible: false })
    if (!this.props.data) {
      this.props.form.resetFields()
    }
  }

  reloadData() {
    this.getAllEmployees()
  }

  getAllEmployees() {
    let endPoint = '/helper/employee'
    request(endPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        let { selectData } = this.state
        selectData['employees'] = response.data
        this.setState({ selectData })
      }
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let requestObject = {}
        requestObject['name'] = values.name
        if (values.description) {
          requestObject['description'] = values.description
        }
        requestObject['manager'] = values.manager

        let method = this.props.edit ? 'PATCH' : 'POST'
        let endPoint = this.props.edit
          ? `/department/${this.props.data._id}`
          : '/department'
        request(endPoint, method, requestObject).then(response => {
          getMessages(response)
          if (response.status === 401) {
            this.setState({ data: response.data })
          } else if (response.status === 200 || response.status === 201) {
            this.props.onSaved()
            this.handleCancel()
          }
        })
      }
    })
  }

  handleSelectChange = value => {}

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <StyleBottonModal>
          <Button
            icon={this.state.icon}
            className={this.props.edit ? 'menu-button' : ''}
            type={this.state.buttonType}
            onClick={this.showModal}>
            {this.state.action}
          </Button>
        </StyleBottonModal>

        <Modal
          visible={this.state.visible}
          title={this.state.action + ' department'}
          width={350}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}>
          <StyledDepartmentForm theme={this.props.theme}>
            <Form onSubmit={this.handleSubmit}>
              <FormItem className={this.haveValue('name')} label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter the Name' }]
                })(
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Name"
                    disabled={this.state.disabled}
                  />
                )}
              </FormItem>
              <FormItem
                className={this.haveValue('description')}
                label="Description">
                {getFieldDecorator(
                  'description',
                  {}
                )(
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Description"
                    disabled={this.state.disabled}
                  />
                )}
              </FormItem>
              <FormItem className={this.haveValue('manager')} label="Manager">
                {getFieldDecorator('manager', {
                  rules: [
                    { required: true, message: 'Please enter the Manager' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Manager">
                    {this.state.selectData.employees
                      ? this.state.selectData.employees.map((employee, i) => {
                          return (
                            <Select.Option
                              key={i}
                              value={
                                employee._id
                              }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                          )
                        })
                      : null}
                  </Select>
                )}
              </FormItem>
              {!this.props.view && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={this.state.disabled}>
                  Submit
                </Button>
              )}
            </Form>
          </StyledDepartmentForm>
        </Modal>
      </div>
    )
  }
}

export default Form.create()(DepartmentForm)
