import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const errorsText = {
  403: {
    text: 'Forbidden',
    image: require(`../images/403.svg`),
    className: 'forbidden-img'
  },
  404: {
    text: 'Not Found',
    image: require(`../images/404.svg`),
    className: 'not-found-img'
  },
  405: {
    text: 'Method not Allowed',
    image: require(`../images/404.svg`),
    className: 'not-found-img'
  },
  500: {
    text: 'Internal Server Error',
    image: require(`../images/500.svg`),
    className: 'internal-server-error-img'
  }
}

const StyledError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1em;
  & .error-logo {
    height: 360px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  & .not-found-img {
    max-width: 420px;
  }
  & .forbidden-img {
    max-width: 294px;
  }
  & .internal-server-error-img {
    max-width: 700px;
    max-height: 240px !important;
  }
  & .error-text {
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  & .error-text h1 {
    color: #434e59;
    font-size: 72px;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 24px;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    .error-logo {
      margin-right: 4em;
    }
    .error-text {
      justify-content: center;
    }
    .internal-server-error-img {
      max-height: 360px;
    }
  }
`
export default class Error extends Component {
  componentDidMount() {
    if (this.props.code === '403') {
    }
  }

  render() {
    return (
      <StyledError url={errorsText[this.props.code].image}>
        <div
          className={`error-logo ${errorsText[this.props.code].className}`}
          style={{
            backgroundImage: `url(${errorsText[this.props.code].image})`
          }}
        />
        <div className="error-text">
          <h1>{this.props.code}</h1>
          <h2>{errorsText[this.props.code].text}</h2>
          {this.props.code === '403' ? (
            <h2>
              Please login again{' '}
              <Link className="login-link" to={{ pathname: '/login' }}>
                here
              </Link>
            </h2>
          ) : null}
        </div>
      </StyledError>
    )
  }
}
