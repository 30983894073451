import React, { Component } from 'react'
import style from 'styled-components'
import {
  Form,
  Input,
  Icon,
  Select,
  Row,
  Col,
  Button,
  DatePicker,
  Spin
} from 'antd'

const dateFormat = 'DD/MM/YYYY'

const FormItem = Form.Item
const StyledJobInfoForm = style.div``

class AddressInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filled: false,
      showReasonText: false,
      loadingSave: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.prev = this.prev.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.form.setFieldsValue(this.props.data)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && !this.state.filled) {
      this.props.form.setFieldsValue(nextProps.data)
      this.setState({
        filled: true
      })
    }
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loadingSave: true })
        let newValues = { ...values }
        this.props.onSubmit(newValues)
      } else this.setState({ loadingSave: false })
    })
  }

  prev() {
    this.props.onPrev()
  }
  fillPermanentAddress = () => {
    let values = this.props.form.getFieldsValue()
    this.props.form.setFieldsValue({ permanentAddress: values.homeAddress })
  }
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form

    return (
      <StyledJobInfoForm>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('signBoardPresent')}
                label="Was the sign board present?">
                {getFieldDecorator('signBoardPresent', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select one Option'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Was the sign board present?*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option
                      key={'Other'} value={'Other'}>
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('signBoardPresentOther')}
                label="Other signBoard">
                {getFieldDecorator('signBoardPresentOther', {
                  rules: [
                    {
                      required: getFieldValue('signBoardPresent') === 'Other'
                        ? true
                        : false,
                      message: "Other signBoard"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other signBoard"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('neighborhoodFeedback')}
                label="Neighborhood Feedback*">
                {getFieldDecorator('neighborhoodFeedback', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the feedback'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Neigborhood Feedback*">
                    <Select.Option key="Business operates daily" value="Business operates daily">
                      Business operates daily
                    </Select.Option>
                    <Select.Option key="Does not open daily" value="Does not open daily">
                      Does not open daily
                    </Select.Option>
                    <Select.Option key="Hardly seen activity in the past 6 monts" value="Hardly seen activity in the past 6 monts">
                      Hardly seen activity in the past 6 monts
                    </Select.Option>
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('neighborhoodFeedbackOther')}
                label="Other feedback">
                {getFieldDecorator('neighborhoodFeedbackOther', {
                  rules: [
                    {
                      required: getFieldValue('neighborhoodFeedback') === 'Other'
                        ? true
                        : false,
                      message: "Other feedback"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other feedback"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('statusOfTradeLic')}
                label="Status on the Trade License*">
                {getFieldDecorator('statusOfTradeLic', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the Trade License'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Status on the Trade License*">
                    <Select.Option key="Active & displayed" value="Active & displayed">
                      Active & displayed
                    </Select.Option>
                    <Select.Option key="No available" value="No available">
                      No available
                    </Select.Option>
                    <Select.Option key="Available but expired" value="Available but expired">
                      Available but expired
                    </Select.Option>
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('statusOfTradeLicOther')}
                label="Other status on the Trade License">
                {getFieldDecorator('statusOfTradeLicOther', {
                  rules: [
                    {
                      required: getFieldValue('statusOfTradeLic') === 'Other'
                        ? true
                        : false,
                      message: "Other status on the Trade License"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other Status on the Trade License"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('anyCmpOperating')}
                label="Any others companies operating from the same premises*" >
                {getFieldDecorator('anyCmpOperating', {
                  rules: [
                    {
                      required: getFieldValue('anyCmpOperating') === 'Other'
                        ? true
                        : false,
                      message:
                        'Any others companies operating from the same premises'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Any others companies operating from the same premises*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option key={'Other'} value={'Other'}>
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('anyCmpOperatingOther')}
                label="Other companies ">
                {getFieldDecorator('anyCmpOperatingOther', {
                  rules: [
                    {
                      required: getFieldValue('anyCmpOperating') === 'Other'
                        ? true
                        : false,
                      message: "Other companies"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other companies"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue('anyCmpOperatingYesVal')}
                label="If the above is yes,pleasegive the name">
                {getFieldDecorator('anyCmpOperatingYesVal', {
                  rules: [
                    {
                      required: getFieldValue('anyCmpOperatingYesVal') === 'Other'
                        ? true
                        : false,
                      message: "If the above is yes,pleasegive the name"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="If the above is yes,pleasegive the name"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue(`feedback`)}
                label="Feedback">
                {getFieldDecorator(`feedback`, {
                  rules: [
                    {
                      required: false,
                      message: 'Feedback'
                    }
                  ]
                })(<Input.TextArea placeholder="Feedback" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue(`comments2`)}
                label="Coments2">
                {getFieldDecorator(`comments2`, {
                  rules: [
                    {
                      required: false,
                      message: 'Coments2'
                    }
                  ]
                })(<Input.TextArea placeholder="Coments2" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue(`comments3`)}
                label="Comments3">
                {getFieldDecorator(`comments3`, {
                  rules: [
                    {
                      required: false,
                      message: 'Comments3'
                    }
                  ]
                })(<Input.TextArea placeholder="Comments3" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue(`latitude`)}
                label="Latitude*">
                {getFieldDecorator(`latitude`, {
                  rules: [
                    {
                      required: true,
                      message: 'Latitude is required'
                    }
                  ]
                })(<Input.TextArea placeholder="Latitude*" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={7} offset={1}>
              <FormItem
                colon={false}
                className={this.haveValue(`longitude`)}
                label="Longitude*">
                {getFieldDecorator(`longitude`, {
                  rules: [
                    {
                      required: true,
                      message: 'Longitude is required'
                    }
                  ]
                })(<Input.TextArea placeholder="Longitude*" />)}
              </FormItem>
            </Col>
          </Row>
          <Row className="button-row">
            <Button style={{ marginRight: '8px' }} onClick={this.prev}>
              <Icon type="left" />
              Previous
            </Button>
            {!this.props.view && (
              <span>
                <Button type="primary" htmlType="submit">
                  Save
                  <Icon type="right" />
                </Button>
                {this.state.loadingSave && (
                  <span style={{ margin: '10px' }}>
                    <Spin />
                  </span>
                )}
              </span>
            )}
          </Row>
        </Form>
      </StyledJobInfoForm>
    )
  }
}
export default Form.create()(AddressInfo)
