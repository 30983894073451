import React, { Component } from 'react'
import { Modal, Form, Select, Input, Button, Switch, Icon } from 'antd'
import request from '../../utils/requestWrapper'
import style from 'styled-components'
import { getMessages } from '../../utils/utils'
import { formUtil } from '../../utils/style-utils'
const FormItem = Form.Item
let StyledResourceForm = style.div`
  ${formUtil}
`
let StyleBottonModal = style.div`
  & .menu-button{
    border: none;
    padding: 0;
    background: none;
    &:hover{
      background:transparent !important;
      border: none !important;
    }
  }
`
class ResourceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: null,
      visible: false,
      disabled: false,
      icon: 'plus',
      action: 'Add',
      buttonType: 'primary',
      classButton: null
    }
    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }
  componentDidMount() {
    if (this.props.edit) {
      let values = this.props.data
      Object.keys(this.props.data).forEach(key => {
        if (!this.props.data[key]) {
          values[key] = ''
        }
      })
      this.props.form.setFieldsValue(values)
      this.setState({ checked: this.props.data.isSubPath })
      this.setState({
        icon: this.props.view ? 'eye' : 'edit',
        disabled: this.props.view ? true : false,
        action: this.props.view ? 'View' : 'Edit',
        buttonType: 'default',
        classButton: 'menu-button'
      })
    }
  }
  componentWillReceiveProps(nextProps) {}

  showModal() {
    this.setState({
      visible: true
    })
    if (this.props.edit) {
      this.props.form.setFieldsValue(this.props.data)
      this.setState({ checked: this.props.data.isSubPath })
      this.setState({
        icon: this.props.view ? 'eye' : 'edit',
        disabled: this.props.view ? true : false,
        action: this.props.view ? 'View' : 'Edit',
        buttonType: 'default',
        classButton: 'menu-button'
      })
    }
  }
  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }
  handleCancel() {
    this.setState({ visible: false, checked: null })
    if (!this.props.data) {
      this.props.form.resetFields()
      this.props.form.setFieldsValue({ isSubPath: false })
    }
  }
  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let requestObject = {}
        requestObject['name'] = values.name
        if (values.description) {
          requestObject['description'] = values.description
        }
        requestObject['isSubPath'] = values.isSubPath
        if (values.isSubPath) {
          requestObject['parentPath'] = values.parentPath
        }
        requestObject['actions'] = values.actions
        requestObject['path'] = values.path
        let method = this.props.edit ? 'PATCH' : 'POST'
        let endPoint = this.props.edit
          ? `/resource/${this.props.data._id}`
          : '/resource'
        request(endPoint, method, requestObject).then(response => {
          getMessages(response)
          if (response.status === 401) {
            this.setState({ data: response.data })
          } else if (response.status === 200 || response.status === 201) {
            this.props.onSaved()
            this.handleCancel()
          }
        })
      }
    })
  }
  handleSelectChange = value => {}
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <StyleBottonModal>
          <Button
            icon={this.state.icon}
            className={this.props.edit ? 'menu-button' : ''}
            type={this.state.buttonType}
            onClick={this.showModal}>
            {this.state.action}
          </Button>
        </StyleBottonModal>
        <Modal
          visible={this.state.visible}
          title={this.state.action + ' Resource'}
          width={350}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}>
          <StyledResourceForm theme={this.props.theme}>
            <Form onSubmit={this.handleSubmit}>
              <FormItem className={this.haveValue('name')} label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter the Name' }]
                })(
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Name"
                    disabled={this.state.disabled}
                  />
                )}
              </FormItem>
              <FormItem
                className={this.haveValue('description')}
                label="Description">
                {getFieldDecorator(
                  'description',
                  {}
                )(
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Description"
                    disabled={this.state.disabled}
                  />
                )}
              </FormItem>
              <FormItem
                className={this.haveValue('isSubPath')}
                label="Is Sub Path">
                {getFieldDecorator(
                  'isSubPath',
                  {}
                )(
                  <Switch
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="cross" />}
                    defaultChecked={this.state.checked || false}
                    disabled={this.state.disabled}
                    checked={this.props.form.getFieldValue('isSubPath')}
                  />
                )}
              </FormItem>
              <FormItem
                style={{
                  display: this.props.form.getFieldValue('isSubPath')
                    ? 'block'
                    : 'none'
                }}
                className={this.haveValue('parentPath')}
                label="Parent Path">
                {getFieldDecorator(
                  'parentPath',
                  {}
                )(
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Parent Path"
                    disabled={this.state.disabled}
                  />
                )}
              </FormItem>
              <FormItem className={this.haveValue('actions')} label="Actions">
                {getFieldDecorator('actions', {
                  rules: [
                    { required: true, message: 'Please enter the actions' }
                  ]
                })(
                  <Select
                    mode="multiple"
                    placeholder="Actions"
                    disabled={this.state.disabled}>
                    <Select.Option key="get" value="get">
                      Get
                    </Select.Option>
                    <Select.Option key="get-all" value="get-all">
                      Get All
                    </Select.Option>
                    <Select.Option key="post" value="post">
                      Post
                    </Select.Option>
                    <Select.Option key="patch" value="patch">
                      Patch
                    </Select.Option>
                    <Select.Option key="delete" value="delete">
                      Delete
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
              <FormItem className={this.haveValue('path')} label="Path">
                {getFieldDecorator(
                  'path',
                  {}
                )(
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Path"
                    disabled={this.state.disabled}
                  />
                )}
              </FormItem>
              {!this.props.view && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={this.state.disabled}>
                  Submit
                </Button>
              )}
            </Form>
          </StyledResourceForm>
        </Modal>
      </div>
    )
  }
}
export default Form.create()(ResourceForm)
