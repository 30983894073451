import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import App from './App'
import enUS from 'antd/lib/locale-provider/en_US'
import 'antd/dist/antd.css'
import './index.css'

let app = document.getElementById('root')

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={enUS}>
      <App />
    </ConfigProvider>
  </BrowserRouter>,
  app
)
