import React, { Component } from 'react'
import style from 'styled-components'
import { Form, Icon, DatePicker, Select, Row, Col, Button, Tag } from 'antd'
import IdentityDetailsForm from './IdentityDetailsForm'

const FormItem = Form.Item
const dateFormat = 'DD/MM/YYYY'

const StyledJobInfoForm = style.div`
  h5{
    margin-top:1em;
  }
`
class JobInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filled: false,
      identityDetails: []
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.prev = this.prev.bind(this)
    this.haveValue = this.haveValue.bind(this)
    this.update = this.update.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.form.setFieldsValue(this.props.data)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && !this.state.filled) {
      this.props.form.setFieldsValue(nextProps.data)
      this.setState({
        filled: true,
        identityDetails: nextProps.data.identityDetails
      })
    }
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  update(data) {
    this.setState({ identityDetails: data })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let data = { ...values, identityDetails: this.state.identityDetails }
        this.props.onSubmit(data)
      }
    })
  }

  prev() {
    this.props.onPrev()
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <StyledJobInfoForm>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('dateOfJoin')}
                label="Date of Join*">
                {getFieldDecorator('dateOfJoin', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select your Date of Join'
                    }
                  ]
                })(
                  <DatePicker
                    disabled={this.props.view}
                    placeholder="Date of Join*"
                    format={dateFormat}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem className={this.haveValue('branch')} label="Branch*">
                {getFieldDecorator('branch', {
                  rules: [
                    { required: true, message: 'Please select the Branch' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Branch*">
                    {this.props.selectData.branch
                      ? this.props.selectData.branch.map(branch => (
                          <Select.Option key={branch._id} value={branch._id}>
                            {branch.name}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('department')}
                label="Department*">
                {getFieldDecorator('department', {
                  rules: [
                    { required: true, message: 'Please select the Department' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Deparment*">
                    {this.props.selectData.department
                      ? this.props.selectData.department.map(department => (
                          <Select.Option
                            key={department._id}
                            value={department._id}>
                            {department.name}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('jobTitle')}
                label="Job Title*">
                {getFieldDecorator('jobTitle', {
                  rules: [
                    { required: true, message: 'Please enter the Job Title' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Job Title*">
                    {this.props.selectData.jobTitle
                      ? this.props.selectData.jobTitle.map(jobTitle => (
                          <Select.Option
                            key={jobTitle._id}
                            value={jobTitle._id}>
                            {jobTitle.name}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem className={this.haveValue('manager')} label="Manager">
                {getFieldDecorator('manager', {
                  rules: [
                    { required: true, message: 'Please enter the Manager' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Manager">
                    {this.props.selectData.employee
                      ? this.props.selectData.employee.map(employee => (
                          <Select.Option
                            key={employee._id}
                            value={
                              employee._id
                            }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('teamLead')}
                label="Team Lead">
                {getFieldDecorator(
                  'teamLead',
                  {}
                )(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Team Lead">
                    {this.props.selectData.employee
                      ? this.props.selectData.employee.map(employee => (
                          <Select.Option
                            key={employee._id}
                            value={
                              employee._id
                            }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem className={this.haveValue('trainer')} label="Trainer">
                {getFieldDecorator(
                  'trainer',
                  {}
                )(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Trainer">
                    {this.props.selectData.employee
                      ? this.props.selectData.employee.map(employee => (
                          <Select.Option
                            key={employee._id}
                            value={
                              employee._id
                            }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem className={this.haveValue('hiredBy')} label="Hired By">
                {getFieldDecorator('hiredBy', {
                  rules: [{ required: true, message: 'This field is required' }]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Hired By">
                    {this.props.selectData.employee
                      ? this.props.selectData.employee.map(employee => (
                          <Select.Option
                            key={employee._id}
                            value={
                              employee._id
                            }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('referedBy')}
                label="Refered By">
                {getFieldDecorator('referedBy', {
                  rules: [{ required: true, message: 'Please enter the Name' }]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Refered By">
                    {this.props.selectData.employee
                      ? this.props.selectData.employee.map(employee => (
                          <Select.Option
                            key={employee._id}
                            value={
                              employee._id
                            }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Tag>Visa Details</Tag>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('visaDetials.jobTitle')}
                label="Job Title On Visa*">
                {getFieldDecorator('visaDetials.jobTitle', {
                  rules: [{ required: true, message: 'Please enter the Name' }]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Job Title On Visa*">
                    {this.props.selectData.jobTitle
                      ? this.props.selectData.jobTitle.map(jobTitle => (
                          <Select.Option
                            key={jobTitle._id}
                            value={jobTitle._id}>
                            {jobTitle.name}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('visaDetials.visaSponser')}
                label="Visa Sponser*">
                {getFieldDecorator(
                  'visaDetials.visaSponser',
                  {}
                )(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Visa Sponser">
                    <Select.Option
                      key="Employment Visa"
                      value="Employment Visa">
                      Employment Visa
                    </Select.Option>
                    <Select.Option
                      key="Exhibition Visa"
                      value="Exhibition Visa">
                      Exhibition Visa
                    </Select.Option>
                    <Select.Option
                      key="Family Visit Visa"
                      value="Family Visit Visa">
                      Family Visit Visa
                    </Select.Option>
                    <Select.Option key="Investor Visa" value="Investor Visa">
                      Investor Visa
                    </Select.Option>
                    <Select.Option
                      key="Residents Permit"
                      value="Residents Permit">
                      Residents Permit
                    </Select.Option>
                    <Select.Option key="Residence Visa" value="Residence Visa">
                      Residence Visa
                    </Select.Option>
                    <Select.Option
                      key="Residence Visa Property"
                      value="Residence Visa Property">
                      Residence Visa Property
                    </Select.Option>
                    <Select.Option
                      key="Residence Visa Family"
                      value="Residence Visa Family">
                      Residence Visa Family
                    </Select.Option>
                    <Select.Option key="Work Permit" value="Work Permit">
                      Work Permit
                    </Select.Option>
                    <Select.Option key="Work Visa" value="Work Visa">
                      Work Visa
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('visaDetials.visaStartDate')}
                label="Visa Start Date*">
                {getFieldDecorator(
                  'visaDetials.visaStartDate',
                  {}
                )(
                  <DatePicker
                    disabled={this.props.view}
                    placeholder="Visa Start Date*"
                    format={dateFormat}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('visaDetials.visaEndDate')}
                label="Visa End Date*">
                {getFieldDecorator(
                  'visaDetials.visaEndDate',
                  {}
                )(
                  <DatePicker
                    disabled={this.props.view}
                    placeholder="Visa End Date*"
                    format={dateFormat}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <IdentityDetailsForm
            view={this.props.view}
            theme={this.props.theme}
            data={this.props.data ? this.props.data.identityDetails : null}
            update={this.update}
          />
          <Row className="button-row">
            <Button style={{ marginRight: '8px' }} onClick={this.prev}>
              <Icon type="left" />
              Previous
            </Button>
            <Button type="primary" htmlType="submit">
              Next
              <Icon type="right" />
            </Button>
          </Row>
        </Form>
      </StyledJobInfoForm>
    )
  }
}
export default Form.create()(JobInfo)
