import React, { Component } from 'react'
import { Layout, Popover, Radio } from 'antd'
import { Route } from 'react-router-dom'
import style from 'styled-components'
import SideBar from './SideBar'
import Dashboard from './Dashboard'
import AttendanceForm from './Attendance/AttendanceForm'
import AttendanceList from './Attendance/AttendanceList'
import EmployeeForm from './Employees/EmployeeMainForm'
import EmployeeProfile from './Employees/EmployeeProfile'
import EmployeesList from './Employees/EmployeesList'
import ResourceList from './Resource/ResourceList'
import DepartmentList from './Department/DepartmentList'
import { themes, selectedColor, formUtil } from '../utils/style-utils'
import Leave from './Leave/Leave'
import LeaveForm from './Leave/LeaveForm'
import LeaveList from './Leave/LeaveList'
import Payroll from './Payroll/Payroll'
import PayrollForm from './Payroll/PayrollForm'
import PayrollList from './Payroll/PayrollList'
import Recruitment from './Recruitment/Recruitment'
import RecruitmentForm from './Recruitment/RecruitmentForm'
import RecruitmentList from './Recruitment/RecruitmentList'
import RoleForm from './Role/RoleForm'
import RoleList from './Role/RoleList'
import Files from './FileSystem/Files'
import { getMessages, parseJwt } from '../utils/utils'
import request from '../utils/requestWrapper'
import BranchList from './Branch/BranchList'
import BranchForm from './Branch/BranchForm'
import JobTitleList from './JobTitle/JobTitleList'
import JobTitleForm from './JobTitle/JobTitleForm'
import SalaryList from './Salary/SalaryList'
import SalaryForm from './Salary/SalaryForm'
import FvForm from './FvLead/FvForm'
import LnddoForm from './Lnddo/LnddoForm'

import Icon from '../utils/icon/Icon'
import 'antd/dist/antd.css'
import { Button } from 'antd/lib/radio'
import FvList from './FvLead/FvList'
import FVleadProfile from './FvLead/FVleadProfile'
import LnddoList from './Lnddo/LnddoList'
import LnddoProfile from './Lnddo/LnddoleadProfile'

const RadioGroup = Radio.Group
const { Header, Content } = Layout

const StyledRadioGroup = style.div`
  & .ant-radio-group.theme-radio-group{
    display:flex !important;
    flex-direction:column !important;
  }
  & .ant-radio-checked .ant-radio-inner, .ant-radio-inner:hover{
    border-color:${props => themes[props.theme].primary};
  }
  & .ant-radio-inner:after{
    background-color:${props => themes[props.theme].primary};
  }
  & .ant-radio-wrapper, & .ant-radio-wrapper span:nth-child(2) {
    display:flex;
  }
  i.color-option{
    display:flex;
    margin-right:4px;
    width:15px;
    height:15px;
  }
`
const StyledLayoutWrapper = style.div`
  ${formUtil}
  height: 100%;
  & .flex-center{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  & .flex-row{
    display:flex;
    flex-direction:row;
  }
  & .flex-column{
    display:flex;
    flex-direction:column;
  }
  & .flex-around{
    align-items:center;
    justify-content:space-around;
  }
  & .flex-between{
    align-items:center;
    justify-content:space-between;
  }
  & .show-desktop{
    display:none;
  }
  & .show-mobile{
    display:flex;
  }
  & .ant-layout {
    height: 100%;
  }
  & .app-header{
    min-width: 100%;
    min-height: 46px;
    display: flex;
    flex-direction:row;
    padding:6px;
    border-bottom: 1px solid #e6eaee;
    background-color: #fff;
    input.ant-input{
      height:30px;
      border:none;
      margin-right:10px;
      padding-left:40px;
      &:focus{
        outline:none;
      }
    }
  }
  & .ant-layout-header{
    height:46px;
  }
  & .app-content{
    padding: 24px 16px;
    background: #F0F1F5;
  }
  & .toggle-sider{
    display:none;
  }
  & .ant-tag{
    margin-top:2em;
    cursor:default;
  }
  & .ant-card{
    border-radius:6px;
    width:100%;
    &:hover{
      box-shadow:4px 4px 40px rgba(0,0,0,.05);
    }
  }
  & .ant-tabs-nav .ant-tabs-tab-active,& .ant-tabs-nav .ant-tabs-tab:hover{
    color: ${props => themes[props.theme].primary};
  }
  & .ant-tabs-horizontal  .ant-tabs-tab-active.ant-tabs-tab{
    border-bottom: 2px solid ${props => themes[props.theme].primary};
  }

  .ant-pagination-item-active{
    background-color: ${props => themes[props.theme].primary};
    border-color: ${props => themes[props.theme].primary};
  }
  & .button-row{
    margin-top:2em;
  }
  .ant-layout::-webkit-scrollbar-track, .ant-layout-sider::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #E0E0E0;
    border-radius: 8px;
  } .ant-card:hover {
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .ant-layout::-webkit-scrollbar, .ant-layout-sider::-webkit-scrollbar {
    width: 8px;
    background-color:#C8C8C8;
  }
  .ant-layout::-webkit-scrollbar-thumb, .ant-layout-sider::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color:#B8B8B8;
  }
  & .logout-button,& .logout-button:hover{
    border: none;
    cursor:pointer;
    &:hover{
      color: ${props => themes[props.theme].primary};
    }
  }
  & .ant-steps-item-process .ant-steps-item-icon{
    background-color: ${props => themes[props.theme].primary} !important;
    border-color: ${props => themes[props.theme].primary} !important;
  }
  & .ant-steps-item-finish .ant-steps-item-icon{
    border-color: ${props => themes[props.theme].primary} !important;
  }

  & .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after{
    background-color:${props => themes[props.theme].primary};
  }
  & .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
    color:${props => themes[props.theme].primary};
  }
  & .ant-spin-dot i{
    background-color:${props => themes[props.theme].primary};
  }
  & .material-icon{
    position:relative;
    margin-right:10px;
  }
  & .header-icon{
    top:21px;
    margin-right:0;
  }
  & .settings{
    cursor:pointer;
    margin-right:10px;
  }
  & .form-internal-title{
    margin-top:1em;
    margin-bottom:0;
  }
  @media screen and (min-width: 768px)  {
    & .toggle-sider{
      display:block;
      cursor:pointer;
      position:relative;
      top:4px;
      margin-right:15px;
    }
    & .show-desktop{
      display:flex;
    }
    & .show-mobile{
      display:none;
    }
  }
`

export default class LayoutWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      height: window.innerHeight,
      theme: selectedColor,
      value: selectedColor,
      width: window.innerWidth
    }
    this.toggleSider = this.toggleSider.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.changeTheme = this.changeTheme.bind(this)
    this.logout = this.logout.bind(this)
  }
  componentWillMount() {
    if (localStorage.accessTokenDev) {
      let info = parseJwt(localStorage.accessTokenDev)
      let now = new Date().getTime()
      if (info.exp * 1000 < now) {
        this.props.history.push('/login')
      }
    } else {
      this.props.history.push('/login')
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    if (window.innerWidth < 768) {
      this.setState({ collapsed: true, width: window.innerWidth })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  logout() {
    request('/user/logout', 'POST').then(response => {
      getMessages(response)
      if (response.status === 200) {
        localStorage.removeItem('accessTokenDev')
        localStorage.removeItem('permissions')
        this.props.history.push('/login')
      }
    })
  }

  changeTheme(e) {
    let key = e.target.value
    localStorage.theme = key
    this.setState({
      theme: key,
      value: key
    })
  }

  handleResize() {
    this.setState({
      collapsed: window.innerWidth < 768,
      height: window.innerHeight
    })
  }

  toggleSider() {
    this.setState({ collapsed: !this.state.collapsed })
  }

  render() {
    let content = Object.keys(themes).map(key => {
      return (
        <Radio key={key} value={key}>
          <i
            className="color-option"
            style={{ backgroundColor: themes[key].primary }}
          />
          {key}
        </Radio>
      )
    })
    content = (
      <StyledRadioGroup theme={this.state.theme}>
        <RadioGroup
          className="theme-radio-group"
          onChange={this.changeTheme}
          value={this.state.value}>
          {content}
        </RadioGroup>
      </StyledRadioGroup>
    )

    return (
      <StyledLayoutWrapper theme={this.state.theme}>
        <Layout>
          <SideBar
            history={this.props.history}
            collapsed={this.state.collapsed}
            theme={this.state.theme}
          />
          <Layout>
            <Header className="app-header flex-between">
              <Icon
                className="toggle-sider"
                onClick={this.toggleSider}
                type={
                  this.state.collapsed
                    ? 'format_indent_increase'
                    : 'format_indent_decrease'
                }
              />
              <div>
                <Popover
                  placement="bottom"
                  title={'Theme'}
                  content={content}
                  trigger="click">
                  <Icon className="header-icon settings" type="settings" />
                </Popover>
                <Button className="logout-button" onClick={this.logout}>
                  Logout
                </Button>
              </div>
            </Header>
            <Content className="app-content">
              <Route
                exact
                path="/home/dashboard"
                render={() => <Dashboard theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/attendance/add"
                render={props => (
                  <AttendanceForm theme={this.state.theme} {...props} />
                )}
              />
              <Route
                exact
                path="/home/attendance/edit/:id"
                render={props => (
                  <AttendanceForm theme={this.state.theme} edit {...props} />
                )}
              />
              <Route
                exact
                path="/home/attendance/list"
                render={props => (
                  <AttendanceList theme={this.state.theme} {...props} />
                )}
              />
              <Route
                exact
                path="/home/employee/add"
                render={() => <EmployeeForm theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/employee/edit/:id"
                render={() => <EmployeeForm edit theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/employee/view/:id"
                render={() => (
                  <EmployeeProfile
                    width={this.state.width}
                    theme={this.state.theme}
                  />
                )}
              />
              <Route
                exact
                path="/home/employee/list"
                render={() => <EmployeesList theme={this.state.theme} />}
              />
              {/* FV Lead */}
              <Route
                exact
                path="/home/fv-lead"
                render={() => <FvList theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/fv-lead/edit/:id"
                render={props => (
                  <FvForm
                    theme={this.state.theme}
                    edit
                    {...props}
                    width={this.state.width}
                  />
                )}
              />
              <Route
                exact
                path="/home/fv-lead/view/:id"
                render={props => (
                  <FVleadProfile theme={this.state.theme} edit {...props} />
                )}
              />
              {/* Lnddo */}
              <Route
                exact
                path="/home/lnddo-lead"
                render={() => <LnddoList theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/lnddo-lead/edit/:id"
                render={props => (
                  <LnddoForm
                    theme={this.state.theme}
                    edit
                    {...props}
                    width={this.state.width}
                  />
                )}
              />
              <Route
                exact
                path="/home/lnddo-lead/view/:id"
                render={props => (
                  <LnddoProfile theme={this.state.theme} edit {...props} />
                )}
              />
              <Route
                exact
                path="/home/department"
                render={() => <DepartmentList theme={this.state.theme} />}
              />
              <Route exact path="/home/leave" render={() => <Leave />} />
              <Route
                exact
                path="/home/leave/add"
                render={() => <LeaveForm />}
              />
              <Route
                exact
                path="/home/leave/list"
                render={() => <LeaveList />}
              />
              <Route exact path="/home/payroll" render={() => <Payroll />} />
              <Route
                exact
                path="/home/payroll/add"
                render={() => <PayrollForm />}
              />
              <Route
                exact
                path="/home/payroll/list"
                render={() => <PayrollList />}
              />
              <Route
                exact
                path="/home/resource"
                render={() => <ResourceList theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/recruitment"
                render={() => <Recruitment />}
              />
              <Route
                exact
                path="/home/recruitment/add"
                render={() => <RecruitmentForm />}
              />
              <Route
                exact
                path="/home/recruitment/list"
                render={() => <RecruitmentList />}
              />
              <Route exact path="/home/role/add" render={() => <RoleForm />} />
              <Route exact path="/home/role/list" render={() => <RoleList />} />
              <Route
                exact
                path="/home/branch/add"
                render={() => <BranchForm />}
              />
              <Route
                exact
                path="/home/branch/edit/:id"
                render={() => <BranchForm edit />}
              />
              <Route
                exact
                path="/home/branch/view/:id"
                render={() => <BranchForm view />}
              />
              <Route
                exact
                path="/home/branch/list"
                render={() => <BranchList theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/jobtitle/add"
                render={props => <JobTitleForm {...props} />}
              />
              <Route
                exact
                path="/home/jobtitle/edit/:id"
                render={props => <JobTitleForm edit {...props} />}
              />
              <Route
                exact
                path="/home/jobtitle/list"
                render={() => <JobTitleList theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/salary/list"
                render={() => <SalaryList theme={this.state.theme} />}
              />
              <Route
                exact
                path="/home/salary/add"
                render={props => (
                  <SalaryForm theme={this.state.theme} {...props} />
                )}
              />
              <Route
                exact
                path="/home/salary/edit/:id"
                render={props => (
                  <SalaryForm edit theme={this.state.theme} {...props} />
                )}
              />
              <Route
                exact
                path="/home/file-system/my-drive/folder/:id"
                render={props => (
                  <Files type="my-drive" theme={this.state.theme} {...props} />
                )}
              />
              <Route
                exact
                path="/home/file-system/public-network/folder/:id"
                render={props => (
                  <Files
                    type="public-network"
                    theme={this.state.theme}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/home/file-system/shared-drive/folder/:id"
                render={props => (
                  <Files
                    type="shared-drive"
                    customRoute="shared-drive"
                    theme={this.state.theme}
                    sharedDrive
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/home/salary/view/:id"
                render={props => (
                  <SalaryForm edit view theme={this.state.theme} {...props} />
                )}
              />
            </Content>
          </Layout>
        </Layout>
      </StyledLayoutWrapper>
    )
  }
}
