import React, { Component } from 'react'
import {
  Form,
  Modal,
  InputNumber,
  Dropdown,
  Menu,
  Icon,
  Button,
  Row,
  Col,
  Input,
  Tag
} from 'antd'
import style from 'styled-components'
import Table from '../../utils/TableWrapper'
import { formUtil } from '../../utils/style-utils'

const FormItem = Form.Item

let StyledStructureForm = style.div`
  ${formUtil}
`
let StyleBottonModal = style.div`
  & .edit-button{
    border: none !important;
    &:hover{
      background:transparent !important;
      border: none !important;
    }
  }
`
class StructureForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      visible: false,
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          priority: 1
        },
        {
          title: 'Value',
          dataIndex: 'value',
          priority: 2
        }
      ],
      activeIndex: null
    }
    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleActionsMenu = this.handleActionsMenu.bind(this)
    this.reloadData = this.reloadData.bind(this)
    this.haveValue = this.haveValue.bind(this)
    this.add = this.add.bind(this)
  }
  componentDidMount() {
    if (this.props.data) {
      this.setState({ data: this.props.data })
    }
    let tableColumns = this.state.columns
    if (!this.props.view) {
      tableColumns.push({
        title: 'Operations',
        dataIndex: 'actions',
        key: 'actions',
        priority: 1,
        render: (text, record, index) => {
          return (
            <Dropdown
              overlay={
                <Menu
                  onClick={({ item, key, keyPath }) => {
                    this.handleActionsMenu(key, record, index)
                  }}>
                  <Menu.Item key="1">
                    <Icon style={{ marginRight: '8px' }} type="edit" />
                    Edit
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Icon style={{ marginRight: '8px' }} type="delete" />
                    Delete
                  </Menu.Item>
                </Menu>
              }>
              <Button style={{ marginLeft: 8, border: 0 }}>
                <Icon type="bars" /> <Icon type="down" />
              </Button>
            </Dropdown>
          )
        }
      })
    }

    this.setState({ columns: tableColumns })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ data: nextProps.data })
    }
  }

  handleActionsMenu(key, record, index) {
    if (key === '1') {
      this.showModal()
      this.props.form.setFieldsValue(record)
      this.setState({ activeIndex: index })
    } else if (key === '2') {
      let { data } = this.state
      data.splice(index, 1)
      this.setState({ data })
      this.props.update(data)
    }
  }

  showModal() {
    this.setState({
      visible: true
    })
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleCancel() {
    this.setState({ visible: false, activeIndex: null })
    this.props.form.resetFields()
  }

  reloadData() {}

  add(values) {
    let { data } = this.state
    if (this.state.activeIndex !== null) {
      data[this.state.activeIndex] = values
      this.setState({
        activeIndex: null
      })
    } else {
      data.push(values)
    }
    this.setState({ data })
    this.props.update(data)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.add(values)
        this.handleCancel()
      }
    })
  }

  handleSelectChange = value => {}

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        {!this.props.view && (
          <StyleBottonModal>
            <Tag>Structure</Tag>
            <div>
              <Button icon="plus" type="primary" onClick={this.showModal}>
                Add
              </Button>
            </div>
          </StyleBottonModal>
        )}
        <Modal
          visible={this.state.visible}
          title="Structure"
          width={500}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}>
          <StyledStructureForm theme={this.props.theme}>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col span={24}>
                  <FormItem className={this.haveValue(`name`)} label="Name">
                    {getFieldDecorator(`name`, {
                      rules: [
                        { required: true, message: 'Please enter the Name' }
                      ]
                    })(<Input placeholder="Name" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItem className={this.haveValue(`value`)} label="Value">
                    {getFieldDecorator(`value`, {
                      rules: [
                        { required: true, message: 'Please enter the value' }
                      ]
                    })(<InputNumber placeholder="Value" />)}
                  </FormItem>
                </Col>
              </Row>
              <Button type="primary" onClick={e => this.handleSubmit(e)}>
                Add
              </Button>
            </Form>
          </StyledStructureForm>
        </Modal>
        {this.state.data.length > 0 ? (
          <Table
            dataSource={this.state.data}
            columns={this.state.columns}
            rowKey="_id"
            notSearch
          />
        ) : null}
      </div>
    )
  }
}
export default Form.create()(StructureForm)
