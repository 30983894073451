import 'whatwg-fetch'
import { getMessages } from '../utils/utils'

const API_URL = process.env.REACT_APP_BACKEND_URL

let request = (
  endPoint,
  method = 'GET',
  requestObject = null,
  logged = true,
  verification = null,
  formData = false
) => {
  let options = {}
  options.headers = new Headers()
  options.method = method
  if (logged) {
    options.headers.append('Authorization', localStorage.accessTokenDev)
  } else if (verification) {
    options.headers.append('Authorization', verification)
  }
  if (requestObject && !formData) {
    options.body = JSON.stringify(requestObject)
  } else if (requestObject && formData) {
    options.body = requestObject
  }
  if (formData) {
    delete options.headers['Content-Type']
    //options.headers.append('Content-Type', `multipart/form-data; boundary=${requestObject._boundary}`)
  } else {
    options.headers.append('Content-Type', 'application/json')
  }
  return fetch(API_URL + endPoint, options)
    .then(response => {
      if (
        ((response.status >= 403 && response.status <= 405) ||
          response.status === 500) &&
        !process.env.REACT_APP_DEV_MODE
      ) {
        window.location = `/error-${response.status}`
      } else {
        return response.json()
      }
    })
    .catch(error => {
      getMessages(error)
      return {
        status: null
      }
    })
}

export default request
