import 'antd/dist/antd.css'
import React, { Component } from 'react'
import {
  Button,
  Dropdown,
  Modal,
  Menu,
  Card,
  Col,
  DatePicker,
  Icon,
  notification,
  Form,
  Select,
  Row,
  Tag,
  Input
} from 'antd'
import MaterialIcon from '../../utils/icon/Icon'
import { getUrl } from '../../utils/utils'
import { Link } from 'react-router-dom'
import request from '../../utils/requestWrapper'
import Table from '../../utils/TableWrapper'
import styled from 'styled-components'
import moment from 'moment'
import User from '../../assets/images/user.png'
import { themes, formUtil, filterUtil } from '../../utils/style-utils'

const FormItem = Form.Item
const confirm = Modal.confirm

let StyledFvList = styled.div`
  ${formUtil} ${filterUtil};
`
const columns = [
  {
    title: 'CIF Number',
    dataIndex: 'cifNumber',
    priority: 2
  },
  {
    title: 'Status',
    dataIndex: 'status',
    priority: 3
  },
  {
    title: 'SFTP',
    dataIndex: 'isSftp',
    priority: 3,
    render: text => text == 1 ? 'Uploaded': 'Not Uploaded'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    priority: 2
  },
  {
    title: 'Employer Name',
    dataIndex: 'employer',
    priority: 4
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    priority: 4,
    render: text => moment(text).format('DD-MM-YYYY h:mm')
  }
]

const feedbackKeys = [
  { key: 'aboutApplicant', name: 'About Applicant' },
  { key: 'aboutCompany', name: 'About Company' },
  { key: 'additionalPoints', name: 'Additional Points' },
  { key: 'overallComment', name: 'Overall Comment' },
  { key: 'findLoc', name: 'Find Loc' }
]
notification.config({
  placement: 'bottomRight',
  bottom: 50
})

const StyledEmployeeCardList = styled.div`
  .ant-card {
    background: #ffffff;
    margin: 0;
    max-width: 100%;
    min-height: 320px;
    cursor: default;
  }
  & .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3em;
  }
  i.material-icon {
    margin: 0;
    font-size: 18px;
    width: 22px;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    border: 2px solid ${props => themes[props.theme].textAndIcons};
  }
  h3 {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    margin-top: 1em;
    word-break: break-all;
  }
  .with-icon-row {
    word-wrap: break-word;
  }
  .card-col {
    padding: 1em;
  }
  @media screen and (min-width: 768px) {
    .ant-card {
      min-height: 380px;
    }
    h3 {
      font-size: 25px;
    }
  }
`
class FvList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 1,
      action: null,
      id: null,
      loading: true,
      showLogin: false,
      data: [],
      pagination: {
        page: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      config: null,
      params: { page: 1, pageSize: 10 },
      showFilter: false,
      columns,
      employees: null
    }

    this.isAllowedEdit = this.loadFVPermissions()
  }

  loadFVPermissions() {
    const arPermissions = JSON.parse(localStorage.permissions);
    // resourceCode
    for (let i = 0; i < arPermissions.length; i++) {
      if (arPermissions[i].resourceCode == "FV-LEAD" && (arPermissions[i].actions).indexOf("patch") >= 0) {
        return true;
      }
    }

    return false;
  }

  handleManualReportUpload(id) {
    let endPoint = `/fv-lead/${id}/manual-upload`
    this.setState({ loading: true })
    request(endPoint, 'POST').then(response => {
      if (response.status === 200) {
        this.setState({ loading: false })
        notification.success({
          message: <span style={{ fontSize: '12px' }}>{response.message}</span>,
          duration: 5
        })
      } else {
        this.setState({ loading: false })
        notification.error({
          message: <span style={{ fontSize: '12px' }}>{response.message}</span>,
          duration: 5
        })
      }
    })
  }

  handleActionsMenu = (item, key, keyPath, id) => {
    if (key === '3') {
      this.handleManualReportUpload(id)
    }
  }

  handleMenuClick = key => {
    if (key === '1') {
      this.setState({ view: 1 })
    } else {
      this.setState({ view: 2 })
    }
  }

  componentDidMount() {
    this.getFvleads(this.state.params)
  }

  getEmployees = () => {
    this.setState({ loading: true, showLogin: false, id: null })
    let endPoint = 'helper/employee'
    request(endPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        this.setState({
          employees: response.data
        })
      }
    })
  }

  reloadData() {
    this.getFvleads()
  }

  makeApiTOExportData = params => {
    this.setState({ loading: true, showLogin: false, id: null })
    let endPoint = getUrl('/fv-lead/export', params)

    request(endPoint).then(response => {
      this.setState({ loading: false })
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        window.open(response.data.path)
      }
    })
  }

  getFvleads = params => {
    this.setState({ loading: true, showLogin: false, id: null })
    let endPoint = getUrl('/fv-lead', params)

    request(endPoint).then(response => {
      this.setState({ loading: false })
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        const pagination = Object.assign({}, this.state.pagination)
        pagination.total = response.navigation.total
        this.setState({
          data: response.data,
          pagination
        })
      }
    })
  }

  resetSearch = () => {
    let { params } = this.state
    delete params['filter']
    this.setState({ params })
    this.props.form.resetFields()
    this.getFvleads(params)
  }

  exportData = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let filter = ''
        Object.keys(values).forEach((key, i) => {
          if (Array.isArray(values[key]) && values[key].length > 0) {
            filter += `${key}:${values[key].toString().replace(/,/g, ';')},`
          } else if (values[key] && (key === "from" || key === "to")) {
            filter += `${key}:${values[key].format("YYYY-MM-DD")},`;
          } else if (values[key] && key === 'employee') {
            filter += `created_by:${values.employee}`
          } else if (key === 'feedback') {
            Object.keys(values.feedback).forEach(k => {
              if (values.feedback[k]) {
                filter += `feedback.${k}:${values.feedback[k]},`
              }
            })
          } else if (values[key] && values[key] !== '') {
            filter += `${key}:${values[key]},`
          }
        })
        if (filter !== '') {
          filter = filter.slice(0, -1)
          filter = 'filters=' + filter
          let newFilter = Object.assign(this.state.params, { filter })
          newFilter.page = 1
          let pagination = this.state.pagination
          pagination.current = 1
          this.setState({ pagination, params: newFilter })
          this.makeApiTOExportData(newFilter)
        }
      }
    })
  }

  toggleFilter = () => {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter
    }))
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let filter = ''
        Object.keys(values).forEach((key, i) => {
          if (Array.isArray(values[key]) && values[key].length > 0) {
            filter += `${key}:${values[key].toString().replace(/,/g, ';')},`
          } else if (values[key] && (key === "from" || key === "to")) {
            filter += `${key}:${values[key].format("YYYY-MM-DD")},`;
          } else if (values[key] && key === 'employee') {
            filter += `created_by:${values.employee}`
          } else if (key === 'feedback') {
            Object.keys(values.feedback).forEach(k => {
              if (values.feedback[k]) {
                filter += `feedback.${k}:${values.feedback[k]},`
              }
            })
          } else if (values[key] && values[key] !== '') {
            filter += `${key}:${values[key]},`
          }
        })
        if (filter !== '') {
          filter = filter.slice(0, -1)
          filter = 'filters=' + filter
          let newFilter = Object.assign(this.state.params, { filter })
          newFilter.page = 1
          let pagination = this.state.pagination
          pagination.current = 1
          this.setState({ pagination, params: newFilter })
          this.getFvleads(newFilter)
        }
      }
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = Object.assign({}, this.state.pagination)
    pager.current = pagination.current
    pager.pageSize = pagination.pageSize
    this.setState({
      pagination: pager
    })
    let params = this.state.params
    params.pageSize = pagination.pageSize
    params.page = pagination.current
    if (sorter.field) {
      params['sort'] = {
        field: sorter.field,
        order: sorter.order === 'descend' ? 'desc' : 'asc'
      }
    }
    this.getFvleads(params)
  }

  render() {
    let tableColumns = []
    const { getFieldDecorator } = this.props.form

    this.state.columns.forEach(column => {
      tableColumns.push(column)
    })

    tableColumns.push({
      title: 'Operations',
      dataIndex: 'actions',
      key: 'actions',
      priority: 1,
      render: (text, record, index) => {
        return (
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key, keyPath }) => {
                  this.handleActionsMenu(item, key, keyPath, record._id)
                }}>
                <Menu.Item key="1">
                  <Link
                    to={{
                      pathname: `/home/fv-lead/view/${record._id}`,
                      state: { profile: record },
                      message: null
                    }}>
                    <Icon style={{ marginRight: '8px' }} type="eye" />
                    View
                  </Link>
                </Menu.Item>
                {(this.isAllowedEdit && record.status == 'awaiting') ? 
                (<Menu.Item key="2">
                  <Link to={{ pathname: `/home/fv-lead/edit/${record._id}` }}>
                    <Icon style={{ marginRight: '8px' }} type="edit" />
                    Edit
                  </Link>
                </Menu.Item>) : '' }
                {(this.isAllowedEdit && record.status == 'reported' && record.isSftp != 1) ? 
                (<Menu.Item key="3">
                    <Icon style={{ marginRight: '8px' }} type="upload" />
                    Upload
                </Menu.Item>) : '' }
              </Menu>
            }>
            <Button style={{ marginLeft: 8, border: 0 }}>
              <Icon type="bars" /> <Icon type="down" />
            </Button>
          </Dropdown>
        )
      }
    })
    const menu = (
      <Menu onClick={({ item, key, keyPath }) => this.handleMenuClick(key)}>
        <Menu.Item key="1">Table</Menu.Item>
        <Menu.Item key="2">Card</Menu.Item>
      </Menu>
    )
    let cards = null
    if (this.state.data.length > 0) {
      cards = this.state.data.map(employee => {
        return (
          <StyledEmployeeCardList theme={this.props.theme}>
            <Col className="card-col" xs={24} sm={6} md={6} lg={6}>
              <Card hoverable>
                <div className="info-container">
                  <img
                    alt=""
                    src={
                      employee.displayPic && employee.displayPic.original
                        ? employee.displayPic.original
                        : User
                    }
                  />
                  <h3>{`${employee.firstName} ${employee.lastName}`}</h3>
                  <div className="with-icon-row">
                    <MaterialIcon type="email" />
                    <span>{`${employee.email}`}</span>
                  </div>
                  <Link
                    to={{
                      pathname: `/home/fv-lead/view/${employee._id}`,
                      state: { profile: employee },
                      message: null
                    }}>
                    <Button icon="eye">View</Button>
                  </Link>
                </div>
              </Card>
            </Col>
          </StyledEmployeeCardList>
        )
      })
    }

    return (
      <StyledFvList theme={this.props.theme}>
        {/*         <Dropdown overlay={menu}>
          <Button className="switch-button" style={{ marginBottom: '1em' }}>
            {this.state.view === 1 ? 'Table' : 'Card'} <Icon type="down" />
          </Button>
        </Dropdown> */}
        {this.state.view === 1 && (
          <div>
            <Row>
              <Icon
                className="filter-icon"
                type="filter"
                title="Filter"
                onClick={this.toggleFilter}
              />
            </Row>
            <Card
              style={{
                margin: '16px 0px',
                display: this.state.showFilter ? 'block' : 'none'
              }}
              bodyStyle={{ padding: '15px' }}>
              <Form onSubmit={this.handleSubmit}>
                <Tag>FILTERS</Tag>
                <Row>
                  {/* {this.state.employees && this.state.employees.length && (
                    <Col xs={24} sm={6}>
                      <FormItem colon={false}>
                        {getFieldDecorator('employee')(
                          <Select
                            optionFilterProp="children"
                            showSearch
                            placeholder="Employee">
                            {this.state.employees.map(employee => (
                              <Select.Option
                                key={employee._id}
                                value={employee._id}>
                                {`${employee.firstName} ${employee.lastName}`}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  )} */}
                  <Col xs={24} sm={6}>
                    <FormItem colon={false}>
                      {getFieldDecorator('from')(
                        <DatePicker
                          style={{ width: '100%' }}
                          placeholder="Created From"
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={6}>
                    <FormItem colon={false}>
                      {getFieldDecorator('to')(
                        <DatePicker
                          style={{ width: '100%' }}
                          placeholder="Created To"
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={6}>
                    <FormItem colon={false}>
                      {getFieldDecorator('status')(
                        <Select
                          optionFilterProp="children"
                          showSearch
                          placeholder="Status">
                          <Select.Option key={1} value="reported">
                            Reported
                          </Select.Option>
                          <Select.Option key={2} value="awaiting">
                            Awaiting
                          </Select.Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={6}>
                    <FormItem colon={false}>
                      {getFieldDecorator('cifNumber')(<Input placeholder="CIF Number" />)}
                    </FormItem>
                  </Col>
                  {/* isSftp */}
                  {/* {feedbackKeys.map(item => (
                    <Col xs={24} sm={6}>
                      <FormItem colon={false}>
                        {getFieldDecorator(`feedback.${item.key}`)(
                          <Input placeholder={item.name} />
                        )}
                      </FormItem>
                    </Col>
                  ))} */}
                  <Col xs={24} sm={6}>
                    <FormItem colon={false}>
                      {getFieldDecorator('isSftp')(
                        <Select
                          optionFilterProp="children"
                          showSearch
                          placeholder="SFTP Status">
                          <Select.Option key={1} value="1">
                            Uploaded
                          </Select.Option>
                          <Select.Option key={2} value="0">
                            Not Uploaded
                          </Select.Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FormItem colon={false}>
                      <Button type="primary" htmlType="submit">
                        Search
                      </Button>
                    </FormItem>
                    <FormItem colon={false}>
                      <Button type="default" onClick={this.exportData.bind(this)}>
                        Export
                      </Button>
                    </FormItem>
                    {this.state.params && this.state.params.filter ? (
                      <FormItem colon={false}>
                        <Button type="dashed" onClick={this.resetSearch}>
                          Reset
                        </Button>
                      </FormItem>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </Card>
            <Card>
              <Table
                dataSource={this.state.data}
                columns={tableColumns}
                loading={this.state.loading}
                rowKey="_id"
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </Card>
          </div>
        )}
        {this.state.view === 2 && <div>{cards}</div>}
      </StyledFvList>
    )
  }
}
export default Form.create()(FvList)
