import React from 'react'
import { Row, Col, Card, message, Drawer, Icon, Tooltip } from 'antd'
import styled from 'styled-components'
import request from '../../utils/requestWrapper'
import MaterialIcon from '../../utils/icon/Icon'
import Spinner from '../../utils/Spinner'

const StyledIframe = styled.div`
  iframe {
    width: 100%;
    min-height: 600px;
    overflow: hidden;
  }
`
const StyledIcon = styled(MaterialIcon)`
  position: absolute !important;
  top: 5px;
  right: -13px;
  font-size: 13px;
  margin: 0 !important;
`
const imgTypes = ['jpeg', 'jpg', 'png', 'bmp', 'gif', 'tiff']

const types = [
  ...imgTypes,
  'pdf',
  'txt',
  'json',
  'js',
  'MP3',
  'MP4',
  'AVI',
  '3GP'
]

const typesIgnore = ['zip', 'rar', 'html', 'tar', 'rtf', 'csv']
message.config({
  maxCount: 1
})
export default class FileViewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      url: null,
      loading: false,
      drawerWidth: '70%',
      drawerIcon: 'fullscreen'
    }
  }

  showDrawer = () => {
    if (typesIgnore.indexOf(this.props.file.format.toLowerCase()) === -1) {
      let endPoint = `/${this.props.type}/file/${this.props.file._id}`
      this.setState({
        loading: true
      })
      request(endPoint).then(response => {
        this.setState({
          url: response.data.url,
          visible: true,
          loading: false
        })
      })
    } else {
      message.info('Please download and view file')
    }
  }

  onClose = () => {
    this.setState({
      visible: false
    })
    let elem = document.getElementById('iframe-viewer')
    if (elem) {
      elem.parentNode.removeChild(elem)
    }
  }

  onFileClose = () => {
    this.setState({
      drawerWidth: '70%',
      drawerIcon: 'fullscreen'
    })
    this.onClose()
  }

  onFullScreen = () => {
    this.setState({
      drawerWidth: this.state.drawerWidth === '70%' ? '100%' : '70%',
      drawerIcon:
        this.state.drawerIcon === 'fullscreen-exit'
          ? 'fullscreen'
          : 'fullscreen-exit'
    })
  }

  render() {
    const { name, format, share } = this.props.file
    const src =
      types.indexOf(format.toLowerCase()) !== -1
        ? this.state.url
        : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            this.state.url
          )}`
    return (
      <React.Fragment>
        <Spinner loading={this.state.loading} />
        <Tooltip title={name}>
          <Card onClick={this.showDrawer}>
            <Icon type="file" />
            <div className={`format ${format}`}>{format}</div>
            <span>{name}</span>
            {share && share.length > 0 && (
              <StyledIcon type="share" title="Shared" />
            )}
          </Card>
        </Tooltip>
        {typesIgnore.indexOf(format) === -1 && (
          <Drawer
            closable={false}
            width={this.state.drawerWidth}
            destroyOnClose={true}
            placement="right"
            onClose={this.onClose}
            visible={this.state.visible}>
            {this.state.url && (
              <StyledIframe>
                <Row gutter={48}>
                  <Col span={12}>
                    <h3>{name}</h3>
                  </Col>
                  <Col span={3} offset={9} style={{ textAlign: 'right' }}>
                    <Icon
                      onClick={this.onFullScreen}
                      type={this.state.drawerIcon}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                    &nbsp;&nbsp;
                    <Icon
                      onClick={this.onFileClose}
                      type="close"
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  </Col>
                </Row>
                <br />
                {!imgTypes.includes(format.toLowerCase()) ? (
                  <iframe
                    src={src}
                    id="iframe-viewer"
                    frameBorder="0"
                    order="0"
                  />
                ) : (
                  <img width={'100%'} height={'100%'} src={src} alt={name} />
                )}
              </StyledIframe>
            )}
          </Drawer>
        )}
      </React.Fragment>
    )
  }
}

/* 

 `https://docs.google.com/viewer?url=${
                      this.state.url
                    }&embedded=true`
*/
