import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import LayoutWrapper from './components/LayoutWrapper'
import UserVerification from './components/UserVerification'

import Login from './components/Login'
import Error from './utils/Error'

export default class App extends Component {
  componentWillMount() {
    let element = document.getElementById('load')
    element.parentNode.removeChild(element)
  }
  render() {
    return (
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route path="/home" render={props => <LayoutWrapper {...props} />} />
        <Route exact path="/login" component={Login} />
        <Route path="/user/verification/:token" component={UserVerification} />
        <Route exact path="/error-403" render={() => <Error code="403" />} />
        <Route exact path="/error-404" render={() => <Error code="404" />} />
        <Route exact path="/error-405" render={() => <Error code="405" />} />
        <Route exact path="/error-500" render={() => <Error code="500" />} />
      </Switch>
    )
  }
}
