/* eslint-disable no-alert, no-console */
import React, { Component } from 'react'
import { Layout, Menu } from 'antd'
import style from 'styled-components'
import Icon from '../utils/icon/Icon'
import { themes } from '../utils/style-utils'
const { Sider } = Layout
const SubMenu = Menu.SubMenu

let jsUcfirst = string => string.charAt(0).toUpperCase() + string.slice(1)

let menusInfo = {
  home: {
    icon: 'dashboard',
    resource: 'Dashboard'
  },
  employee: {
    icon: 'account_box',
    resource: 'Employee',
    subMenu: true
  },
  salary: {
    icon: 'credit_card',
    resource: 'Salary',
    subMenu: true
  },
  jobtitle: {
    icon: 'card_travel',
    resource: 'Job Title',
    subMenu: true
  },
  branch: {
    icon: 'location_city',
    resource: 'Branch',
    subMenu: true
  },
  resource: {
    icon: 'insert_drive_file',
    resource: 'Resource'
  },
  department: {
    icon: 'assignment_ind',
    resource: 'Department'
  },
  attendance: {
    icon: 'transfer_within_a_station',
    resource: 'Attendance',
    subMenu: true
  },
  payroll: {
    icon: 'receipt',
    resource: 'Payroll'
  },
  payment: {
    icon: 'contacts',
    resource: 'Payment'
  },
  leave: {
    icon: 'layers',
    resource: 'Leave'
  },
  recruitment: {
    icon: 'group_add',
    resource: 'Recruitment'
  },
  config: {
    icon: 'settings',
    resource: 'config'
  },
  'file-system': {
    icon: 'backup',
    resource: 'Cloud Storage',
    subMenu: true,
    customRoutes: {
      'My Drive': '/home/file-system/my-drive/folder/root',
      'Public Network': '/home/file-system/public-network/folder/root',
      'Shared Drive': '/home/file-system/shared-drive/folder/root'
    }
  }
}

let customIcons = {
  'shared-drive': 'folder_shared',
  'public-network': 'device_hub',
  'my-drive': 'person_pin',
  '/file-system': 'cloud',
  '/payroll': 'receipt',
  '/admin': 'person'
}

const StyledSideBar = style.div`
  & .ant-layout-sider{
    position: relative;
    margin: 0;
    height:100vh;
    padding-bottom: 1.875rem;
    background-color: ${props => themes[props.theme].primary};
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 200;
  }
  & .app-logo a{
    height:46px;
    color  ${props => themes[props.theme].textAndIcons};
    font-familyinherit;
    font-size:18px;
    vertical-align:middle;
    display:flex;
    align-items:center;
    cursor:pointer;
    text-decoration:none;
    padding-left:${props => (props.collapsed ? '6px' : '28px')};
    text-transform:uppercase;
    font-weight:600;
    background:${props => themes[props.theme].darkPrimary};
  }
  & .app-logo a img{
    max-width:100%;
    max-height:70%;
    margin-right:5px;
  }
  *App sider menu*/
  & .header-menu .ant-menu-submenu-active>.ant-menu-submenu-title,
  & .header-menu .ant-menu-submenu-open:hover>.ant-menu-submenu-title,
  & .ant-menu-submenu-selected>.ant-menu-submenu-title,
  & .header-menu .ant-menu-item-active,
  & .header-menu .ant-menu-item-selected{
    color:red !important;
  }
  & .ant-menu-vertical .ant-menu-item:after,
  & .ant-menu-vertical-left .ant-menu-item:after,
  & .ant-menu-vertical-right .ant-menu-item:after,
  & .ant-menu-inline .ant-menu-item:after{
    border:none;
  }

  & .ant-menu-submenu-title .ant-menu-submenu-arrow,
  & .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background:${props => themes[props.theme].textAndIcons} !important;
    position:relative;
    top:4px;
    right:3px;
  }
  & .ant-menu-submenu-active>a{

  }
  & .ant-menu-submenu-title,
  & .ant-menu-item{
    &:hover{
      color: ${props => themes[props.theme].secondaryText};
      & .ant-menu-submenu-arrow,
      & .ant-menu-submenu-arrow::before,
      & .ant-menu-submenu-arrow::after {
        background:${props => themes[props.theme].secondaryText} !important;
      }
    }
  }
  & .ant-menu-item, .ant-menu-submenu{
    background-color: ${props => themes[props.theme].primary};
    color: ${props => themes[props.theme].textAndIcons};
  }
  & .ant-menu-item.ant-menu-item-selected{
    background-color: ${props => themes[props.theme].lightPrimary} !important;
  }
  li.ant-menu-item, div.ant-menu-submenu-title, li.ant-menu-item.ant-menu-item-selected{
    margin:0 !important;
    border-right: 1px solid ${props => themes[props.theme].primary};
    height:50px !important;
  }


  & .ant-menu-submenu .ant-menu-item{
    background-color: ${props => themes[props.theme].darkPrimary};
  }

  & .ant-menu-vertical{
    position:relative;
    left:0;
  }
  div.ant-menu-submenu-title {
    padding-top:5px;
  }
  li.ant-menu-item,
  & .ant-menu-inline-collapsed > .ant-menu-item,
  & .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  & .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{
    padding-left:${props => (props.collapsed ? '20px !important' : '23px')};
    padding-top:${props => (props.collapsed ? '7px' : '5px')} !important;
  }
  & .menu-without-childs{
    background-color: ${props => themes[props.theme].primary} !important;
    padding-left: 48px;
  }
  & .ant-menu-item > a {
    color: ${props => themes[props.theme].textAndIcons};
  }
  & .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open>.ant-menu-submenu-title>span>span{
  }
  li.ant-menu-item.ant-menu-item-selected{
  }
  h4.title {
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    background-color: ${props => themes[props.theme].primary};
    text-transform: uppercase;
    padding: 32px 0px 32px 30px;
    margin:0;
  }
  & .material-icon{
    position:relative;
    top:7px;
    margin-right:10px;
  }
`

export default class SideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      permissions: null,
      parentPaths: null
    }
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.createMenu = this.createMenu.bind(this)
  }

  componentWillMount() {
    if (localStorage.permissions) {
      this.createMenu()
    }
  }

  createMenu() {
    //TODO improve this method, try reduce code
    let permissions = JSON.parse(localStorage.permissions)
    let parentPaths = []
    permissions.forEach(resource => {
      if (
        resource.parentPath &&
        parentPaths.indexOf(resource.parentPath) === -1
      ) {
        parentPaths.push(resource.parentPath)
      }
    })

    let parentMenus = parentPaths.map(path => {
      let childrens = []
      permissions.forEach(resource => {
        let key = resource.path.slice(1)
        if (resource.parentPath && resource.parentPath === path) {
          let item = menusInfo[key] || {
            icon: customIcons[key] || 'list',
            resource: key
          }
          let menu = null
          if (item.subMenu) {
            menu = (
              <SubMenu
                key={resource.path}
                title={
                  <span>
                    <Icon type={item.icon || 'list'} />
                    <span>{jsUcfirst(key).replace('-', ' ')}</span>
                  </span>
                }>
                {resource.actions.indexOf('post') !== -1 && (
                  <Menu.Item key={`/home/${key}/add`}>Add</Menu.Item>
                )}
                {(resource.actions.indexOf('get-all') !== -1 ||
                  resource.actions.indexOf('getall') !== -1 ||
                  resource.actions.indexOf('get') !== -1) && (
                  <Menu.Item key={`/home/${key}/list`}>List</Menu.Item>
                )}
              </SubMenu>
            )
          } else {
            menu = (resource.actions.indexOf('get-all') !== -1 ||
              resource.actions.indexOf('getall') !== -1 ||
              resource.actions.indexOf('get') !== -1) && (
              <Menu.Item
                className="menu-without-childs"
                key={
                  key === 'home'
                    ? '/home/dashboard'
                    : menusInfo[path.slice(1)] &&
                      menusInfo[path.slice(1)].customRoutes
                    ? menusInfo[path.slice(1)].customRoutes[resource.resource]
                    : `/home/${key}`
                }>
                <Icon type={item.icon} />
                <span>{jsUcfirst(key).replace('-', ' ')}</span>
              </Menu.Item>
            )
          }
          childrens.push(menu)
        }
      })
      return (
        <SubMenu
          key={path}
          title={
            <span>
              <Icon type={customIcons[path] || 'list'} />
              <span>{jsUcfirst(path.slice(1)).replace('-', ' ')}</span>
            </span>
          }>
          {childrens}
        </SubMenu>
      )
    })

    let menusWithoutParents = []

    permissions.forEach(resource => {
      if (parentPaths.indexOf(resource.path) !== -1 || resource.parentPath) {
        return
      } else {
        let key = resource.path.slice(1)
        let item = menusInfo[key] || {
          icon: 'list',
          resource: key
        }
        let menu = null
        if (item.subMenu) {
          menu = (
            <SubMenu
              key={resource.path}
              title={
                <span>
                  <Icon type={item.icon} />
                  <span>{jsUcfirst(key)}</span>
                </span>
              }>
              {resource.actions.indexOf('post') !== -1 && (
                <Menu.Item key={`/home/${key}/add`}>Add</Menu.Item>
              )}
              {(resource.actions.indexOf('get-all') !== -1 ||
                resource.actions.indexOf('getall') !== -1 ||
                resource.actions.indexOf('get') !== -1) && (
                <Menu.Item key={`/home/${key}/list`}>List</Menu.Item>
              )}
            </SubMenu>
          )
        } else if (item.customSubMenu) {
          menu = (
            <SubMenu
              key={resource.path}
              title={
                <span>
                  <Icon type={item.icon || 'list'} />
                  <span>{jsUcfirst(resource.resource)}</span>
                </span>
              }>
              {item.customSubMenu.map(item => (
                <Menu.Item key={`/home${item.path}`}>
                  {jsUcfirst(item.name)}
                </Menu.Item>
              ))}
            </SubMenu>
          )
        } else {
          menu = (resource.actions.indexOf('get-all') !== -1 ||
            resource.actions.indexOf('getall') !== -1 ||
            resource.actions.indexOf('get') !== -1) && (
            <Menu.Item
              className="menu-without-childs"
              key={
                key === 'home'
                  ? '/dashboard'
                  : key === 'file-system'
                  ? `/home/file-system/folder/root`
                  : `/home/${key}`
              }>
              <Icon type="dashboard" />
              <span>{jsUcfirst(key).replace('-', ' ')}</span>
            </Menu.Item>
          )
        }
        menusWithoutParents.push(menu)
      }
    })
    this.setState({ parentMenus, menusWithoutParents })
  }

  handleMenuClick(e) {
    this.props.history.push(e.key)
  }

  render() {
    return (
      <StyledSideBar collapsed={this.props.collapsed} theme={this.props.theme}>
        <Sider
          width={250}
          trigger={null}
          collapsible
          collapsedWidth={60}
          collapsed={this.props.collapsed}>
          <div className="app-logo">
            <a href="/home/file-system/private/folder/root">Cloud ERP</a>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[this.props.history.location.pathname]}
            defaultSelectedKeys={[this.props.history.location.pathname]}
            onClick={this.handleMenuClick}>
            {this.state.parentMenus}
            {this.state.menusWithoutParents}
            {/*             <Menu.Item
              className="menu-without-childs"
              key='/home/files'>
              <Icon type='cloud' />
              <span>File Manager</span>
            </Menu.Item> */}
          </Menu>
        </Sider>
      </StyledSideBar>
    )
  }
}
