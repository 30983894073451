import React, { Component } from 'react'
import styled from 'styled-components'
import { Form, Input, Icon, Button, Card } from 'antd'
import { themes, selectedColor } from '../utils/style-utils'
import { getMessages } from '../utils/utils'
import request from '../utils/requestWrapper'
import { withRouter } from 'react-router-dom'
import ForgotPasssword from './ForgotPasssword'
import moment from 'moment'

function getGreetingTime(m) {
  let g = null
  if (!m || !m.isValid()) {
    return
  }
  let split_afternoon = 12
  let split_evening = 17
  let currentHour = parseFloat(m.format('HH'))
  if (currentHour >= split_afternoon && currentHour <= split_evening) {
    g = 'good afternoon'
  } else if (currentHour >= split_evening) {
    g = 'good evening'
  } else {
    g = 'good morning'
  }
  return g
}
const FormItem = Form.Item

let StyledLogin = styled.div`
  background: #F2F4F8;
  padding:1.5em;
  min-height:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  .ant-card{
    width:100%;
    border-radius:10px;
    box-shadow: 0 7px 20px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.1)
  }
  h2.title{
    display: none;
  }
  .footer-list{
    display: none;
  }
  label{
    font-weight: bold;
    margin-bottom: .4em;
    font-size:12px;
  }
  .ant-input, .ant-btn{
    height: 40px;
  }
  .ant-card-body{
    padding:0;
    display:flex;
    flex-direction: row;
    height:100%;
  }
  .organization-container{
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 20px;
  }
  .organization-name {
    padding-bottom: 8px;
  }
  .login-title{
    margin-bottom:1em;
    h2{
      font-size:25px;
      font-weight:bold;
      color:#0E0C1F;
      text-align:center;
    }
    h3{
      font-size:15px;
      color:#0E0C1F;
      text-align:center;      
    }
  }
  .title{
    display:flex;
    justify-content:center;
    margin-top: 0.3em;
  }
  & .app-title{
    h1{
      text-align:center;
      color:${props => themes[props.theme].primary};
      font-size:35px;
      font-weight:bold;
    }
  }
  label.error-label{
    color: #f5222d;
    font-size: 14px !important;
    opacity:1 !important;
  }
  .dark-content{
    display:none;
    padding: 4em 2em 2em 2em;
    h1{
      color: #FFFFFF;
      text-transform: capitalize;
      font-size: 1.7em;
    }
    h2{
      color: #FFFFFF;
      font-size: 1.5em;
    }
    h3{
      color: #FFFFFF;
      font-size: 16px;
    }

  }
  & .ant-form{
    display:flex;
    flex-direction:column;
    width:100%;
    padding:2em;
  }
  & .forgot-container{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
  }
  & .ant-form-item{
    margin-bottom:10px;
  }
  & .ant-checkbox-wrapper{
    color:${props => themes[props.theme].primary};
  }
  button.ant-btn.login-form-button.ant-btn-primary{
    margin-top: 2em;
  }

  & .remember-checkbox{
    opacity:1 !important;
    font-size:14px !important;
  }
  & .login-form-forgot{
    height:39px;
    line-height:38px;
    color:${props => themes[props.theme].primary};
  }
  & .key-login{
    margin-right:3px;
  }
  @media (min-width: 992px) {
    padding:3em;
    h2.title{
      display:block;
      font-weight: bold;
      font-size: 25px;
      position:absolute;
      top:12px;
      left:12px;
    }
    & .app-title{
      h1{
        font-size:45px;
      }
    }
    .ant-card{
      width:800px;
      border-radius:10px;
      height: 500px;
    }
    .ant-form{
      width:60%;
      padding:6em;
    }
    .dark-content{
      width:40%;
      display:flex;
      flex-direction:column;
      justify-content: space-between;
      background:#222033;
      color: #FFFFFF;
      border-radius: 0 10px 10px 0;
      i.anticon{
        margin-right: .5em;
      }
    }
    & .ant-input{
      min-width:290px;
    }
    button.ant-btn.login-form-button.ant-btn-primary{
      max-width:150px;
    }
    .login-title{
      margin-bottom:3em;
      h2{
        font-size:32px;
      }
      h3{
        font-size:22px;
      }
    }
    .title{
      justify-content:flex-start;
    }
    .footer-list{
      margin-top: 3em;
      display:flex;
      flex-direction: row;
      justify-content: space-around;
      max-width: 750px;
      list-style: none;
      li{
        margin-right: 1.5em;
        a{
          color: #B9B9C8;
        }
      } 

    }
  }
 }
`
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailError: null,
      requestError: null,
      email: '',
      password: '',
      time: moment().format('MMMM Do YYYY, h:mm:ss a'),
      greetingTime: getGreetingTime(moment())
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }
  componentDidMount = () => {
    let intervalID = setInterval(() => this.updateTime(), 1000)
    this.setState({ intervalID })
    if (localStorage.userEmail) {
      this.props.form.setFieldsValue({ login: localStorage.userEmail })
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalID)
  }
  updateTime = () => {
    this.setState({
      time: moment().format('MMMM Do YYYY, h:mm:ss a'),
      greetingTime: getGreetingTime(moment())
    })
  }
  handleInputChange(e) {}

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: null })
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        let requestObject = {}
        requestObject['login'] = values.login
        requestObject['password'] = values.password
        request('/user/login', 'POST', requestObject, false).then(response => {
          getMessages(response)
          this.setState({ loading: false })
          if (response.status === 200) {
            let { firstName, lastName } = response.data.profile
            let resp = response.data
            localStorage.accessTokenDev = resp.token
            localStorage.userEmail = values.login
            localStorage.name = `${firstName} ${lastName}`
            localStorage.permissions = JSON.stringify(resp.permissions)
            this.props.history.push('/home/file-system/my-drive/folder/root')
          }
        })
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <StyledLogin theme={selectedColor}>
        {/*  <StyledImg src={Logo} /> */}
        <div className="login-title">
          <div className="title">
            <h2>Private Cloud Data Centre</h2>
          </div>
          <h3>Manage your company Data</h3>
        </div>
        <Card>
          <Form onSubmit={this.handleSubmit}>
            <div className="organization-container">
              <span>PLEASE LOG IN</span>
              <br />
            </div>
            <div className="organization-name">
              <span>
                <b>Organization: </b>
              </span>{' '}
              M&M Marketing Management LLC
              <br />
            </div>
            <label>Email</label>
            <FormItem colon={false}>
              {getFieldDecorator('login', {
                rules: [
                  { required: true, message: 'Please input your username!' },
                  { type: 'email', message: 'The input is not valid E-mail!' }
                ]
              })(<Input placeholder="Username" />)}
            </FormItem>
            <br />
            <label>Password</label>
            <FormItem colon={false} hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please enter the password!' }
                ]
              })(<Input type="password" placeholder="Password" />)}
            </FormItem>
            {this.state.error}
            <div className="forgot-container">
              {/*               <FormItem colon={false}>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true
                })(
                  <Checkbox className="remember-checkbox">Remember me</Checkbox>
                )}
              </FormItem> */}
              <ForgotPasssword />
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={this.state.loading}>
              Login
            </Button>
          </Form>
          <div class="dark-content">
            <div>
              <h1>
                {this.state.greetingTime}
                {localStorage.name ? `, ${localStorage.name}` : null}
              </h1>
              <h3>{this.state.time}</h3>
            </div>
            <h2>
              <Icon type="environment" theme="outlined" />
              Dubai, UAE
            </h2>
          </div>
        </Card>
        <ul className="footer-list">
          <li>
            <a href="">TERMS OF USE</a>
          </li>
          <li>
            <a href="">COMPLIANCE</a>
          </li>
          <li>
            <a href="">CONFIDENTIAL INFORMATION </a>
          </li>
          <li>
            <a href="">SUPPORT</a>
          </li>
          <li>
            <a href="">CONTACTS</a>
          </li>
        </ul>
      </StyledLogin>
    )
  }
}

export default withRouter(Form.create()(Login))
