import React, { Component } from 'react'
import { Modal, Button, Tree, Icon } from 'antd'
import styled from 'styled-components'
import request from '../../utils/requestWrapper'
import { getMessages } from '../../utils/utils'

const DirectoryTree = Tree.DirectoryTree
const TreeNode = Tree.TreeNode
const Wrapper = styled.div`
  .ant-btn {
    padding: 0 15px;
    border: none;
    background: transparent;
    width: 100%;
    height: 32px;
    color: #5f5f5f;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &:hover {
      background: transparent;
      color: #5f5f5f;
    }
  }
`
const ContentWrapper = styled.div`
  .root-node {
    i.anticon.anticon-database,
    span.ant-tree-title {
      font-size: 16px;
      font-weight: bold;
    }
  }
`
export default class Move extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false,
      rootFolders: null,
      selectedFolder: null,
      loadingFolders: false,
      folders: {}
    }
  }
  componentDidMount = () => {}
  showModal = () => {
    this.getFolders()
    this.setState({
      visible: true
    })
  }

  handleOk = e => {
    this.setState({ loading: true })
    let endPoint = `/${this.props.type}/${this.props.resourceType}/${
      this.props.id
    }${this.props.resourceType === 'file' ? '/move' : ''}`
    let requestObject = {
      parent:
        this.state.selectedFolder !== 'root' ? this.state.selectedFolder : null
    }
    request(endPoint, 'PATCH', requestObject).then(response => {
      getMessages(response)
      this.setState({
        loading: false
      })
      if (response.status === 200 || response.status === 201) {
        this.props.delete(this.props.resourceType, this.props.id)
        this.setState({
          visible: false
        })
      }
    })
  }
  onSelect = selectedKeys => {
    this.setState({ selectedFolder: selectedKeys[0] })
    if (!this.state.folders[selectedKeys[0]] && selectedKeys[0] !== 'root') {
      this.getFolders(selectedKeys[0])
    }
  }
  getFolders(id = null) {
    this.setState({ loadingFolders: true })
    let endPoint = id
      ? `/${this.props.type}/folder?filters=parent:${id}`
      : `/${this.props.type}/folder`
    request(endPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        if (!id) {
          this.setState({ rootFolders: response.data, loadingFolders: false })
        } else {
          let { folders } = this.state
          folders[id] = response.data
          this.setState({ folders, loadingFolders: false })
        }
      }
    })
  }
  handleCancel = e => {
    this.setState({
      visible: false
    })
  }
  getChilds = folders => {
    return folders.map(folder => (
      <TreeNode title={folder.name} key={folder._id}>
        {this.state.folders[folder._id] &&
          this.state.folders[folder._id].length > 0 &&
          this.getChilds(this.state.folders[folder._id])}
      </TreeNode>
    ))
  }
  render() {
    return (
      <Wrapper>
        <Button type="primary" onClick={this.showModal}>
          <Icon type="folder" />
          Move
        </Button>
        <Modal
          title={
            <div>
              <Icon type="folder" />
              &nbsp;Move to folder
            </div>
          }
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={this.state.loading}
              disabled={
                !this.state.selectedFolder ||
                this.state.selectedFolder === this.props.currentFolderId ||
                (this.state.selectedFolder &&
                  this.state.selectedFolder === this.props.selectedId &&
                  this.props.resourceType === 'folder') ||
                (this.state.selectedFolder === 'root' &&
                  !this.props.currentFolderId)
              }
              onClick={this.handleOk}>
              Move
            </Button>
          ]}>
          <ContentWrapper>
            {this.state.loadingFolders && (
              <div>
                <Icon type="loading" />
                &nbsp;&nbsp;Loading
              </div>
            )}
            {this.state.rootFolders && (
              <DirectoryTree
                multiple
                onSelect={this.onSelect}
                onExpand={this.onExpand}>
                <TreeNode
                  className="root-node"
                  title="Root"
                  key="root"
                  icon={<Icon type="database" />}
                />
                {this.getChilds(this.state.rootFolders)}
              </DirectoryTree>
            )}
          </ContentWrapper>
        </Modal>
      </Wrapper>
    )
  }
}
/* 
        disabled={
          this.props.currentFolderId &&
          this.props.currentFolderId === folder._id
        }
*/
