import React from 'react'
import { Modal, Form, Icon, Input, Button, Row, Col } from 'antd'
import request from '../../utils/requestWrapper'
import { getMessages } from '../../utils/utils'
import styled from 'styled-components'
import MaterialIcon from '../../utils/icon/Icon'

const FormItem = Form.Item
const Wrapper = styled.div`
  button.ant-btn.ant-btn-primary {
    background: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    width: 100%;
  }
  .button-text {
    margin-left: 0.2em;
  }
  .material-icon.share-icon {
    font-size: 18px;
  }
`
const FormWrapper = styled.div`
  .ant-form-item-label {
    display: none;
  }
  .save-button {
    width: 100px;
  }
`
class Resource extends React.Component {
  state = {
    loading: false,
    visible: false
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.name !== nextProps.name) {
      this.props.form.setFieldsValue({ name: nextProps.name })
    }
  }
  componentDidMount() {
    if (this.props.name) {
      this.props.form.setFieldsValue({ name: this.props.name })
    }
  }
  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleOk = () => {
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false, visible: false })
    }, 3000)
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let requestObject = {
          name: values.name
        }
        this.setState({ loading: true })
        if (
          this.props.parent &&
          this.props.parent !== 'root' &&
          !this.props.editId
        ) {
          requestObject['parent'] = this.props.parent
        }
        let method = this.props.editId ? 'PATCH' : 'POST'
        let endPoint = this.props.editId
          ? `/${this.props.type}/${this.props.resource}/${this.props.editId}`
          : `/${this.props.type}/${this.props.resource}`
        request(endPoint, method, requestObject).then(response => {
          this.setState({ loading: false })
          getMessages(response)
          if (response.status === 401) {
            this.setState({ data: response.data })
          } else if (response.status === 200 || response.status === 201) {
            this.props.onSuccess(response.data)
            this.props.form.resetFields()
            this.handleCancel()
          }
        })
      }
    })
  }
  render() {
    const { visible, loading } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Wrapper>
        <Button type="primary" onClick={this.showModal}>
          {this.props.editId ? (
            <React.Fragment>
              <Icon type="edit" />
              <span className="button-text">Rename</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <MaterialIcon type="folder_shared" className="share-icon" />
              <span className="button-text">Folder</span>
            </React.Fragment>
          )}
        </Button>
        <Modal
          visible={visible}
          title={
            this.props.editId
              ? `Edit ${this.props.resource}`
              : `Add new ${this.props.resource}`
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}>
          {' '}
          <FormWrapper>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <FormItem colon={false} label="Name">
                    {getFieldDecorator('name', {
                      rules: [
                        { required: true, message: 'Please enter the Name' }
                      ]
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Name"
                        disabled={this.state.disabled}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24}>
                  <Button
                    className="save-button"
                    type="primary"
                    htmlType="submit"
                    loading={loading}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        </Modal>
      </Wrapper>
    )
  }
}
export default Form.create()(Resource)
