import React, { Fragment } from 'react'
import { Marker, InfoWindow } from 'react-google-maps'
import MainMap from '../common/GoogleMaps'

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: '',
      isOpen: []
    }
    this.onMapMounted = this.onMapMounted.bind(this)
  }

  onToggleOpen(e, i, cb) {
    const isOpen = []
    isOpen[i] = !this.state.isOpen[i]
    this.setState(
      {
        isOpen
      },
      () => {
        if (cb) {
          cb()
        }
      }
    )
  }

  onMapMounted(mapObj) {
    if (!this.state.map) {
      const map = this.state.map ? this.state.map : mapObj
      const bounds = new window.google.maps.LatLngBounds()
      this.props.markers.forEach(marker => {
        if (marker.latitude && marker.longitude) {
          bounds.extend(
            new window.google.maps.LatLng(marker.latitude, marker.longitude)
          )
        }
      })
      map.fitBounds(bounds)
      this.setState({
        map
      })
    }
  }

  render() {
    return (
      <MainMap
        // eslint-disable-next-line max-len
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCu9yG0HHcEhgdHRNaXwkGxd27Hh701h-M&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: '100%', width: '100%' }} />}
        containerElement={<div style={{ width: '100%', height: '100%' }} />}
        mapElement={<div style={{ width: '100%', height: '400px' }} />}
        onMapMounted={this.onMapMounted}
        defaultZoom={4}
        defaultCenter={{ lat: 30.9873867, lng: -97.472588 }}
        {...this.props}>
        <Fragment>
          {this.props.markers.map((marker, i) => (
            <Fragment>
              <Marker
                key={marker.id}
                position={{ lat: marker.latitude, lng: marker.longitude }}
                onClick={e => this.onToggleOpen(e, i)}>
                {this.state.isOpen[i] && (
                  <InfoWindow
                    onCloseClick={e => this.onToggleOpen(e, i)}
                    options={{
                      alignTop: true,
                      closeBoxURL: '',
                      enableEventPropagation: true
                    }}>
                    <div
                      style={{
                        backgroundColor: 'white',
                        opacity: 0.75,
                        width: '150px'
                      }}>
                      <Fragment>
                        <div
                          style={{
                            fontSize: '16px',
                            fontColor: '#08233B',
                            padding: '10px 5px'
                          }}>
                          {marker.name}
                        </div>
                      </Fragment>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </Fragment>
          ))}
        </Fragment>
      </MainMap>
    )
  }
}

export default Map
