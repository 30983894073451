import React, { Component } from 'react'
import { Card, Form, Select, Input, Button, Row, Col, Tag } from 'antd'
import request from '../../utils/requestWrapper'
import style from 'styled-components'
import { getMessages } from '../../utils/utils'
import { withRouter } from 'react-router-dom'
import Spin from '../../utils/Spinner'

const FormItem = Form.Item

let StyledBranchForm = style.div`
  button{
    margin-top:20px;
  }
`

class BranchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectData: [],
      disabled: false,
      action: 'Add',
      loading: false
    }

    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    this.getCountry()
    this.getAllEmployees()
    if (this.props.edit || this.props.view) {
      this.getBranchDetaills()
      this.setState({
        disabled: this.props.view ? true : false,
        action: this.props.view ? 'View' : 'Edit'
      })
    }
  }

  getBranchDetaills() {
    this.setState({ loading: true, showLogin: false })
    let endPoint = `/branch/${this.props.match.params.id}`
    request(endPoint).then(response => {
      this.setState({ loading: false })
      if (response.status === 401) {
      } else if (response.status === 200) {
        /* TODO improve this passing directly the object */
        let data = {
          name: response.data.name,
          branchHead: response.data.branchHead._id,
          description: response.data.description,
          address: {
            addressLine: response.data.address.addressLine,
            city: response.data.address.city,
            state: response.data.address.state,
            zip: response.data.address.zip,
            contact: response.data.address.contact,
            country: response.data.address.country
          }
        }
        this.props.form.setFieldsValue(data)
      }
    })
  }

  reloadData() {
    this.getCountry()
    this.getAllEmployees()
  }

  showModal() {
    this.setState({
      visible: true
    })
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleCancel() {
    this.props.form.resetFields()
  }

  getCountry() {
    let locationEndPoint = '/location/country'
    request(locationEndPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        let { selectData } = this.state
        selectData['country'] = response.data
        this.setState({ selectData })
      }
    })
  }

  getAllEmployees() {
    let endPoint = '/helper/employee'
    request(endPoint).then(response => {
      if (response.status === 401) {
      } else if (response.status === 200) {
        this.setState({ employees: response.data })
      }
    })
  }
  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let requestObject = { address: {} }

        requestObject['name'] = values.name
        if (values.description) {
          requestObject['description'] = values.description
        }
        requestObject['branchHead'] = values.branchHead
        if (values.address.addressLine) {
          requestObject['address']['addressLine'] = values.address.addressLine
        }
        if (values.address.city) {
          requestObject['address']['city'] = values.address.city
        }
        if (values.address.state) {
          requestObject['address']['state'] = values.address.state
        }
        if (values.address.zip) {
          requestObject['address']['zip'] = values.address.zip
        }
        if (values.address.contact) {
          requestObject['address']['contact'] = values.address.contact
        }
        if (values.address.country) {
          requestObject['address']['country'] = values.address.country
        }

        let method = this.props.edit ? 'PATCH' : 'POST'
        let endPoint = this.props.edit
          ? `/branch/${this.props.match.params.id}`
          : '/branch'
        request(endPoint, method, requestObject).then(response => {
          getMessages(response)
          if (response.status === 401) {
            this.setState({ data: response.data })
          } else if (response.status === 200 || response.status === 201) {
            this.props.history.push('/home/branch/list')
          }
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <Spin loading={this.state.loading} />
        <Card title={this.state.action + ' branch'}>
          <StyledBranchForm>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <FormItem className={this.haveValue('name')} label="Name">
                    {getFieldDecorator('name', {
                      rules: [
                        { required: true, message: 'Please enter the Name' }
                      ]
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Name"
                        disabled={this.state.disabled}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8}>
                  <FormItem
                    className={this.haveValue('branchHead')}
                    label="Branch Head">
                    {getFieldDecorator('branchHead', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the branch Head'
                        }
                      ]
                    })(
                      <Select
                        optionFilterProp="Branch Head"
                        showSearch
                        disabled={this.props.view}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .replace(/,/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Branch Head">
                        {this.state.employees &&
                          this.state.employees.map((employee, i) => (
                            <Select.Option
                              key={i}
                              value={
                                employee._id
                              }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                          ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8}>
                  <FormItem
                    className={this.haveValue('description')}
                    label="Description">
                    {getFieldDecorator(
                      'description',
                      {}
                    )(
                      <Input
                        placeholder="Description"
                        disabled={this.state.disabled}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Tag>Address Info</Tag>
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <FormItem
                    className={this.haveValue('address.addressLine')}
                    label="Address line">
                    {getFieldDecorator(
                      'address.addressLine',
                      {}
                    )(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Address line"
                        disabled={this.state.disabled}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8}>
                  <FormItem
                    className={this.haveValue('address.city')}
                    label="City">
                    {getFieldDecorator(
                      'address.city',
                      {}
                    )(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="City"
                        disabled={this.state.disabled}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8}>
                  <FormItem
                    className={this.haveValue('address.state')}
                    label="State">
                    {getFieldDecorator(
                      'address.state',
                      {}
                    )(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="State"
                        disabled={this.state.disabled}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8}>
                  <FormItem
                    className={this.haveValue('address.zip')}
                    label="Zip Code">
                    {getFieldDecorator(
                      'address.zip',
                      {}
                    )(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Zip Code"
                        disabled={this.state.disabled}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8}>
                  <FormItem
                    className={this.haveValue('address.contact')}
                    label="Contact">
                    {getFieldDecorator(
                      'address.contact',
                      {}
                    )(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Contact"
                        disabled={this.state.disabled}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8}>
                  <FormItem
                    className={this.haveValue('address.country')}
                    label="Country">
                    {getFieldDecorator(
                      'address.country',
                      {}
                    )(
                      <Select
                        optionFilterProp="children"
                        showSearch
                        disabled={this.props.view}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .replace(/,/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Country">
                        {this.state.selectData.country
                          ? Object.keys(this.state.selectData.country).map(
                              key => {
                                return (
                                  <Select.Option key={key} value={key}>
                                    {this.state.selectData.country[key]}
                                  </Select.Option>
                                )
                              }
                            )
                          : null}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              {!this.props.view && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={this.state.disabled}>
                  Submit
                </Button>
              )}
            </Form>
          </StyledBranchForm>
        </Card>
      </div>
    )
  }
}

export default withRouter(Form.create()(BranchForm))
