import React, { Component } from 'react'
import style from 'styled-components'
import {
  Form,
  Icon,
  Input,
  Select,
  Row,
  Col,
  Button,
  Upload,
  message,
  notification,
  Spin,
  Modal
} from 'antd'
import request from '../../utils/requestWrapper'

const FormItem = Form.Item

const StyledPersonalInfo = style.div`
  .upload-col{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
  }

   .title-save-image-file {
    margin-top: 20px;
  }

  .images-files {
    display: flex;
    flex-direction: row;
    margin-right: 1em;
    flex-wrap: wrap;
    align-items: center;
  }

  .images-files .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    margin-bottom: 0 !important;
  }

  .delete-icon {
    cursor: pointer;
  }

  .images-files > div {
    margin-right: 1em;
  }

  .button-upload {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
  }

  .ant-upload,
  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload.ant-upload-select.ant-upload-select-picture-card{
    margin:0;
  }

  .image-upload {
    width:102px;
    height:102px;
    margin-bottom: 1em;
    cursor: pointer;
  }
  .ant-modal-root .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.45);
  }

  .ant-modal img {
    width:200px;
    height:200px;
  }

  @media (min-width: 992px) {
    .ant-col-lg-8 {
      padding-right:10px;
    }

    .ant-upload,
    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload.ant-upload-select.ant-upload-select-picture-card{
      margin-bottom: 22px;
    }
  }
`

let StyledModalImage = style.div`
   img{
    width: 100%;
  }
`
class LnddoPersonalInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      loading: false,
      percent: null,
      previewVisible: false,
      previewImage: '',
      filled: false,
      newAdress: false,
      data: props.data,
      file: '',
      fileUrl: '',
      filesCount: 0,
      imagesArray: null,
      imageDocArray: null,
      imageArrayLoading: true,
      imageDocArrayLoading: true,
      visibleImagesArray: false,
      visibleImageDocArray: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.haveValue = this.haveValue.bind(this)
    this.beforeUpload = this.beforeUpload.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleCancelModal = this.handleCancelModal.bind(this)
  }

  componentDidMount() {
    const { props } = this
    if (props.data) {
      props.form.setFieldsValue(props.data)
    }
    this.setImages()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data !== state.data) {
      props.form.setFieldsValue(props.data)
      return {
        data: props.data
      }
    } else {
      return null
    }
  }

  componentDidUpdate() {
    this.setImages()
  }

  setImages() {
    if (!this.state.imagesArray && this.props.sendImages) {
      this.setState({
        imagesArray: this.props.sendImages,
        imageArrayLoading: false
      })
    }
    if (!this.state.imageDocArray && this.props.sendImageDoc) {
      this.setState({
        imageDocArray: this.props.sendImageDoc,
        imageDocArrayLoading: false
      })
    }
  }

  delete(id, array) {
    let endPoint = `/my-drive/file/${id}`
    this.setState({ [`${array}Deleting`]: true })
    request(endPoint, 'DELETE').then(response => {
      if (response.status === 401) {
      } else if (response.status === 200) {
        message.success('Image deleted')
        const reducedImagesArray = this.state[array].filter(e => {
          if (e.id !== id) {
            return e
          }
          return null
        })
        this.setState({
          [array]: reducedImagesArray,
          [`${array}Deleting`]: false
        })
      }
    })
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    })
  }

  haveValue(value) {
    if (value === 'employerName') {
    }
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleCancel() {
    this.setState({ previewVisible: false })
  }

  beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJPG) {
      message.error('You can only upload JPG or PNG file!')
    }
    const isLt2M = file.size < 400000
    if (!isLt2M) {
      message.error('Image must smaller than 400kb!')
    }
    return isJPG && isLt2M
  }

  handleChange(element, value) {
    if (value === 'new') {
      this.setState({
        newAdress: true
      })
    } else {
      this.setState({
        newAdress: false
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const imagesIds = this.state.imagesArray.map(id => id.id)
        const imageDocIds = this.state.imageDocArray.map(id => id.id)
        let newValues = {
          ...values,
          images: imagesIds,
          imageDoc: imageDocIds
        }
        this.props.onSubmit(newValues)
      }
    })
  }

  async uploadFile(file, stateKey) {
    let data = new FormData()
    let endPoint = `/my-drive/file`
    data.append('file', file)
    this.setState({ requesting: true })
    notification.info({
      message: <span style={{ fontSize: '12px' }}>{file.name}</span>,
      icon: <Icon type="loading" />,
      duration: 0,
      key: `loading-${this.state.filesCount}`
    })
    const response = await request(endPoint, 'POST', data, true, null, true)

    if (response.status === 201 || response.status === 200) {
      notification.close(`loading-${this.state.filesCount}`)
      notification.success({
        message: <span style={{ fontSize: '12px' }}>{file.name}</span>,
        duration: 5
      })
      let endPoint2 = `/my-drive/file/${response.data._id}`
      const response2 = await request(endPoint2)
      this.setState(prevState => ({
        requesting: false,
        file: response.data.url,
        filesCount: prevState.filesCount + 1,
        files: response.data,
        [stateKey]: prevState[stateKey].concat([
          { id: response2.data._id, url: response2.data.url }
        ])
      }))
    }
  }

  uploadButton = stateKey => {
    return (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">
          {this.state[`${stateKey}Deleting`] ? `deleting` : 'Choose'}
        </div>
      </div>
    )
  }

  showModal(images, url) {
    this.setState({
      [`visible${images}`]: true,
      [`${images}Url`]: url
    })
  }

  handleCancelModal(images) {
    this.setState({ [`visible${images}`]: false })
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const {
      imagesArray,
      imageDocArray,
      imageArrayLoading,
      imageDocArrayLoading,
      requesting
    } = this.state

    return (
      <StyledPersonalInfo>
        <Form onSubmit={this.handleSubmit}>
          <Col xs={24}>
            <Row>
              <Col xs={24} lg={8}>
                <FormItem
                  colon={false}
                  className={this.haveValue('customerName')}
                  label="Customer Name*">
                  {getFieldDecorator('customerName', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the Customer Name'
                      }
                    ]
                  })(
                    <Input
                      disabled={this.props.view}
                      placeholder="Customer Name*"
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} lg={8}>
                <FormItem
                  colon={false}
                  className={this.haveValue('cifNumber')}
                  label="CIF Number*">
                  {getFieldDecorator('cifNumber', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your CIF Number'
                      }
                    ]
                  })(
                    <Input
                      disabled={this.props.view}
                      placeholder="CIF Number*"
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} lg={8}>
                <FormItem
                  colon={false}
                  className={this.haveValue('nameOfBusiness')}
                  label="Name of Business*">
                  {getFieldDecorator('nameOfBusiness', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Name of Business'
                      }
                    ]
                  })(
                    <Input
                      disabled={this.props.view}
                      placeholder="Name of Business*"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={8}>
                <FormItem
                  className={this.haveValue('address.line1')}
                  colon={false}
                  label="Address Line 1*">
                  {getFieldDecorator('address.line1', {
                    rules: [
                      {
                        required: true,

                        message: 'Please input your address1'
                      }
                    ]
                  })(
                    <Input
                      disabled={this.props.view}
                      placeholder="Address Line 1*"
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} lg={8}>
                <FormItem
                  colon={false}
                  className={this.haveValue('address.line2')}
                  label="Address Line 2">
                  {getFieldDecorator(
                    'address.line2',
                    {}
                  )(
                    <Input
                      disabled={this.props.view}
                      placeholder="Address Line 2"
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} lg={8}>
                <FormItem
                  colon={false}
                  className={this.haveValue('address.line3')}
                  label="Address Line 3">
                  {getFieldDecorator(
                    'address.line3',
                    {}
                  )(
                    <Input
                      disabled={this.props.view}
                      placeholder="Address Line 3"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={8}>
                <FormItem
                  colon={false}
                  className={this.haveValue('address.emirate')}
                  label={'Emirate*'}>
                  {getFieldDecorator('address.emirate', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your address emirate'
                      }
                    ]
                  })(
                    <Select
                      optionFilterProp="children"
                      showSearch
                      disabled={this.props.view}
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .replace(/,/g, '')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select Emirate*">
                      <Select.Option key={'Abu Dhabi'} value={'Abu Dhabi'}>
                        Abu Dhabi
                      </Select.Option>
                      <Select.Option key={'Ajman'} value={'Ajman'}>
                        Ajman
                      </Select.Option>
                      <Select.Option key={'Dubai'} value={'Dubai'}>
                        Dubai
                      </Select.Option>
                      <Select.Option key={'Fujairah'} value={'Fujairah'}>
                        Fujairah
                      </Select.Option>
                      <Select.Option
                        key={'Ras AI Khaimah'}
                        value={'Ras AI Khaimah'}>
                        Ras AI Khaimah
                      </Select.Option>
                      <Select.Option key={'Sharjah'} value={'Sharjah'}>
                        Sharjah
                      </Select.Option>
                      <Select.Option
                        key={'Umm AI Quwain'}
                        value={'Umm AI Quwain'}>
                        Umm AI Quwain
                      </Select.Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row className="title-save-image-file">
              <h3>Save Images</h3>
            </Row>
            <Row className="images-files">
              <FormItem colon={false} className="">
                <div>
                  <Upload
                    action={file => this.uploadFile(file, 'imagesArray')}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={this.beforeUpload}
                    disabled={this.props.view}>
                    {this.uploadButton('imagesArray')}
                  </Upload>
                </div>
              </FormItem>
              {imageArrayLoading ? (
                <Spin />
              ) : (
                imagesArray &&
                imagesArray.map(e => {
                  return (
                    <div>
                      <img
                        className="image-upload"
                        src={e.url}
                        onClick={() => this.showModal('ImagesArray', e.url)}
                        alt=""
                      />
                      <Icon
                        type="delete"
                        className="delete-icon"
                        onClick={() => this.delete(e.id, 'imagesArray')}
                      />
                      <Modal
                        visible={this.state.visibleImagesArray}
                        width={350}
                        title="Images"
                        onOk={this.handleOk}
                        onCancel={() => this.handleCancelModal('ImagesArray')}
                        footer={null}>
                        <StyledModalImage>
                          <img src={this.state.ImagesArrayUrl} alt="" />
                        </StyledModalImage>
                      </Modal>
                    </div>
                  )
                })
              )}
            </Row>
            <Row className="title-save-image-file">
              <h3>Save Images Docs</h3>
            </Row>
            <Row className="images-files">
              <FormItem colon={false} className="">
                <div>
                  <Upload
                    action={file => this.uploadFile(file, 'imageDocArray')}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={this.beforeUpload}
                    disabled={this.props.view}>
                    {this.uploadButton('imageDocArray')}
                  </Upload>
                </div>
              </FormItem>
              {imageDocArrayLoading ? (
                <Spin />
              ) : (
                imageDocArray &&
                imageDocArray.map(e => {
                  return (
                    <div>
                      <img
                        className="image-upload"
                        src={e.url}
                        onClick={() => this.showModal('ImageDocArray', e.url)}
                        alt=""
                      />
                      <Icon
                        type="delete"
                        className="delete-icon"
                        onClick={() => this.delete(e.id, 'imageDocArray')}
                      />
                      <Modal
                        visible={this.state.visibleImageDocArray}
                        title="ImagesDoc"
                        onOk={this.handleOk}
                        onCancel={() => this.handleCancelModal('ImageDocArray')}
                        footer={null}>
                        <StyledModalImage>
                          <img src={this.state.ImageDocArrayUrl} alt="" />
                        </StyledModalImage>
                      </Modal>
                    </div>
                  )
                })
              )}
            </Row>
            <Row className="button-row">
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  requesting || imageArrayLoading || imageDocArrayLoading
                }>
                Next
                <Icon type="right" />
              </Button>
            </Row>
          </Col>
        </Form>
      </StyledPersonalInfo>
    )
  }
}
export default Form.create()(LnddoPersonalInfo)
