import React, { Component } from 'react'
import style from 'styled-components'
import WrapPersonalIInfo from './PersonalInfoForm'
import WrapJobInfo from './JobInfoForm'
import WrapProfessionInfo from './ProfessionInfoForm'
import WrapAddressInfo from './AddressInfoForm'
import { Card, Steps, Breadcrumb } from 'antd'
import { withRouter } from 'react-router-dom'
import request from '../../utils/requestWrapper'
import { getMessages } from '../../utils/utils'
import Spin from '../../utils/Spinner'
import countries from '../../utils/countries.json'
import moment from 'moment'

import { themes } from '../../utils/style-utils'

const Step = Steps.Step

const steps = [
  {
    title: 'Personal Info'
  },
  {
    title: 'Job Info'
  },
  {
    title: 'Profession Info'
  },
  {
    title: 'Address Info'
  }
]

const StyledEmployeeForm = style.div`
  & .ant-card{
    margin:1em 0;
  }
  h5{
    font-weight:bold;
  }
  & .form-header{
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
  }
  span.ant-breadcrumb-link{
    cursor:pointer;
    &:hover{
      color: ${props => themes[props.theme].primary};
    }
  }
  & .previous-button{
    margin-right:10px;
  }
  @media screen and (min-width: 768px){
    & .ant-col-xs-24.ant-col-sm-8, & .ant-col-xs-24.ant-col-md-6 {
      padding-right:30px;
    }
  }
`
class EmployeeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      formData: Array(4).fill(null),
      selectData: {},
      loading: false
    }

    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.changeStep = this.changeStep.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setCountries = this.setCountries.bind(this)
    this.getImages = this.getImages.bind(this)
  }

  componentDidMount() {
    this.setCountries(countries)
    if (this.props.edit) {
      this.getEditData()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({
        formData: Array(4).fill(null),
        current: 0
      })
    }
  }

  async getImages(array, key) {
    let i = 0
    const newArray = []
    if (!array || array.length == 0) {
      this.setState({ [key]: newArray })
      return;
    }
    for (i = 0; i < array.length; i++) {
      let endPoint = `/my-drive/file/${array[i]}`
      const response = await request(endPoint)
      if (response.status === 200) {
        newArray.push({ id: response.data._id, url: response.data.url })
      }
      if (response.status === 400) {
      }
    }
    this.setState({ [key]: newArray })
  }

  async getEditData() {
    this.setState({ loading: true, showLogin: false })
    let endPoint = `/fv-lead/${this.props.match.params.id}`
    const response = await request(endPoint)
    this.setState({ loading: false })
    if (response.status === 401) {
      this.setState({ showLogin: true, action: this.reloadData })
    } else if (response.status === 200 || response.status === 201) {
      let values = response.data
      this.getImages(values.images, 'images')
      this.getImages(values.imageDoc, 'imageDoc')
      let formData = []
      formData[0] = {
        displayPic: values.displayPic
          ? {
              original: values.displayPic.original
            }
          : undefined,
        images: values.images,
        imageDoc: values.imageDoc,
        name: values.name,
        employer: values.employer,
        cifNumber: values.cifNumber,
        productType: values.productType,
        addressType: values.addressType,
        segmentType: values.segmentType,
        address: values.address
          ? {
              line1: values.address.line1,
              line2: values.address.line2,
              line3: values.address.line3,
              emirate: values.address.emirate
            }
          : {}
      }

      formData[1] = {
        hasBranch: values.hasBranch,
        sector: values.sector
          ? {
              name: values.sector.name,
              other: values.sector.other,
            }
          : undefined,
        lineOfActivity: values.lineOfActivity,
        industry: values.industry,
        visitType: values.visitType,
        officeType: values.officeType,
        designation: values.designation,
        officeLandline: values.officeLandline,
        officeLandlineVerified: values.officeLandlineVerified
          ? {
              verified: values.officeLandlineVerified.verified,
              reason: values.officeLandlineVerified.reason
            }
          : undefined,
        verified: values && values.officeLandlineVerified ? values.officeLandlineVerified.verified : false,
        numOfBranch: values.numOfBranch,
        personMet: values.personMet
          ? {
              type: values.personMet.type,
              name: values.personMet.name,
              designation: values.personMet.designation,
              personDesignationOthers: values.personMet.personDesignationOthers
            }
          : undefined
      }
      formData[2] = {
        employmentConfirmed: values.employmentConfirmed,
        workingSince: moment(values.workingSince),
        designation: values.designation,
        officerEmail: values.officerEmail,
        loan: values.loan
          ? {
              purpose: values.loan.purpose,
              amount: values.loan.amount,
              otherPurpose: values.loan.otherPurpose
            }
          : undefined,
        salaryTransMode: values.salaryTransMode,
        monthlyIncome: values.monthlyIncome
          ? {
              isMonthlyIncomeVerified:
                values.monthlyIncome.isMonthlyIncomeVerified,
              amount: values.monthlyIncome.amount
            }
          : undefined
      }
      formData[3] = {
        facilities: values.facilities
          ? {
              housing: values.facilities.housing,
              transportation: values.facilities.transportation,
              uniform: values.facilities.uniform,
              variablePay: values.facilities.variablePay
            }
          : undefined,
        cntEmp: values.cntEmp,
        airTicket: values.airTicket,
        airTicketType: values.airTicketType,
        totalNumOfEmpl: values.totalNumOfEmpl,
        dateOfInception: moment(values.dateOfInception),
        website: values.website
          ? {
              hasWebsite: values.website.hasWebsite,
              url: values.website.url ? values.website.url : undefined
            }
          : undefined,
        homeCountryRef: values.homeCountryRef
          ? {
              name: values.homeCountryRef.name,
              phoneNumber: values.homeCountryRef.phoneNumber,
              relationship: values.homeCountryRef.relationship
            }
          : undefined,
        residenceRef: values.residenceRef
          ? {
              relationship: values.residenceRef.relationship,
              name: values.residenceRef.name,
              phoneNumber: values.residenceRef.phoneNumber
            }
          : undefined,

        signBoard: values.signBoard
          ? {
              hasSignBoard: values.signBoard.hasSignBoard,
              nameOnBoard: values.signBoard.nameOnBoard
            }
          : undefined,

        neighbourhood: values.neighbourhood
          ? {
              feedback: values.neighbourhood.feedback,
              reason: values.neighbourhood.reason,
              reasonothers: values.neighbourhood.reasonothers
            }
          : undefined,
        homeCountryAddress: values.homeCountryAddress
          ? {
              line1: values.homeCountryAddress.line1
                ? values.homeCountryAddress.line1
                : undefined,
              line2: values.homeCountryAddress.line2
                ? values.homeCountryAddress.line2
                : undefined,
              line3: values.homeCountryAddress.line3
                ? values.homeCountryAddress.line3
                : undefined,
              landmark: values.homeCountryAddress.landmark
                ? values.homeCountryAddress.landmark
                : undefined,
              contactNumber: values.homeCountryAddress.contactNumber,
              country: values.homeCountryAddress.country
            }
          : undefined,

        familyInUAE: values.familyInUAE,
        makaniNo: values.makaniNo,
        officeAppearance: values.officeAppearance,
        liability: values.liability,
        salaryTransfDate: values.salaryTransfDate,
        assessmentByAgent: values.assessmentByAgent
          ? {
              feedback: values.assessmentByAgent.feedback,
              reason: values.assessmentByAgent.reason,
              reasonothers: values.assessmentByAgent.reasonothers
            }
          : undefined,
        feedback: values.feedback
          ? {
              findLoc: values.feedback.findLoc,
              aboutApplicant: values.feedback.aboutApplicant,
              aboutCompany: values.feedback.aboutCompany,
              additionalPoints: values.feedback.additionalPoints,
              overallComment: values.feedback.overallComment,
              officeLoc: values.feedback.officeLoc
            }
          : undefined,
        tradeLicense: values.tradeLicense
          ? {
              seen: values.tradeLicense.seen,
              reason: values.tradeLicense.reason
            }
          : undefined,
        latitude: values.latitude,
        longitude: values.longitude
      }
      this.setState({ formData })
    }
  }

  setCountries(countries) {
    let { selectData } = this.state
    selectData.country = countries
    this.setState({ selectData })
  }

  next(data) {
    let formData = this.state.formData
    formData[this.state.current] = data
    this.setState({
      formData,
      current: this.state.current + 1
    })
  }

  changeStep(current) {
    this.setState({ current })
  }

  prev() {
    const current = this.state.current - 1
    this.setState({ current })
  }

  handleSubmit(values) {
    let requestObject = {
      ...this.state.formData[0],
      ...this.state.formData[1],
      ...this.state.formData[2],
      ...this.state.formData[3],
      ...values
    }

    // requestObject = processEmployeeForm(requestObject)
    //TODO add request to API endpoint
    let method = this.props.edit ? 'PATCH' : 'POST'
    let endPoint = this.props.edit
      ? `/fv-lead/${this.props.match.params.id}`
      : '/fv-lead'
    request(endPoint, method, requestObject).then(response => {
      getMessages(response)
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        this.props.history.push('/home/fv-lead')
      }
    })
  }

  render() {
    const { current } = this.state
    return (
      <StyledEmployeeForm theme={this.props.theme}>
        <Spin loading={this.state.loading} />
        <Card>
          <Steps
            direction={this.state.width < 768 ? 'vertical' : 'horizontal'}
            current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Card>
        <div className="steps-content" style={{ marginTop: '18px' }}>
          <Card
            title={
              (this.props.view ? 'View' : this.props.edit ? 'Edit' : 'Add') +
              ' Employee'
            }>
            <div className="form-header">
              <Breadcrumb>
                {this.state.current > 0 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(0)}>
                    Personal Info
                  </Breadcrumb.Item>
                )}
                {this.state.current > 1 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(1)}>
                    Job Info
                  </Breadcrumb.Item>
                )}
                {this.state.current > 2 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(2)}>
                    Profession Info
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            </div>
            {this.state.current === 0 && (
              <WrapPersonalIInfo
                id={this.props.match.params.id}
                data={this.state.formData[0]}
                onSubmit={this.next}
                view={this.props.view}
                sendImages={this.state.images}
                sendImageDoc={this.state.imageDoc}
              />
            )}
            {this.state.current === 1 && (
              <WrapJobInfo
                theme={this.props.theme}
                data={this.state.formData[1]}
                onSubmit={this.next}
                onPrev={this.prev}
                view={this.props.view}
              />
            )}
            {this.state.current === 2 && (
              <WrapProfessionInfo
                theme={this.props.theme}
                data={this.state.formData[2]}
                onSubmit={this.next}
                onPrev={this.prev}
                view={this.props.view}
              />
            )}
            {this.state.current === 3 && (
              <WrapAddressInfo
                selectData={this.state.selectData}
                data={this.state.formData[3]}
                onSubmit={this.handleSubmit}
                onPrev={this.prev}
                view={this.props.view}
              />
            )}
          </Card>
        </div>
      </StyledEmployeeForm>
    )
  }
}
export default withRouter(EmployeeForm)
