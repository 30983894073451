import React, { Component } from 'react'
import { Modal, Form, Input, Button } from 'antd'
import request from '../utils/requestWrapper'
import style from 'styled-components'
import theme, { themes, selectedColor } from '../utils/style-utils'
import { getMessages } from '../utils/utils'

const FormItem = Form.Item

let StyledForgotPassword = style.div`

  & .ant-form-item-required:before{
    display:none;
  }
  & .ant-form-item{
    margin-bottom:2px;
  }
  & .ant-form-item-label {
    height: 15px;
    line-height:1;
  }
  & .ant-form label{
    font-size:11px;
    opacity:0.0000001;
  }
  & .with-label label {
    opacity:1;
  }
  & .ant-btn{
    border-radius:15px;
  }
  input, textarea, & .ant-input-number, span.ant-calendar-picker, & .ant-select-selection {
    border-radius:0 !important;
    width:100%;
    &:hover, &:active, &:focus{
      outlinhe:none;
      border-color:${theme.primary} !important;
    }
  }
  & .ant-btn-primary{
    background-color: ${theme.primary};
    border-color: ${theme.primary};
    &:hover, &:active, &:focus{
      background-color: ${theme.lightPrimary};
      border-color: ${theme.lightPrimary};
      box-shadow: 0 10px 20px 0 rgba(0,0,0,.16), 0 -1px 2px 0 rgba(0,0,0,.1);
    }
  }
  button{
    margin-top:20px;
  }
`
let StyledModal = style.div`
  & .ant-modal-content{
    background-color:${themes[selectedColor].primary} !important;
  }
`
class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false
    }

    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  showModal() {
    this.setState({
      visible: true
    })
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleCancel() {
    this.setState({ visible: false })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let requestObject = {}
        this.setState({ loading: true })
        requestObject['email'] = values.email
        let method = 'POST'
        let endPoint = '/user/forgot-password'
        this.setState({ loading: true })
        request(endPoint, method, requestObject).then(response => {
          this.setState({ loading: false })
          getMessages(response)
          this.handleCancel()
          this.props.form.resetFields()
        })
      }
    })
  }

  handleSelectChange = value => {}
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <a
          className="login-form-forgot"
          style={{
            color: '#1993FB',
            marginTop: '1em',
            fontWeight: 'Bold',
            fontSize: '12px'
          }}
          onClick={this.showModal}>
          {/*           <Icon className="key-login" type="key" /> */}
          Forgot Password
        </a>
        <StyledModal>
          <Modal
            visible={this.state.visible}
            width={350}
            tiele
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}>
            <StyledForgotPassword>
              <h5>Please enter your Email</h5>
              <Form onSubmit={this.handleSubmit}>
                <FormItem className={this.haveValue('email')} label="Email">
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'Please input your email!' },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      }
                    ]
                  })(<Input style={{ width: '100%' }} placeholder="Email" />)}
                </FormItem>

                <Button
                  loading={this.state.loading}
                  type="primary"
                  onClick={this.handleSubmit}>
                  Send
                </Button>
              </Form>
            </StyledForgotPassword>
          </Modal>
        </StyledModal>
      </div>
    )
  }
}

export default Form.create()(ForgotPassword)
