import 'antd/dist/antd.css'
import React, { Component } from 'react'
import {
  Button,
  Dropdown,
  Modal,
  Menu,
  Card,
  Col,
  Icon,
  notification
} from 'antd'
import MaterialIcon from '../../utils/icon/Icon'
import { Link } from 'react-router-dom'
import request from '../../utils/requestWrapper'
import Table from '../../utils/TableWrapper'
import styled from 'styled-components'
import moment from 'moment'
import User from '../../assets/images/user.png'
import { themes } from '../../utils/style-utils'
import { getUrl } from '../../utils/utils'

const confirm = Modal.confirm

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    priority: 2
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    priority: 3
  },
  {
    title: 'Email',
    dataIndex: 'email',
    priority: 4
  },
  {
    title: 'Contact Number',
    dataIndex: 'contactNumber1',
    priority: 4
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    priority: 4
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    priority: 4,
    render: text => moment(text).format('DD-MM-YYYY h:mm')
  }
]

notification.config({
  placement: 'bottomRight',
  bottom: 50
})

const StyledEmployeeCardList = styled.div`
  .ant-card {
    background: #ffffff;
    margin: 0;
    max-width: 100%;
    min-height: 320px;
    cursor: default;
  }
  & .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3em;
  }
  i.material-icon {
    margin: 0;
    font-size: 18px;
    width: 22px;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    border: 2px solid ${props => themes[props.theme].textAndIcons};
  }
  h3 {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    margin-top: 1em;
    word-break: break-all;
  }
  .with-icon-row {
    word-wrap: break-word;
  }
  .card-col {
    padding: 1em;
  }
  @media screen and (min-width: 768px) {
    .ant-card {
      min-height: 380px;
    }
    h3 {
      font-size: 25px;
    }
  }
`
export default class Employee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 1,
      action: null,
      id: null,
      loading: true,
      showLogin: false,
      data: [],
      pagination: {
        page: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      config: null,
      params: { page: 1, pageSize: 10 },
      showFilter: false,
      columns: columns
    }

    this.handleActionsMenu = this.handleActionsMenu.bind(this)
    this.getEmployees = this.getEmployees.bind(this)
    this.handleMenuClick = this.handleMenuClick.bind(this)
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = Object.assign({}, this.state.pagination)
    pager.current = pagination.current
    pager.pageSize = pagination.pageSize
    this.setState({
      pagination: pager
    })
    let params = this.state.params
    params.pageSize = pagination.pageSize
    params.page = pagination.current
    if (sorter.field) {
      params['sort'] = {
        field: sorter.field,
        order: sorter.order === 'descend' ? 'desc' : 'asc'
      }
    }
    this.getEmployees(params)
  }

  handleActionsMenu(item, key, keyPath, id) {
    if (key === '3') {
      this.handleResendVerification(id)
    }

    if (key === '4') {
      let current = this
      confirm({
        title: 'Do you Want to delete these ?',
        onOk() {
          current.delete(id)
        },
        onCancel() {}
      })
    }
  }

  delete(id) {
    let endPoint = `/employee/${id}`
    this.setState({ loading: true })
    request(endPoint, 'DELETE').then(response => {
      if (response.status === 401) {
      } else if (response.status === 200) {
        this.getEmployees()
      }
    })
  }

  handleMenuClick(key) {
    if (key === '1') {
      this.setState({ view: 1 })
    } else {
      this.setState({ view: 2 })
    }
  }

  componentDidMount() {
    this.getEmployees(this.state.params)
  }

  reloadData() {
    this.getEmployees(this.state.params)
  }

  getEmployees(params) {
    this.setState({ loading: true, showLogin: false, id: null })
    // let endPoint = '/employee'
    let endPoint = getUrl('/employee', params)
    request(endPoint).then(response => {
      this.setState({ loading: false })
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        const pagination = Object.assign({}, this.state.pagination)
        pagination.total = response.navigation.total
        this.setState({
          data: response.data,
          pagination
        })
      }
    })
  }

  handleResendVerification = id => {
    let endPoint = `/employee/${id}/send-verification`
    this.setState({ loading: true })
    request(endPoint, 'GET').then(response => {
      if (response.status === 400) {
        this.setState({ loading: false })
        notification.error({
          message: <span style={{ fontSize: '12px' }}>{response.message}</span>,
          duration: 5
        })
      } else if (response.status === 200) {
        this.setState({ loading: false })
        notification.success({
          message: <span style={{ fontSize: '12px' }}>{response.message}</span>,
          duration: 5
        })
      }
    })
  }

  render() {
    let tableColumns = []
    this.state.columns.forEach(column => {
      tableColumns.push(column)
    })
    tableColumns.push({
      title: 'Operations',
      dataIndex: 'actions',
      key: 'actions',
      priority: 1,
      render: (text, record, index) => {
        return (
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key, keyPath }) => {
                  this.handleActionsMenu(item, key, keyPath, record._id)
                }}>
                <Menu.Item key="1">
                  <Link
                    to={{
                      pathname: `/home/employee/view/${record._id}`,
                      state: { profile: record },
                      message: null
                    }}>
                    <Icon style={{ marginRight: '8px' }} type="eye" />
                    View
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={{ pathname: `/home/employee/edit/${record._id}` }}>
                    <Icon style={{ marginRight: '8px' }} type="edit" />
                    Edit
                  </Link>
                </Menu.Item>
                {record && !record.active && (
                  <Menu.Item key="3">
                    <Icon style={{ marginRight: '8px' }} type="file-sync" />
                    Verify
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button style={{ marginLeft: 8, border: 0 }}>
              <Icon type="bars" /> <Icon type="down" />
            </Button>
          </Dropdown>
        )
      }
    })
    const menu = (
      <Menu onClick={({ item, key, keyPath }) => this.handleMenuClick(key)}>
        <Menu.Item key="1">Table</Menu.Item>
        <Menu.Item key="2">Card</Menu.Item>
      </Menu>
    )
    let cards = null
    if (this.state.data.length > 0) {
      cards = this.state.data.map(employee => {
        return (
          <StyledEmployeeCardList theme={this.props.theme}>
            <Col className="card-col" xs={24} sm={6} md={6} lg={6}>
              <Card hoverable>
                <div className="info-container">
                  <img
                    alt=""
                    src={
                      employee.displayPic && employee.displayPic.original
                        ? employee.displayPic.original
                        : User
                    }
                  />
                  <h3>{`${employee.firstName} ${employee.lastName}`}</h3>
                  <div className="with-icon-row">
                    <MaterialIcon type="email" />
                    <span>{`${employee.email}`}</span>
                  </div>
                  <Link
                    to={{
                      pathname: `/home/employee/view/${employee._id}`,
                      state: { profile: employee },
                      message: null
                    }}>
                    <Button icon="eye">View</Button>
                  </Link>
                </div>
              </Card>
            </Col>
          </StyledEmployeeCardList>
        )
      })
    }
    return (
      <div>
        <Dropdown overlay={menu}>
          <Button className="switch-button" style={{ marginBottom: '1em' }}>
            {this.state.view === 1 ? 'Table' : 'Card'} <Icon type="down" />
          </Button>
        </Dropdown>
        {this.state.view === 1 && (
          <Card>
            <Table
              dataSource={this.state.data}
              columns={tableColumns}
              loading={this.state.loading}
              rowKey="_id"
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
            />
          </Card>
        )}
        {this.state.view === 2 && <div>{cards}</div>}
      </div>
    )
  }
}
