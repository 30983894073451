import React, { Component } from 'react'
import style from 'styled-components'
import WrapPersonalIInfo from './PersonalInfoForm'
import WrapJobInfo from './JobInfoForm'
import WrapProfessionInfo from './ProfessionInfoForm'
import WrapAddressInfo from './AddressInfoForm'
import { Card, Steps, Breadcrumb } from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import request from '../../utils/requestWrapper'
import { getMessages, processEmployeeForm, getDate } from '../../utils/utils'
import Spin from '../../utils/Spinner'

import { themes } from '../../utils/style-utils'

const Step = Steps.Step

const steps = [
  {
    title: 'Personal Info'
  },
  {
    title: 'Job Info'
  },
  {
    title: 'Profession Info'
  },
  {
    title: 'Address Info'
  }
]

const StyledEmployeeForm = style.div`
  & .ant-card{
    margin:1em 0;
  }
  h5{
    font-weight:bold;
  }
  & .form-header{
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
  }
  span.ant-breadcrumb-link{
    cursor:pointer;
    &:hover{
      color: ${props => themes[props.theme].primary};
    }
  }
  & .previous-button{
    margin-right:10px;
  }
  @media screen and (min-width: 768px){
    & .ant-col-xs-24.ant-col-sm-8, & .ant-col-xs-24.ant-col-md-6 {
      padding-right:30px;
    }
  }
`
class EmployeeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      formData: Array(4).fill(null),
      selectData: {},
      loading: false
    }

    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.changeStep = this.changeStep.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getSelectData = this.getSelectData.bind(this)
  }

  componentDidMount() {
    this.getSelectData()
    if (this.props.edit) {
      this.getEditData()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({
        formData: Array(4).fill(null),
        current: 0
      })
    }
  }
  getEditData() {
    this.setState({ loading: true, showLogin: false })

    let endPoint = `/employee/${this.props.match.params.id}`
    request(endPoint).then(response => {
      this.setState({ loading: false })
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200 || response.status === 201) {
        let values = response.data
        let formData = []
        formData[0] = {
          displayPic: values.displayPic
            ? {
                original: values.displayPic.original
              }
            : undefined,
          firstName: values.firstName,
          middleName: values.middleName ? values.middleName : undefined,
          lastName: values.lastName,
          email: values.email,
          personalEmail: values.personalEmail,
          contactNumber1: values.contactNumber1,
          contactNumber2: values.contactNumber2
            ? values.contactNumber2
            : undefined,
          dateOfBirth: moment(getDate(values.dateOfBirth), 'YYYY-MM-DD'),
          gender: values.gender,
          nationality: values.nationality,
          marital: values.marital ? values.marital : undefined
        }
        formData[1] = {
          dateOfJoin: moment(getDate(values.dateOfJoin), 'YYYY-MM-DD'),
          branch: values.branch ? values.branch._id : undefined,
          department: values.department ? values.department._id : undefined,
          jobTitle: values.jobTitle ? values.jobTitle._id : undefined,
          manager: values.manager ? values.manager._id : undefined,
          teamLead: values.teamLead ? values.teamLead._id : undefined,
          trainer: values.trainer ? values.trainer._id : undefined,
          hiredBy: values.hiredBy ? values.hiredBy._id : undefined,
          referedBy: values.referedBy ? values.referedBy._id : undefined,
          visaDetials: values.visaDetials
            ? {
                jobTitle: values.visaDetials.jobTitle
                  ? values.visaDetials.jobTitle
                  : undefined,
                visaSponser: values.visaDetials.visaSponser
                  ? values.visaDetials.visaSponser
                  : undefined,
                visaStartDate: values.visaDetials.visaStartDate
                  ? moment(
                      getDate(values.visaDetials.visaStartDate),
                      'YYYY-MM-DD'
                    )
                  : undefined,
                visaEndDate: values.visaDetials.visaEndDate
                  ? moment(
                      getDate(values.visaDetials.visaEndDate),
                      'YYYY-MM-DD'
                    )
                  : undefined
              }
            : {},
          identityDetails:
            values.identityDetails && values.identityDetails.length > 0
              ? values.identityDetails
              : []
        }
        formData[2] = {
          education:
            values.education && values.education.length > 0
              ? values.education
              : [],
          workExperience:
            values.workExperience && values.workExperience.length > 0
              ? values.workExperience
              : []
        }
        formData[3] = {
          homeAddress: values.homeAddress
            ? {
                address: values.homeAddress.address,
                city: values.homeAddress.city,
                state: values.homeAddress.state,
                zip: values.homeAddress.zip
                  ? values.homeAddress.zip
                  : undefined,
                contact: values.homeAddress.contact,
                country: values.homeAddress.country
              }
            : {},
          permanentAddress: values.permanentAddress
            ? {
                address: values.permanentAddress.address,
                city: values.permanentAddress.city,
                state: values.permanentAddress.state,
                zip: values.permanentAddress.zip
                  ? values.permanentAddress.zip
                  : undefined,
                contact: values.permanentAddress.contact,
                country: values.permanentAddress.country
              }
            : {},
          social: values.social
            ? {
                fb: values.social.fb,
                twitter: values.social.twitter,
                linkedin: values.social.linkedin,
                googleplus: values.social.googleplus
              }
            : {}
        }
        this.setState({ formData })
      }
    })
  }

  getSelectData() {
    let employeeEndPoint = '/helper/employee'
    request(employeeEndPoint).then(response => {
      if (response.status === 401) {
      } else if (response.status === 200) {
        let { selectData } = this.state
        selectData.employee = response.data
        this.setState({ selectData })
      }
    })

    let departmentEndPoint = '/helper/department'
    request(departmentEndPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        let { selectData } = this.state
        selectData.department = response.data
        this.setState({ selectData })
      }
    })

    let locationEndPoint = '/location/country'
    request(locationEndPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        let { selectData } = this.state
        selectData.country = response.data
        this.setState({ selectData })
      }
    })

    let jobTitleEndPoint = '/helper/jobtitle'
    request(jobTitleEndPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        let { selectData } = this.state
        selectData.jobTitle = response.data
        this.setState({ selectData })
      }
    })
    let branchEndPoint = '/helper/branch'
    request(branchEndPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        let { selectData } = this.state
        selectData.branch = response.data
        this.setState({ selectData })
      }
    })
  }

  next(data) {
    let formData = this.state.formData
    formData[this.state.current] = data
    this.setState({
      formData,
      current: this.state.current + 1
    })
  }

  changeStep(current) {
    this.setState({ current })
  }

  prev() {
    const current = this.state.current - 1
    this.setState({ current })
  }

  handleSubmit(values) {
    let requestObject = {
      ...this.state.formData[0],
      ...this.state.formData[1],
      ...this.state.formData[2],
      ...values
    }
    requestObject = processEmployeeForm(requestObject)
    //TODO add request to API endpoint
    let method = this.props.edit ? 'PATCH' : 'POST'
    let endPoint = this.props.edit
      ? `/employee/${this.props.match.params.id}`
      : '/employee'
    request(endPoint, method, requestObject).then(response => {
      getMessages(response)
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        this.props.history.push('/home/employee/list')
      }
    })
  }

  render() {
    const { current } = this.state
    return (
      <StyledEmployeeForm theme={this.props.theme}>
        <Spin loading={this.state.loading} />
        <Card>
          <Steps
            direction={this.state.width < 768 ? 'vertical' : 'horizontal'}
            current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Card>
        <div className="steps-content" style={{ marginTop: '18px' }}>
          <Card
            title={
              (this.props.view ? 'View' : this.props.edit ? 'Edit' : 'Add') +
              ' Employee'
            }>
            <div className="form-header">
              <Breadcrumb>
                {this.state.current > 0 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(0)}>
                    Personal Info
                  </Breadcrumb.Item>
                )}
                {this.state.current > 1 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(1)}>
                    Job Info
                  </Breadcrumb.Item>
                )}
                {this.state.current > 2 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(2)}>
                    Profession Info
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            </div>
            {this.state.current === 0 && (
              <WrapPersonalIInfo
                selectData={this.state.selectData}
                data={this.state.formData[0]}
                onSubmit={this.next}
                view={this.props.view}
              />
            )}
            {this.state.current === 1 && (
              <WrapJobInfo
                theme={this.props.theme}
                selectData={this.state.selectData}
                data={this.state.formData[1]}
                onSubmit={this.next}
                onPrev={this.prev}
                view={this.props.view}
              />
            )}
            {this.state.current === 2 && (
              <WrapProfessionInfo
                theme={this.props.theme}
                selectData={this.state.selectData}
                data={this.state.formData[2]}
                onSubmit={this.next}
                onPrev={this.prev}
                view={this.props.view}
              />
            )}
            {this.state.current === 3 && (
              <WrapAddressInfo
                selectData={this.state.selectData}
                data={this.state.formData[3]}
                onSubmit={this.handleSubmit}
                onPrev={this.prev}
                view={this.props.view}
              />
            )}
          </Card>
        </div>
      </StyledEmployeeForm>
    )
  }
}
export default withRouter(EmployeeForm)
