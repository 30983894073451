import React, { Component } from 'react'
import { Form, Icon, Select, Row, Col, Button, Input, DatePicker } from 'antd'

const dateFormat = 'DD/MM/YYYY'

const FormItem = Form.Item

class ProfessionInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Verified: false,
      showOtherPurpose: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.prev = this.prev.bind(this)
    this.haveValue = this.haveValue.bind(this)
    this.update = this.update.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.form.setFieldsValue(this.props.data)
    }
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  update(key, data) {
    this.setState({ [key]: data })
  }

  handleChange = (e, value) => {
    if (e === 'verified') {
      if (value === 'Yes') {
        this.setState({
          Verified: true
        })
      } else {
        this.setState({
          Verified: false
        })
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let newValues = { ...values }
        const { getFieldValue } = this.props.form
        if (getFieldValue('monthlyIncome.isMonthlyIncomeVerified') !== 'Yes') {
          newValues['monthlyIncome']['amount'] = ''
        }
        if (getFieldValue('loan.purpose') !== 'Others') {
          newValues['loan']['otherPurpose'] = ''
        }
        this.props.onSubmit(newValues)
      }
    })
  }

  prev() {
    this.props.onPrev()
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form

    return (
      <Row className="button-row">
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('employmentConfirmed')}
                label="Employment Confirmed*">
                {getFieldDecorator('employmentConfirmed', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select your Employment Confirmed'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Employement Confirmed*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option key="OnProbation" value="OnProbation">
                      On Probation
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('workingSince')}
                label="Working Since">
                {getFieldDecorator('workingSince', {})(
                  <DatePicker format={dateFormat} disabled={this.props.view} />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('designation')}
                label="Designation*">
                {getFieldDecorator('designation', {
                  rules: [
                    { required: true, message: 'Please select the Designation' }
                  ]
                })(<Input placeholder="Designation*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('officerEmail')}
                label="Officer Email">
                {getFieldDecorator('officerEmail', {
                  rules: [
                    {
                      message: 'Please select the officer Email'
                    }
                  ]
                })(<Input placeholder="Officer Email" type="email" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('loan.purpose')} 
                label="Loan Purpose">
                {getFieldDecorator('loan.purpose', {
                  rules: [
                    {
                      required: getFieldValue('productType') === 'Personal Loan' ? true : false,
                      message: 'Please select the Loan Purpose'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select the Loan Purpose">
                    <Select.Option key="Card Req" value="Card Req">
                      Card Req
                    </Select.Option>
                    <Select.Option
                      key="Purch Prop in HC"
                      value="Purch Prop in HC">
                      Purch Prop in HC
                    </Select.Option>
                    <Select.Option
                      key="Purch Prop in UAE"
                      value="Purch Prop in UAE">
                      Purch Prop in UAE
                    </Select.Option>
                    <Select.Option
                      key="Medical Emergency in UAE"
                      value="Medical Emergency in UAE">
                      Medical Emergency in UAE
                    </Select.Option>
                    <Select.Option
                      key="Medical Emergency in HC"
                      value="Medical Emergency in HC">
                      Medical Emergency in HC
                    </Select.Option>
                    <Select.Option
                      key="Relative Wedding in HC"
                      value="Relative Wedding in HC">
                      Relative Wedding in HC
                    </Select.Option>
                    <Select.Option
                      key="School Fee in UAE"
                      value="School Fee in UAE">
                      School Fee in UAE
                    </Select.Option>
                    <Select.Option
                      key="Purch Prop in HC"
                      value="Purch Prop in HC">
                      Purch Prop in HC
                    </Select.Option>
                    <Select.Option
                      key="Clearing other CC/Loan"
                      value="Clearing other CC/Loan">
                      Clearing other CC/Loan
                    </Select.Option>
                    <Select.Option key="Buy used car" value="Buy used car">
                      Buy used car
                    </Select.Option>
                    <Select.Option key="Invest in Bus" value="Invest in Bus">
                      Invest in Bus
                    </Select.Option>
                    <Select.Option
                      key="Personal Invest"
                      value="Personal Invest">
                      Personal Invest
                    </Select.Option>
                    <Select.Option
                      key="Jewellery Purch"
                      value="Jewellery Purch">
                      Jewellery Purch
                    </Select.Option>
                    <Select.Option key="Others" value="Others">
                      Other reason
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('loan.otherPurpose')}
                label="Another Loan Purpose">
                {getFieldDecorator('loan.otherPurpose', {
                  rules: [
                    {
                      required: getFieldValue('loan.purpose') === 'Others' ? true : false,
                      message: 'Please enter another Loan Purpose'
                    }
                  ]
                })(<Input placeholder="Another Loan Purpose" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue(
                  'monthlyIncome.isMonthlyIncomeVerified'
                )}
                label="Is Monthly Income Verified*">
                {getFieldDecorator('monthlyIncome.isMonthlyIncomeVerified', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select one Option'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={e => this.handleChange('verified', e)}
                    placeholder="Industry*">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('loan.amount')}
                label="Loan Amount">
                {getFieldDecorator('loan.amount', {
                  rules: [
                    {
                      required: getFieldValue('productType') == 'Personal Loan' &&
                       getFieldValue('monthlyIncome.isMonthlyIncomeVerified') === 'Yes' ? true : false,
                      message: 'Please select the Loan Amount'
                    }
                  ]
                })(<Input placeholder="Loan Amount" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('monthlyIncome.amount')}
                label="Monthly Income Amount*">
                {getFieldDecorator('monthlyIncome.amount', {
                  rules: [
                    {
                      required:
                        getFieldValue(
                          'monthlyIncome.isMonthlyIncomeVerified'
                        ) === 'Yes'
                          ? true
                          : false,
                      message: 'Please select the Monthly Income Amount'
                    }
                  ]
                })(<Input placeholder="Monthly Income Amount*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('salaryTransMode')}
                label="salaryTransMode">
                {getFieldDecorator('salaryTransMode', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the Salary TransMode'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select Salary TransMode*">
                    <Select.Option key="Bank" value="Bank">
                      Bank
                    </Select.Option>
                    <Select.Option key="WPS" value="WPS">
                      WPS
                    </Select.Option>
                    <Select.Option key="Cash" value="Cash">
                      Cash
                    </Select.Option>
                    <Select.Option key="Cheque" value="Cheque">
                      Cheque
                    </Select.Option>
                    <Select.Option key="N/A" value="N/A">
                      N/A
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row></Row>
          <Row className="button-row">
            <Button style={{ marginRight: '8px' }} onClick={this.prev}>
              <Icon type="left" />
              Previous
            </Button>
            <Button type="primary" htmlType="submit">
              Next
              <Icon type="right" />
            </Button>
          </Row>
        </Form>
      </Row>
    )
  }
}
export default Form.create()(ProfessionInfo)
