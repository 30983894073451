import React, { Component } from 'react'
import style from 'styled-components'
import WrapPersonalIInfo from './PersonalInfoForm'
import WrapBusinessInfo from './BusinessInfoForm'
import WrapAgentFeedback from './AgentFeedbackForm'
import { Card, Steps, Breadcrumb } from 'antd'
import { withRouter } from 'react-router-dom'
import request from '../../utils/requestWrapper'
import { getMessages } from '../../utils/utils'
import Spin from '../../utils/Spinner'
import countries from '../../utils/countries.json'

import { themes } from '../../utils/style-utils'

const Step = Steps.Step

const steps = [
  {
    title: 'Personal Info'
  },
  {
    title: 'Business Info'
  },
  {
    title: 'Agent Feedback'
  }
]

const StyledEmployeeForm = style.div`
  & .ant-card{
    margin:1em 0;
  }
  h5{
    font-weight:bold;
  }
  & .form-header{
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
  }
  span.ant-breadcrumb-link{
    cursor:pointer;
    &:hover{
      color: ${props => themes[props.theme].primary};
    }
  }
  & .previous-button{
    margin-right:10px;
  }
  @media screen and (min-width: 768px){
    & .ant-col-xs-24.ant-col-sm-8, & .ant-col-xs-24.ant-col-md-6 {
      padding-right:30px;
    }
  }
`
class EmployeeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      formData: Array(4).fill(null),
      selectData: {},
      loading: false
    }

    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.changeStep = this.changeStep.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setCountries = this.setCountries.bind(this)
    this.getImages = this.getImages.bind(this)
  }

  componentDidMount() {
    this.setCountries(countries)
    if (this.props.edit) {
      this.getEditData()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({
        formData: Array(4).fill(null),
        current: 0
      })
    }
  }

  async getImages(array, key) {
    let i = 0
    const newArray = []
    for (i = 0; i < array.length; i++) {
      let endPoint = `/my-drive/file/${array[i]}`
      const response = await request(endPoint)
      if (response.status === 200) {
        newArray.push({ id: response.data._id, url: response.data.url })
      }
      if (response.status === 400) {
      }
    }
    this.setState({ [key]: newArray })
  }

  async getEditData() {
    this.setState({ loading: true, showLogin: false })
    let endPoint = `/lnddo-lead/${this.props.match.params.id}`
    const response = await request(endPoint)
    this.setState({ loading: false })
    if (response.status === 401) {
      this.setState({ showLogin: true, action: this.reloadData })
    } else if (response.status === 200 || response.status === 201) {
      let values = response.data
      this.getImages(values.images, 'images')
      this.getImages(values.imageDoc, 'imageDoc')
      let formData = []
      console.log('values: ', values);
      formData[0] = {
        images: values.images,
        imageDoc: values.imageDoc,
        customerName: values.customerName,
        cifNumber: values.cifNumber,
        nameOfBusiness: values.nameOfBusiness,
        address: values.address
          ? {
              line1: values.address.line1,
              line2: values.address.line2,
              line3: values.address.line3,
              emirate: values.address.emirate
            }
          : {}
      }
      formData[1] = {
        nameOfShareholder: values.nameOfShareholder,
        nameOfPersonMet: values.nameOfPersonMet,
        desgOfPersonMet: values.desgOfPersonMet,
        mobileOfPersonMet: values.mobileOfPersonMet,
        isLandlineAvailable: values.isLandlineAvailable,
        isMachinesAvailable: values.isMachinesAvailable,
        businessLandline: values.businessLandline,
        typeoFbuilding: values.typeoFbuilding,
        typeoFbuildingOther: values.typeoFbuildingOther,
        diffLocate: values.diffLocate,
        noOfEmpSeen: values.noOfEmpSeen,
        typeOfBusinessObs: values.typeOfBusinessObs,
        typeOfBusinessObsOther: values.typeOfBusinessObsOther,
        noOfCustSeen: values.noOfCustSeen,
        apxDailyCustFootfall: values.apxDailyCustFootfall,
        apxAmtDealtCash: values.apxAmtDealtCash,
        apxValDigSale: values.apxValDigSale,
        stockLevel: values.stockLevel,
        stockLevelOther: values.stockLevelOther,
        personMetExplBusn: values.personMetExplBusn,
        personMetExplBusnOther: values.personMetExplBusnOther,
      }
      formData[2] = {
        signBoardPresent: values.signBoardPresent,
				signBoardPresentOther: values.signBoardPresentOther,
				neighborhoodFeedback: values.neighborhoodFeedback,
				neighborhoodFeedbackOther: values.neighborhoodFeedbackOther,
				statusOfTradeLic: values.statusOfTradeLic,
				statusOfTradeLicOther: values.statusOfTradeLicOther,
				anyCmpOperating: values.anyCmpOperating,
				anyCmpOperatingOther: values.anyCmpOperatingOther,
				feedback: values.feedback,
				comments2: values.comments2,
				comments3: values.comments3,
				status: values.status,
        latitude: values.latitude,
        longitude: values.longitude
      }
      this.setState({ formData })
    }
  }

  setCountries(countries) {
    let { selectData } = this.state
    selectData.country = countries
    this.setState({ selectData })
  }

  next(data) {
    let formData = this.state.formData
    formData[this.state.current] = data
    this.setState({
      formData,
      current: this.state.current + 1
    })
  }

  changeStep(current) {
    this.setState({ current })
  }

  prev() {
    const current = this.state.current - 1
    this.setState({ current })
  }

  handleSubmit(values) {
    let requestObject = {
      ...this.state.formData[0],
      ...this.state.formData[1],
      ...this.state.formData[2],
      ...values
    }

    // requestObject = processEmployeeForm(requestObject)
    //TODO add request to API endpoint
    let method = this.props.edit ? 'PATCH' : 'POST'
    let endPoint = this.props.edit
      ? `/lnddo-lead/${this.props.match.params.id}`
      : '/lnddo-lead'
    request(endPoint, method, requestObject).then(response => {
      getMessages(response)
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        this.props.history.push('/home/lnddo-lead')
      }
    })
  }

  render() {
    const { current } = this.state
    return (
      <StyledEmployeeForm theme={this.props.theme}>
        <Spin loading={this.state.loading} />
        <Card>
          <Steps
            direction={this.state.width < 768 ? 'vertical' : 'horizontal'}
            current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Card>
        <div className="steps-content" style={{ marginTop: '18px' }}>
          <Card
            title={
              (this.props.view ? 'View' : this.props.edit ? 'Edit' : 'Add') +
              ' Lnddo FV Lead'
            }>
            <div className="form-header">
              <Breadcrumb>
                {this.state.current > 0 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(0)}>
                    Personal Info
                  </Breadcrumb.Item>
                )}
                {this.state.current > 1 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(1)}>
                    Job Info
                  </Breadcrumb.Item>
                )}
                {this.state.current > 2 && (
                  <Breadcrumb.Item onClick={e => this.changeStep(2)}>
                    Profession Info
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            </div>
            {this.state.current === 0 && (
              <WrapPersonalIInfo
                id={this.props.match.params.id}
                data={this.state.formData[0]}
                onSubmit={this.next}
                view={this.props.view}
                sendImages={this.state.images}
                sendImageDoc={this.state.imageDoc}
              />
            )}
            {this.state.current === 1 && (
              <WrapBusinessInfo
                theme={this.props.theme}
                data={this.state.formData[1]}
                onSubmit={this.next}
                onPrev={this.prev}
                view={this.props.view}
              />
            )}
            {this.state.current === 2 && (
              <WrapAgentFeedback
                selectData={this.state.selectData}
                data={this.state.formData[2]}
                onSubmit={this.handleSubmit}
                onPrev={this.prev}
                view={this.props.view}
              />
            )}
          </Card>
        </div>
      </StyledEmployeeForm>
    )
  }
}
export default withRouter(EmployeeForm)


/* { 
    "_id" : ObjectId("61aeeee8627ce52cea188baa"), 
    "active" : true, 
    "status" : "awaiting", 
    "customerName" : "Test case 2", 
    "cifNumber" : "56565657888", 
    "nameOfBusiness" : "Test company ", 
    "address" : {
        "line1" : "5", 
        "line2" : "Gjj", 
        "line3" : "Gbb", 
        "emirate" : "Sharjah"
    }, 
    "nameOfShareholder" : "Partner 1, partner  2 , partner 3", 
    "nameOfPersonMet" : "Hari", 
    "desgOfPersonMet" : "Hr", 
    "mobileOfPersonMet" : "050688889", 
    "date" : "12/07/21", 
    "page" : NumberInt(2), 
    "isLandlineAvailable" : "Yes", 
    "businessLandline" : "044678999", 
    "isMachinesAvailable" : "Yes", 
    "typeoFbuilding" : "Other", 
    "typeoFbuildingOther" : "Business center ", 
    "diffLocate" : "Commercial", 
    "noOfEmpSeen" : "5", 
    "typeOfBusinessObs" : "Trading", 
    "noOfCustSeen" : "12", 
    "apxDailyCustFootfall" : "25", 
    "apxAmtDealtCash" : "50,000", 
    "apxValDigSale" : "23000", 
    "stockLevel" : "Average", 
    "personMetExplBusn" : "Yes", 
    "signBoardPresent" : "Yes", 
    "neighborhoodFeedback" : "Business Operates daily", 
    "statusOfTradeLic" : "Active & displayed", 
    "anyCmpOperating" : "No", 
    "feedback" : "Positive ", 
    "comments2" : "Jjj, hjjnyhhhhtgghoiuhgvg    thijhvvc  hhbffghvthxccvcvcg gghhyddgc", 
    "comments3" : "Gbjj", 
    "longitude" : 55.2020385, 
    "latitude" : 25.0364888, 
    "images" : [
        "61aeeeb1627ce52cea188ba0", 
        "61aeeeb9627ce52cea188ba2", 
        "61aeeec4627ce52cea188ba4"
    ], 
    "imageDoc" : [
        "61aeeecc627ce52cea188ba6", 
        "61aeeed5627ce52cea188ba8"
    ], 
    "createdBy" : ObjectId("5bc5e62467084364497dcf40"), 
    "isSftp" : NumberInt(0), 
    "createdAt" : ISODate("2021-12-07T05:19:36.798+0000"), 
    "updatedAt" : ISODate("2022-01-03T14:11:07.953+0000"), 
    "__v" : NumberInt(0), 
    "updatedBy" : ObjectId("5bc5e62467084364497dcf40")
} */