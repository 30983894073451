import React, { Component } from 'react'
import style from 'styled-components'
import {
  Form,
  Input,
  InputNumber,
  Icon,
  Select,
  Row,
  Col,
  Button,
  Tag
} from 'antd'

const FormItem = Form.Item
const StyledJobInfoForm = style.div``

class AddressInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filled: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.prev = this.prev.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.form.setFieldsValue(this.props.data)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && !this.state.filled) {
      this.props.form.setFieldsValue(nextProps.data)
      this.setState({
        filled: true
      })
    }
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values)
      }
    })
  }

  prev() {
    this.props.onPrev()
  }
  fillPermanentAddress = () => {
    let values = this.props.form.getFieldsValue()
    this.props.form.setFieldsValue({ permanentAddress: values.homeAddress })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <StyledJobInfoForm>
        <Form onSubmit={this.handleSubmit}>
          <Tag>UAE Address</Tag>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('homeAddress.address')}
                label="Address*">
                {getFieldDecorator('homeAddress.address', {
                  rules: [
                    { required: true, message: 'Please enter the address' }
                  ]
                })(<Input disabled={this.props.view} placeholder="Address*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('homeAddress.city')}
                label="City*">
                {getFieldDecorator('homeAddress.city', {
                  rules: [{ required: true, message: 'Please enter the city' }]
                })(<Input disabled={this.props.view} placeholder="City*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('homeAddress.state')}
                label="State*">
                {getFieldDecorator('homeAddress.state', {
                  rules: [{ required: true, message: 'Please enter the state' }]
                })(<Input disabled={this.props.view} placeholder="State*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('homeAddress.zip')}
                label="Zip Code">
                {getFieldDecorator(
                  'homeAddress.zip',
                  {}
                )(
                  <InputNumber
                    disabled={this.props.view}
                    placeholder="Zip Code"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('homeAddress.contact')}
                label="Contact*">
                {getFieldDecorator('homeAddress.contact', {
                  rules: [
                    { required: true, message: 'Please enter the contact' }
                  ]
                })(<Input disabled={this.props.view} placeholder="Contact*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('homeAddress.country')}
                label="Country*">
                {getFieldDecorator('homeAddress.country', {
                  rules: [
                    { required: true, message: 'Please enter the country' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Country*">
                    {this.props.selectData.country
                      ? Object.keys(this.props.selectData.country).map(key => (
                          <Select.Option key={key} value={key}>
                            {this.props.selectData.country[key]}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Tag>Home Country Address</Tag>{' '}
          <Button
            icon="caret-down"
            className="ant-btn ant-btn-primary"
            onClick={this.fillPermanentAddress}>
            Copy
          </Button>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('permanentAddress.address')}
                label="Address*">
                {getFieldDecorator('permanentAddress.address', {
                  rules: [
                    { required: true, message: 'Please enter the address' }
                  ]
                })(<Input disabled={this.props.view} placeholder="Address*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('permanentAddress.city')}
                label="City*">
                {getFieldDecorator('permanentAddress.city', {
                  rules: [{ required: true, message: 'Please enter the city' }]
                })(<Input disabled={this.props.view} placeholder="City*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('permanentAddress.state')}
                label="State*">
                {getFieldDecorator('permanentAddress.state', {
                  rules: [{ required: true, message: 'Please enter the state' }]
                })(<Input disabled={this.props.view} placeholder="State*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('permanentAddress.zip')}
                label="Zip Code">
                {getFieldDecorator(
                  'permanentAddress.zip',
                  {}
                )(
                  <InputNumber
                    disabled={this.props.view}
                    placeholder="Zip Code"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('permanentAddress.contact')}
                label="Contact*">
                {getFieldDecorator('permanentAddress.contact', {
                  rules: [
                    { required: true, message: 'Please enter the contact' }
                  ]
                })(<Input disabled={this.props.view} placeholder="Contact*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('permanentAddress.country')}
                label="Country*">
                {getFieldDecorator('permanentAddress.country', {
                  rules: [
                    { required: true, message: 'Please enter the country' }
                  ]
                })(
                  <Select
                    optionFilterProp="Country"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Country*">
                    {this.props.selectData.country
                      ? Object.keys(this.props.selectData.country).map(key => (
                          <Select.Option key={key} value={key}>
                            {this.props.selectData.country[key]}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Tag>Social</Tag>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('social.fb')}
                label="Facebook">
                {getFieldDecorator(
                  'social.fb',
                  {}
                )(<Input disabled={this.props.view} placeholder="Facebook" />)}
              </FormItem>
            </Col>{' '}
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('social.twitter')}
                label="Twitter">
                {getFieldDecorator(
                  'social.twitter',
                  {}
                )(<Input disabled={this.props.view} placeholder="Twitter" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('social.linkedin')}
                label="LinkedIn">
                {getFieldDecorator(
                  'social.linkedin',
                  {}
                )(<Input disabled={this.props.view} placeholder="LinkedIn" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('social.googleplus')}
                label="Google Plus">
                {getFieldDecorator(
                  'social.googleplus',
                  {}
                )(
                  <Input disabled={this.props.view} placeholder="Google Plus" />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row className="button-row">
            <Button style={{ marginRight: '8px' }} onClick={this.prev}>
              <Icon type="left" />
              Previous
            </Button>
            {!this.props.view && (
              <Button type="primary" htmlType="submit">
                Save
                <Icon type="right" />
              </Button>
            )}
          </Row>
        </Form>
      </StyledJobInfoForm>
    )
  }
}
export default Form.create()(AddressInfo)
