import React from 'react'

import './Icons.css'

const Icon = props => (
  <i {...props} className={`material-icon ${props.className || ''}`}>
    {props.type}
  </i>
)

export default Icon
