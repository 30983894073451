import React, { Component } from 'react'
import {
  Form,
  Modal,
  Dropdown,
  Menu,
  Select,
  Icon,
  Button,
  Row,
  Col,
  DatePicker,
  Input,
  Tag
} from 'antd'
import style from 'styled-components'
import Table from '../../utils/TableWrapper'
import { formUtil } from '../../utils/style-utils'
import moment from 'moment'
const FormItem = Form.Item
const dateFormat = 'DD/MM/YYYY'
const { TextArea } = Input

let StyledWorkExperienceForm = style.div`
  ${formUtil}
  & .ant-form-item{
    margin:.4em 1em;
  }
`
let StyleBottonModal = style.div`
  & .edit-button{
    border: none !important;
    &:hover{
      background:transparent !important;
      border: none !important;
    }
  }
  div{
    margin-top:1.2em;
  }
`
const columns = [
  {
    title: 'Job Title',
    dataIndex: 'jobTitle',
    priority: 1
  },
  {
    title: 'Company',
    dataIndex: 'company',
    priority: 2
  },
  {
    title: 'Location',
    dataIndex: 'location',
    priority: 3
  },
  {
    title: 'Skills',
    dataIndex: 'skills',
    priority: 4,
    render: text => text.join(', ')
  }
]
class WorkExperienceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      visible: false,
      columns,
      activeIndex: null
    }

    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleActionsMenu = this.handleActionsMenu.bind(this)
    this.reloadData = this.reloadData.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({ data: this.props.data })
    }
    let tableColumns = this.state.columns
    if (this.props.selectData.jobTitle) {
      tableColumns[0]['render'] = text => {
        const value = this.props.selectData.jobTitle.find(
          value => value._id === text
        )
        return value ? value.name : text
      }
    }
    const haveOperations = tableColumns.some(
      item => item.dataIndex === 'actions'
    )
    if (!this.props.view && !haveOperations) {
      tableColumns.push({
        title: 'Operations',
        dataIndex: 'actions',
        key: 'actions',
        priority: 1,
        render: (text, record, index) => {
          return (
            <Dropdown
              overlay={
                <Menu
                  onClick={({ item, key, keyPath }) => {
                    this.handleActionsMenu(key, record, index)
                  }}>
                  <Menu.Item key="1">
                    <Icon style={{ marginRight: '8px' }} type="edit" />
                    Edit
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Icon style={{ marginRight: '8px' }} type="delete" />
                    Delete
                  </Menu.Item>
                </Menu>
              }>
              <Button style={{ marginLeft: 8, border: 0 }}>
                <Icon type="bars" /> <Icon type="down" />
              </Button>
            </Dropdown>
          )
        }
      })
    }
    this.setState({ columns: tableColumns })
  }

  handleActionsMenu(key, record, index) {
    if (key === '1') {
      this.showModal()
      if (record.startPeriod) {
        record.startPeriod = moment(record.startPeriod)
      }
      if (record.endPeriod) {
        record.endPeriod = moment(record.endPeriod)
      }
      this.props.form.setFieldsValue(record)
      this.setState({ activeIndex: index })
    } else if (key === '2') {
      let { data } = this.state
      data.splice(index, 1)
      this.setState({ data })
      this.props.update('workExperience', data)
    }
  }
  showModal() {
    this.setState({
      visible: true
    })
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleCancel() {
    this.setState({ visible: false, activeIndex: null })
    this.props.form.resetFields()
  }

  reloadData() {}

  add(values) {
    let { data } = this.state
    if (this.state.activeIndex !== null) {
      data[this.state.activeIndex] = values
      this.setState({
        activeIndex: null
      })
    } else {
      data.push(values)
    }
    this.setState({ data })
    this.props.update('workExperience', data)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.add(values)
        this.handleCancel()
      }
    })
  }

  handleSelectChange = value => {}
  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <StyleBottonModal>
          <Tag>Work Experience</Tag>
          <div>
            {!this.props.view && (
              <Button icon="plus" type="primary" onClick={this.showModal}>
                Add
              </Button>
            )}
          </div>
        </StyleBottonModal>
        <Modal
          visible={this.state.visible}
          title="Work Experience Info"
          width={500}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}>
          <StyledWorkExperienceForm theme={this.props.theme}>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue('jobTitle')}
                    label="Job Title*">
                    {getFieldDecorator('jobTitle', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the Job Title'
                        }
                      ]
                    })(
                      <Select placeholder="Job Title*">
                        {this.props.selectData.jobTitle
                          ? this.props.selectData.jobTitle.map(jobTitle => (
                              <Select.Option
                                key={jobTitle._id}
                                value={jobTitle._id}>
                                {jobTitle.name}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue('company')}
                    label="Company*">
                    {getFieldDecorator('company', {
                      rules: [
                        { required: true, message: 'Please enter the company' }
                      ]
                    })(
                      <Input style={{ width: '100%' }} placeholder="Company*" />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue('location')}
                    label="Location*">
                    {getFieldDecorator('location', {
                      rules: [
                        { required: true, message: 'Please enter the Location' }
                      ]
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Location*"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue('description')}
                    label="Description">
                    {getFieldDecorator(
                      'description',
                      {}
                    )(
                      <TextArea
                        rows={2}
                        style={{ width: '100%' }}
                        placeholder="Description"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue('startPeriod')}
                    label="Start Period*">
                    {getFieldDecorator('startPeriod', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the start date'
                        }
                      ]
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder="Start Period*"
                        format={dateFormat}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue('endPeriod')}
                    label="End Period*">
                    {getFieldDecorator('endPeriod', {
                      rules: [
                        { required: true, message: 'Please enter the end date' }
                      ]
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder="End Period*"
                        format={dateFormat}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24}>
                  <FormItem
                    className={this.haveValue('skills')}
                    label="Skills*">
                    {getFieldDecorator('skills', {
                      rules: [
                        { required: true, message: 'Please enter the skills' }
                      ]
                    })(
                      <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Skills*">
                        <Select.Option
                          key="Communication Skills"
                          value="Communication Skills">
                          Communication Skills
                        </Select.Option>
                        <Select.Option key="Finance" value="Finance">
                          Finance
                        </Select.Option>
                        <Select.Option key="Accounting" value="Accounting">
                          Accounting
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Button type="primary" onClick={e => this.handleSubmit(e)}>
                Add
              </Button>
            </Form>
          </StyledWorkExperienceForm>
        </Modal>
        {this.state.data.length > 0 ? (
          <Table
            dataSource={this.state.data}
            columns={this.state.columns}
            rowKey="jobTitle"
            notSearch
          />
        ) : null}
      </div>
    )
  }
}

export default Form.create()(WorkExperienceForm)
