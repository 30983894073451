import React, { Component } from 'react'
import { Row, Col, Tabs, Divider, Tag, Spin, Modal, Button, Icon, Popconfirm, message } from 'antd'
import style from 'styled-components'
import { withRouter } from 'react-router-dom'
import { themes } from '../../utils/style-utils'
import Map from './MapComponent'
import request from '../../utils/requestWrapper'

const TabPane = Tabs.TabPane

let InfoColumn = props => (
  <Col xs={24} sm={12}>
    <div className="info-group">
      <Tag>{props.title}</Tag>
      <span>{props.info}</span>
    </div>
  </Col>
)

let StyledProfileModal = style.div`
  height:100%;
  & .ant-tabs-vertical{
    display:flex;
    flex-direction:row;
    height:100%;
  }
  & .map-margin{
      margin-bottom:30px;
  }
  & .ant-tabs-vertical>div.ant-tabs-content.ant-tabs-content-animated{
    padding:1.5em;
  }
  & .ant-tabs-tab-active.ant-tabs-tab{
    background-color:#FFFFFF;
  }
  & .ant-tabs-content.ant-tabs-content-animated{
    background-color:#FFFFFF;
    width:100%;
    overflow-y: scroll;
  }
  & .ant-tag{
    color:${props => themes[props.theme].textAndIcons};
    border-color:${props => themes[props.theme].primary};
    background:${props => themes[props.theme].primary};
    margin:.5em 0;
    min-width:120px;
  }
  & .info-group{
    margin:0 .5em;
    span {
      white-space: normal
    }
  }
  & .ant-tabs-bar{
    border:none;
  }
  & .profession-tabs .ant-tabs-tab-active.ant-tabs-tab{
    border-bottom:2px solid #1890ff;
  }
  & .big-avatar{
    width:100px;
    height:100px;
    margin:0;
    border-radius:0;
    margin-right:35px;
  }
  & .small-avatar{
    width:40px;
    height:40px;
    border-radius:50px;
    margin-left:1em;
  }
  & .image-label{
    font-weight:bold;
    margin-left:1em;
  }
  h3{
    font-weight:bold;
    font-size:20px;
  }
  & .profession-tabs{
    height:100% !important;
  }
  & .ant-row{
    margin:1em 0;
  }
  & .info-group{
    display:flex;
    flex-direction:column;
    span{
      font-weight:bold;
      font-size:14px;
    }
  }

  .images-files {
    display: flex;
    flex-direction: row;
    margin-right: 1em;
    flex-wrap: wrap;
    align-items: center;
  }
  .images-files .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    margin-bottom: 0 !important;
  }
  .delete-icon {
    cursor: pointer;
  }
  .images-files > div {
    margin-right: 1em;
  }

  .images-files img {
    height: 102px;
    width: 102px;
    margin-bottom: 1em;
   cursor: pointer;
  }
  @media screen and (min-width: 768px) {
    & .ant-tag{
      align-self:flex-start;
    }
  }
`

let StyledModalImage = style.div`
img {
   width: 100%;
}
`
class EmployeeProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: null,
      visibleImagesArray: false,
      visibleImageDocArray: false,
      loading: false,
      iconLoading: false
    }

    this.getImages = this.getImages.bind(this)
    this.showModal = this.showModal.bind(this)
    this.handleCancelModal = this.handleCancelModal.bind(this)
    this.isSendReport = this.loadFVPermissions()
  }

  loadFVPermissions() {
    const arPermissions = JSON.parse(localStorage.permissions);
    // resourceCode
    for (let i = 0; i < arPermissions.length; i++) {
      if (arPermissions[i].resourceCode == "LNDDO-LEAD" && (arPermissions[i].actions).indexOf("get-all") >= 0 &&
        (arPermissions[i].actions).indexOf("patch") >= 0) {
        return true;
      }
    }

    return false;
  }

  componentDidMount() {
    this.getImages(this.state.employee.images, 'imagesArray')
    this.getImages(this.state.employee.imageDoc, 'imageDocArray')
  }

  componentWillMount() {
    let { profile } = this.props.location.state
    this.setState({ employee: profile })
  }

  async getImages(array, key) {
    this.setState({ [`${key}Loading`]: true })
    let i = 0
    const newArray = []
    for (i = 0; i < array.length; i++) {
      let endPoint = `/my-drive/file/${array[i]}`
      const response = await request(endPoint)
      if (response.status === 200) {
        newArray.push({ id: response.data._id, url: response.data.url })
      }
    }
    this.setState({ [key]: newArray, [`${key}Loading`]: false })
  }

  showModal(images, url) {
    this.setState({
      [`visible${images}`]: true,
      [`${images}Url`]: url
    })
  }

  handleCancelModal(images) {
    this.setState({ [`visible${images}`]: false })
  }

  authorizeReport = async () => {
    this.setState({ iconLoading: true })
    let endPoint = `/lnddo-lead/${this.state.employee._id}/send-report`
    const response = await request(endPoint, 'POST');

    if (response.status == 200) {
      message.success(response.message)
    }
    else {
      message.error(response.message)
    }

    this.setState({ iconLoading: false })
    return;
  }

  render() {
    let {
      employee,
      imagesArray,
      imageDocArray,
      imagesArrayLoading,
      imageDocArrayLoading
    } = this.state
    const markers = [
      {
        ...employee,
        name: `${employee.customerName} ${employee.lastName}`
      }
    ]

    return (
      <StyledProfileModal theme={this.props.theme}>
        <Tabs
          tabBarStyle={{ border: 'none' }}
          defaultActiveKey="1"
          tabPosition={'right'}>
          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">PERSONAL INFO</span>
                <span className="show-mobile">PI</span>
              </div>
            }
            key="1">
            <Row className="flex-row">
              {(this.state.employee.status == 'awaiting' && this.isSendReport) ? (<Popconfirm
                title="Do you want to send report?"
                onConfirm={this.authorizeReport}
                onCancel={() => { return; }}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: '8px' }} type="primary" loading={this.state.iconLoading}>
                  <Icon type="caret-right" theme="filled" /> Send Report
                </Button>
              </Popconfirm>) : ''}
            </Row>
            <div style={{ height: '1500px' }}>
              <h3>PERSONAL INFORMATION</h3>
              <Row>
                <InfoColumn title="CUSTOMER NAME" info={employee.customerName} />
                <InfoColumn title="CIF NUMBER" info={employee.cifNumber} />
              </Row>
              <Row>
                <InfoColumn title="NAME OF BUSINESS" info={employee.nameOfBusiness} />
                <InfoColumn
                  title="ADDRESS LINE 1"
                  info={employee.address.line1}
                />
              </Row>
              <Row>
                <InfoColumn
                  title="ADDRESS LINE 2"
                  info={employee.address.line2}
                />
                <InfoColumn
                  title="ADDRESS LINE 3"
                  info={employee.address.line3}
                />
              </Row>
              <Row>
                <InfoColumn
                  title="EMIRATE"
                  info={employee.address.emirate}
                />
              </Row>
              <Row>
                {employee.images.length > 0 && <InfoColumn title="Images" />}
              </Row>
              <Row className="images-files">
                {imagesArrayLoading ? (
                  <Spin />
                ) : (
                  imagesArray &&
                  imagesArray.map(e => {
                    return (
                      <div>
                        <img
                          src={e.url}
                          onClick={() => this.showModal('ImagesArray', e.url)}
                          alt=""
                        />
                        <Modal
                          visible={this.state.visibleImagesArray}
                          width={350}
                          title="Images"
                          onOk={this.handleOk}
                          onCancel={() => this.handleCancelModal('ImagesArray')}
                          footer={null}>
                          <StyledModalImage>
                            <img src={this.state.ImagesArrayUrl} alt="" />
                          </StyledModalImage>
                        </Modal>
                      </div>
                    )
                  })
                )}
              </Row>
              <Row>
                {employee.imageDoc.length > 0 && (
                  <InfoColumn title="Images Docs" />
                )}
              </Row>
              <Row className="images-files">
                {imageDocArrayLoading ? (
                  <Spin />
                ) : (
                  imageDocArray &&
                  imageDocArray.map(e => {
                    return (
                      <div>
                        <img
                          src={e.url}
                          onClick={() => this.showModal('ImageDocArray', e.url)}
                          alt=""
                        />

                        <Modal
                          visible={this.state.visibleImageDocArray}
                          title="ImagesDoc"
                          onOk={this.handleOk}
                          onCancel={() =>
                            this.handleCancelModal('ImageDocArray')
                          }
                          footer={null}>
                          <StyledModalImage>
                            <img src={this.state.ImageDocArrayUrl} alt="" />
                          </StyledModalImage>
                        </Modal>
                      </div>
                    )
                  })
                )}
              </Row>
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">BUSINESS INFORMATION</span>
                <span className="show-mobile">BI</span>
              </div>
            }
            key="2">
            <h3>BUSINESS INFORMATION</h3>

            <Row>
              <InfoColumn
                title="NAME OF THE SHAREHOLDERS"
                info={employee.nameOfShareholder}
              />
              <InfoColumn
                title="NAME OF THE PERSON MET"
                info={employee.nameOfPersonMet}
              />
            </Row>
            <Row>
              <InfoColumn
                title="PERSON MET DESIGNATION"
                info={employee.desgOfPersonMet}
              />
              <InfoColumn
                title="MOBILE No. OF THE PERSON MET"
                info={employee.mobileOfPersonMet}
              />
            </Row>
            <Row>
              <InfoColumn
                title="LANDLINE NUMBER AVAILABLE"
                info={employee.isLandlineAvailable}
              />
              <InfoColumn
                title="LANDLINE NUMBER AVAILABLE (OTHERS)"
                info={employee.isLandlineAvailableOther}
              />
            </Row>
            <Row>
              <InfoColumn
                title="LANDLINE NUMBER OF THE BUSINESS"
                info={employee.businessLandline}
              />
              <InfoColumn
                title="WERE THERE COMPUTERS,MACHINES AND OTHER DEVICES OPERATING"
                info={employee.isMachinesAvailable}
              />
            </Row>
            <Row>
              <InfoColumn
                title="TYPE OF BUILDING FROM WHERE BUSINESS IS BEING OPERATED"
                info={employee.typeoFbuilding}
              />
              <InfoColumn
                title="OTHER TYPE OF BUILDING?"
                info={employee.typeoFbuildingOther}
              />
            </Row>
            <Row>
              <InfoColumn
                title="HOW DIFICULT WAS TO LOCATE THE PREMISES"
                info={employee.diffLocate}
              />
              <InfoColumn
                title="OTHER LOCATE THE PREMISES"
                info={employee.diffLocateOther}
              />
            </Row>
            <Row>
              <InfoColumn
                title="NUMBER OF EMPLOYEES, SEEN DURING VISIT"
                info={employee.noOfEmpSeen}
              />
              <InfoColumn
                title="TYPE OF BUSINESS OBSERVED"
                info={employee.typeOfBusinessObs}
              />
            </Row>
            <Row>
              <InfoColumn
                title=" OTHER TYPE OF BUSINESS OBSERVED"
                info={employee.typeOfBusinessObsOther}
              />
              <InfoColumn
                title="NUMBER OF CUSTOMERS SEEN DURING VISIT, IF ANY"
                info={employee.noOfCustSeen}
              />
            </Row>
            <Row>
              <InfoColumn
                title=" APPROX DAILY CUSTOMER FOOTFALL (IF RETAIL)"
                info={employee.apxDailyCustFootfall}
              />
              <InfoColumn
                title="APPROX AMOUNT DEALT IN CASH"
                info={employee.apxAmtDealtCash}
              />
            </Row>
            <Row>
              <InfoColumn
                title=" APPROX VALUE OF DIGITAL SALES - CARD, ONLINE ETC."
                info={employee.apxValDigSale}
              />
              <InfoColumn
                title="STOCK LEVELS / RAW MATERIAL OBSERVED DURING THE VISIT"
                info={employee.stockLevel}
              />
            </Row>
            <Row>
              <InfoColumn
                title=" OTHER STOCK LEVELS "
                info={employee.stockLevelOther}
              />
              <InfoColumn
                title="WAS THE PERSON MET ABLE TO EXPLAIN THE BUSINESS DETAILS "
                info={employee.personMetExplBusn}
              />
            </Row>
            <Row>
              <InfoColumn
                title=" OTHER EXPLAIN THE BUSINESS "
                info={employee.personMetExplBusnOther}
              />
            </Row>
          </TabPane>
          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">AGENT FEEBACK</span>
                <span className="show-mobile">AF</span>
              </div>
            }
            key="4">
            {employee.address && (
              <div style={{ height: 900 }}>
                <h3>AGENT FEEBACK</h3>
                <Row>
                  <InfoColumn
                    title="WAS THE SIGN BOARD PRESENT"
                    info={employee.signBoardPresent}
                  />
                  <InfoColumn
                    title="OTHER SIGN BOARD"
                    info={employee.signBoardPresentOther}
                  />
                </Row>
                <Row>
                  <InfoColumn
                    title="NEIGHBORHOOD FEEDBACK"
                    info={employee.neighborhoodFeedback}
                  />
                  <InfoColumn
                    title="OTHER FEEDBACK"
                    info={employee.neighborhoodFeedbackOther}
                  />
                </Row>
                <Row>
                  <InfoColumn
                    title="STATUS ON THE TRADE LICENSE"
                    info={employee.statusOfTradeLic}
                  />
                  <InfoColumn
                    title="OTHER STATUS ON THE TRADE LICENSE"
                    info={employee.statusOfTradeLicOther}
                  />
                </Row>
                <Row>
                  <InfoColumn
                    title="ANY OTHER COMPANIES OPERATING FROM THE SAME PREMISES "
                    info={employee.anyCmpOperating}
                  />
                  <InfoColumn
                    title="OTHER COMPANIES"
                    info={employee.anyCmpOperatingOther}
                  />
                </Row>
                <Row>
                  <InfoColumn
                    title="IF THE ABOVE IS YES, PLEASE GIVE THE NAME"
                    info={employee.anyCmpOperatingYesVal}
                  />
                  <InfoColumn
                    title="FEEDBACK"
                    info={employee.feedback}
                  />
                </Row>
                <Row>
                  <InfoColumn
                    title="COMMENTS2"
                    info={employee.comments2}
                  />
                  <InfoColumn
                    title="COMMENTS3"
                    info={employee.comments3}
                  />
                </Row>
                <Row>
                  <InfoColumn
                    title="LATITUDE"
                    info={employee.latitude}
                  />
                  <InfoColumn
                    title="LONGITUDE"
                    info={employee.longitude}
                  />
                </Row>
                <Row>
                  <Map markers={markers} className="map-margin" />
                </Row>
              </div>
            )}
          </TabPane>
        </Tabs>
      </StyledProfileModal>
    )
  }
}
export default withRouter(EmployeeProfile)
