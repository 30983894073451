import { css } from 'styled-components'

//Select here your preffer color palette, check https://www.materialpalette.com
let selectedColor =
  typeof localStorage.theme === 'string' ? localStorage.theme : 'AntPro'

const formUtil = css`
  & .ant-btn {
    border-radius: 15px;
  }
  & .ant-btn-primary {
    background-color: ${props => themes[props.theme].primary};
    border-color: ${props => themes[props.theme].primary};
    &:hover,
    &:active,
    &:focus {
      background-color: ${props => themes[props.theme].lightPrimary};
      border-color: ${props => themes[props.theme].lightPrimary};
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16),
        0 -1px 2px 0 rgba(0, 0, 0, 0.1);
    }
  }
  input,
  textarea,
  span.ant-calendar-picker,
  & .ant-select-selection {
    border-radius: 0 !important;
    width: 100%;
    &:hover,
    &:active,
    &:focus {
      outline: none;
      border-color: ${props => themes[props.theme].primary} !important;
    }
  }
  & .ant-select {
    height: 32px;
  }
  & .ant-form-item-required:before {
    display: none;
  }
  & .ant-form-item {
    margin-bottom: 2px;
  }
  & .ant-checkbox .ant-checkbox-inner {
    border-color: 1px solid #d9d9d9;
  }
  & .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${props => themes[props.theme].primary};
  }
  & .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => themes[props.theme].primary};
    border-color: ${props => themes[props.theme].primary};
  }
  & .ant-form-item-label {
    height: 15px;
    line-height: 1;
  }
  & .ant-form label {
    font-size: 11px;
    opacity: 0.0000001;
  }
  & .with-label label {
    opacity: 1;
  }
  span.ant-calendar-picker,
  & .ant-input-number {
    width: 100%;
  }
  & .ant-switch {
    background-color: ${props => themes[props.theme].lightPrimary};
  }
  & .ant-switch-checked {
    background-color: ${props => themes[props.theme].primary};
  }
  button {
    margin-top: 20px;
  }
`
const filterUtil = css`
  & .filter-icon {
    font-size: 22px;
    float: left;
    cursor: pointer;
    margin-bottom: 10px;
  }
  div.ant-tag {
    margin: 0 0 10px 0;
  }
  & .ant-btn {
    margin: 20px 30px 0 0;
  }
  .ant-col-xs-24.ant-col-sm-12 {
    display: flex;
  }
  & .ant-col-xs-24.ant-col-sm-6,
  & .ant-col-xs-24.ant-col-sm-4 {
    margin-bottom: 10px;
  }
  @media screen and (min-width: 768px) {
    & .ant-col-xs-24.ant-col-sm-6,
    & .ant-col-xs-24.ant-col-sm-4 {
      padding-right: 30px;
      margin-bottom: 0;
    }
    & .ant-btn {
      margin: 0 30px 0 0;
    }
  }
`
let themes = {
  Red: {
    darkPrimary: '#D32F2F',
    lightPrimary: '#FFCDD2',
    primary: '#F44336',
    textAndIcons: '#FFFFFF',
    accentColor: '#FF5252',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Pink: {
    darkPrimary: '#C2185B',
    lightPrimary: '#F8BBD0',
    primary: '#E91E63',
    textAndIcons: '#FFFFFF',
    accentColor: '#FF5252',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Purple: {
    darkPrimary: '#7B1FA2',
    lightPrimary: '#E1BEE7',
    primary: '#9C27B0',
    textAndIcons: '#FFFFFF',
    accentColor: '#7C4DFF',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  DeepPurple: {
    darkPrimary: '#512DA8',
    lightPrimary: '#D1C4E9',
    primary: '#673AB7',
    textAndIcons: '#FFFFFF',
    accentColor: '#7C4DFF',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Indigo: {
    darkPrimary: '#303F9F',
    lightPrimary: '#C5CAE9',
    primary: '#3F51B5',
    textAndIcons: '#FFFFFF',
    accentColor: '#7C4DFF',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Blue: {
    darkPrimary: '#1976D2',
    lightPrimary: '#BBDEFB',
    primary: '#2196F3',
    textAndIcons: '#FFFFFF',
    accentColor: '#03A9F4',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  LightBlue: {
    darkPrimary: '#0288D1',
    lightPrimary: '#B3E5FC',
    primary: '#03A9F4',
    textAndIcons: '#FFFFFF',
    accentColor: '#03A9F4',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Cyan: {
    darkPrimary: '#0097A7',
    lightPrimary: '#B2EBF2',
    primary: '#00BCD4',
    textAndIcons: '#FFFFFF',
    accentColor: '#009688',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Teal: {
    darkPrimary: '#00796B',
    lightPrimary: '#B2DFDB',
    primary: '#009688',
    textAndIcons: '#FFFFFF',
    accentColor: '#009688',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Green: {
    darkPrimary: '#388E3C',
    lightPrimary: '#C8E6C9',
    primary: '#4CAF50',
    textAndIcons: '#FFFFFF',
    accentColor: '#009688',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  LightGreen: {
    darkPrimary: '#689F38',
    lightPrimary: '#DCEDC8',
    primary: '#8BC34A',
    textAndIcons: '#212121',
    accentColor: '#8BC34A',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Lime: {
    darkPrimary: '#AFB42B',
    lightPrimary: '#F0F4C3',
    primary: '#CDDC39',
    textAndIcons: '#212121',
    accentColor: '#8BC34A',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Yellow: {
    darkPrimary: '#FBC02D',
    lightPrimary: '#FFF9C4',
    primary: '#FFEB3B',
    textAndIcons: '#212121',
    accentColor: '#FFEB3B',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Amber: {
    darkPrimary: '#FFA000',
    lightPrimary: '#FFECB3',
    primary: '#FFC107',
    textAndIcons: '#212121',
    accentColor: '#FFC107',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Orange: {
    darkPrimary: '#F57C00',
    lightPrimary: '#FFE0B2',
    primary: '#FF9800',
    textAndIcons: '#212121',
    accentColor: '#FFC107',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  DeepOrange: {
    darkPrimary: '#E64A19',
    lightPrimary: '#FFCCBC',
    primary: '#FF5722',
    textAndIcons: '#FFFFFF',
    accentColor: '#FF5722',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },

  Brown: {
    darkPrimary: '#5D4037',
    lightPrimary: '#D7CCC8',
    primary: '#795548',
    textAndIcons: '#FFFFFF',
    accentColor: '#795548',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Grey: {
    darkPrimary: '#616161',
    lightPrimary: '#F5F5F5',
    primary: '#9E9E9E',
    textAndIcons: '#212121',
    accentColor: '#9E9E9E',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  BlueGrey: {
    darkPrimary: '#455A64',
    lightPrimary: '#CFD8DC',
    primary: '#607D8B',
    textAndIcons: '#FFFFFF',
    accentColor: '#607D8B',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  Pinterest: {
    darkPrimary: '#122d71',
    lightPrimary: '#91abee',
    primary: '#121469',
    textAndIcons: '#FFFFFF',
    accentColor: '#03A9F4',
    primaryText: '#212121',
    secondaryText: '#757575',
    dividerColor: '#BDBDBD'
  },
  AntPro: {
    darkPrimary: '#000c17',
    lightPrimary: '#1890ff',
    primary: '#001529',
    textAndIcons: '#FFFFFF',
    secondaryText: '#CCCCCC'
  }
}
export default themes[selectedColor]
export { themes, selectedColor, formUtil, filterUtil }
