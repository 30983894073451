import React, { Component } from 'react'
import { Table, Input, Row, Col } from 'antd'
import styled from 'styled-components'
import theme from './style-utils'
let moment = require('moment')

const { Search } = Input

let StyledTable = styled.div`
  & .ant-row {
    margin-bottom: 1em;
  }
  & .ant-btn {
    margin: 0;
  }
  .ant-pagination-options-quick-jumper > input {
    width: 50px !important;
  }
  ul.ant-pagination.ant-table-pagination {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
  & .ant-table-tbody > tr > td {
    min-width: 70px;
  }
  ul.ant-pagination.ant-table-pagination {
    width: 100%;
    text-align: center;
  }
  & .ant-pagination-prev .ant-pagination-item-link,
  & .ant-pagination-next .ant-pagination-item-link,
  & .ant-pagination-item {
    color: ${theme.lightPrimary};
    background-color: #ffffff;
  }
  & .ant-pagination-item a {
    color: ${theme.lightPrimary};
  }
  & .ant-pagination-jump-prev,
  & .ant-pagination-jump-next {
    &:focus,
    &:hover {
      &:after {
        color: ${theme.lightPrimary};
      }
    }
    &:after {
      color: ${theme.lightPrimary};
    }
  }
  & .ant-table-row-expand-icon {
    background: #ffffff;
    border: 1px solid ${theme.lightPrimary};
    color: ${theme.lightPrimary};
  }
  & .ant-pagination-item-active,
  & .ant-pagination-item:focus {
    &:hover,
    &:focus {
      background: ${theme.darkPrimary};
      a {
        color: #ffffff;
      }
    }
    border-color: gray;
    a {
      color: #ffffff;
    }
  }
  & .ant-pagination-item:hover,
  & .ant-pagination-next:hover,
  & .ant-pagination-next:focus,
  & .ant-pagination-prev:hover,
  & .ant-pagination-prev:focus {
    border-color: gray;
    a {
      border-color: gray;
      color: gray;
    }
  }

  & .anticon.anticon-caret-down,
  & .anticon.anticon-caret-up {
    &::after,
    &::before {
      font-size: 18px;
    }
    &:hover {
      color: #808080;
    }
  }

  @media (min-width: 768px) {
    ul.ant-pagination.ant-table-pagination {
      width: 100%;
      text-align: right;
    }
  }
`

export default class TableWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      component: null,
      width: window.innerWidth,
      tableProps: null,
      viewColumns: [],
      columns: [],
      dataSource: [],
      priorities: Array(this.props.columns.length).fill(1)
    }
    this.hiddenColumns = this.hiddenColumns.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.changeHiddenColumns = this.changeHiddenColumns.bind(this)
    this.expandComponent = this.expandComponent.bind(this)
    this.changeData = this.changeData.bind(this)
  }

  componentWillMount() {
    let tableProps = Object.assign({}, this.props)
    let columns = this.props.columns
    let dataSource = this.props.dataSource
    let loading = this.props.loading
    let pagination = this.props.pagination

    this.setState({ tableProps, columns, dataSource, loading, pagination })
    if (!this.props.loading) {
      let priorities = this.props.columns.map((column, i) => {
        return typeof column.priority !== 'undefined'
          ? column.priority
          : this.props.columns.length
      })
      this.setState({
        viewColumns: this.hiddenColumns(priorities),
        priorities: priorities
      })
    }
    delete tableProps['columns']
    delete tableProps['dataSource']
    delete tableProps['loading']
    delete tableProps['pagination']
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading) {
      this.setState({ loading: nextProps.loading })
    }
    if (this.props.dataSource !== nextProps.dataSource) {
      this.setState({ dataSource: nextProps.dataSource })
    }
    if (this.props !== nextProps) {
      if (this.props.columns !== nextProps.columns) {
        this.setState({ columns: nextProps.columns })
      }
      if (this.props.pagination !== nextProps.pagination) {
        this.setState({ pagination: nextProps.pagination })
      }
      let priorities = nextProps.columns.map((column, i) => {
        return typeof column.priority !== 'undefined'
          ? column.priority
          : nextProps.columns.length
      })
      this.setState({
        viewColumns: this.hiddenColumns(priorities),
        priorities: priorities
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  changeData(value) {
    if (value !== '') {
      let newData = this.props.dataSource.filter(record => {
        return Object.keys(record).some((item, i) => {
          let val = `${record[item]}`.toUpperCase()
          return val.indexOf(value.toUpperCase()) !== -1
        })
      })
      this.setState({ dataSource: newData })
    } else if (value === '') {
      this.setState({ dataSource: this.props.dataSource })
    }
  }
  /*   changeData(value) {
    const reg = new RegExp(value, 'gi')
    if (value !== '') {
      let newData = this.props.dataSource.filter(record => {
        return Object.keys(record).some(key => {
          let haveColumn = this.props.columns.find(column => {
            return column.dataIndex === key
          })
          if (haveColumn) {
            return record[key].toString().match(reg)
          }
          return null
        })
      })
      this.setState({ dataSource: newData })
    } else if (value === '') {
      this.setState({ dataSource: this.props.dataSource })
    }
  } */

  handleResize() {
    this.changeHiddenColumns()
    this.setState({
      width: window.innerWidth
    })
  }

  hiddenColumns(priorities) {
    let width = window.innerWidth - 64
    return priorities.map(priority => {
      return !(
        (width <= 350 && priority <= 1) ||
        (width <= 414 && priority <= 2) ||
        (width > 414 && width <= 540 && priority <= 3) ||
        (width > 540 && width <= 768 && priority <= 4) ||
        (width > 768 && width <= 992 && priority <= 5) ||
        (width > 992 && width <= 1100 && priority <= 6) ||
        (width > 1100 && width <= 1200 && priority <= 7) ||
        (width > 1200 && width <= 1300 && priority <= 8) ||
        (width > 1300 && width <= 1400 && priority <= 9) ||
        (width > 1400 && width <= 1500 && priority <= 10) ||
        (width > 1500 && width <= 1600 && priority <= 11) ||
        (width > 1600 && width <= 1700 && priority <= 12) ||
        (width > 1700 && width <= 1800 && priority <= 13) ||
        (width > 1800 && width <= 1900 && priority <= 15) ||
        width > 1900
      )
    })
  }

  expandComponent(record) {
    let values = []
    this.state.columns.forEach((column, i) => {
      if (
        this.state.viewColumns[i] === true &&
        typeof record[column.dataIndex] === 'string'
      ) {
        values.push(
          <div
            key={column.dataIndex + i}
            style={{ textAlign: 'left', marginLeft: '-40px' }}>
            <div style={{ fontWeight: 'bold' }}>{column.title}:</div>
            <div />
            {column.dataIndex !== 'createdAt'
              ? record[column.dataIndex]
              : moment(record[column.dataIndex]).format('DD-MM-YYYY h:mm')}
          </div>
        )
      }
    })
    return <div>{values}</div>
  }

  changeHiddenColumns() {
    let viewColumns = this.hiddenColumns(this.state.priorities)
    this.setState({
      viewColumns
    })
  }

  render() {
    let { dataSource, loading, pagination } = this.state
    let columns = []
    this.state.columns.forEach((column, i) => {
      if (this.state.viewColumns[i] === false) {
        columns.push(column)
      }
    })

    return (
      <StyledTable>
        <Row>
          {this.props.notSearch ? null : (
            <Col xs={24} sm={{ span: 4 }}>
              <Search
                placeholder="Search"
                onChange={e => this.changeData(e.target.value)}
              />
            </Col>
          )}
          <Col xs={24} sm={{ span: 4, offset: 16 }}>
            {this.props.component}
          </Col>
        </Row>
        <Row>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={pagination}
            expandedRowRender={
              this.state.width <= 768 &&
              dataSource &&
              dataSource.length > 0 &&
              this.state.viewColumns.indexOf(true) !== -1
                ? record => this.expandComponent(record)
                : null
            }
            loading={loading}
            {...this.state.tableProps}
          />
        </Row>
      </StyledTable>
    )
  }
}
