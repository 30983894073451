import React, { Component } from 'react'
import style from 'styled-components'
import { Form, Icon, Select, Row, Col, Button, Input } from 'antd'

const FormItem = Form.Item

const StyledJobInfoForm = style.div`
  h5{
    margin-top:1em;
  }
`
class LnddoBusinessInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filled: false,
      Verified: false,
      branch: false,
      showOtherSector: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.prev = this.prev.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.form.setFieldsValue(this.props.data)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && !this.state.filled) {
      this.props.form.setFieldsValue(nextProps.data)
      this.setState({
        filled: true
      })
    }
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleChange = (e, value) => {
    if (e === 'verified') {
      if (value === 'No') {
        this.setState({
          Verified: true
        })
      } else {
        this.setState({
          Verified: false
        })
      }
    }
    if (e === 'hasBranch') {
      if (value === 'Yes') {
        this.setState({
          branches: true
        })
      } else {
        this.setState({
          branches: false
        })
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { getFieldValue } = this.props.form
        let newValues = { ...values }
        /* if (getFieldValue('sector.name') !== 'Others') {
          newValues['sector']['other'] = ''
        }
        if (getFieldValue('personMet.type') !== 'Others') {
          newValues['personMet']['name'] = ''
        }
        if (getFieldValue('personMet.designation') !== 'Others') {
          newValues['personMet']['personDesignationOthers'] = ''
        }
        if (getFieldValue('officeLandlineVerified.verified') !== 'No') {
          newValues['officeLandlineVerified']['reason'] = undefined
        } */
        this.props.onSubmit(newValues)
      }
    })
  }

  prev() {
    this.props.onPrev()
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form

    return (
      <StyledJobInfoForm>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('nameOfShareholder')}
                label="Name of the Shareholders*">
                {getFieldDecorator('nameOfShareholder', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input Name of the Shareholders'
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Name of the Shareholders*"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('nameOfPersonMet')}
                label="Name of the person met">
                {getFieldDecorator('nameOfPersonMet', {})(
                  <Input
                    disabled={this.props.view}
                    placeholder="Name of the person met"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('desgOfPersonMet')}
                label="Person Met Designation">
                {getFieldDecorator('desgOfPersonMet', {})(
                  <Input
                    disabled={this.props.view}
                    placeholder="Person Met Designation"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('mobileOfPersonMet')}
                label="Mobile No. of the person met">
                {getFieldDecorator('mobileOfPersonMet', {})(
                  <Input
                    disabled={this.props.view}
                    placeholder="Mobile No. of the person met"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('isLandlineAvailable')}
                label="Landline number available*">
                {getFieldDecorator('isLandlineAvailable', {
                  rules: [
                    { required: true, message: 'Please select the Select Landline Availability' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select Landline Availability*">
                    <Select.Option key={'Yes'} value={'Yes'}>
                      Yes
                    </Select.Option>
                    <Select.Option key={'No'} value={'No'}>
                      No
                    </Select.Option>
                    <Select.Option key={'Other'} value={'Other'}>
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('isLandlineAvailableOther')}
                label="Other number available ">
                {getFieldDecorator('isLandlineAvailableOther', {
                  rules: [
                    {
                      required: getFieldValue('isLandlineAvailable') === 'Other'
                        ? true
                        : false,
                      message: "Other number available"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other number available"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('businessLandline')}
                label="Landline number. of the business*">
                {getFieldDecorator('businessLandline', {
                  rules: [
                    {
                      required: true,
                      message: "Please input Landline no. of the business"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Landline no. of the business*"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('isMachinesAvailable')}
                label="Were there comp.'s,machines and other dev.'s operating*">
                {getFieldDecorator('isMachinesAvailable', {
                  rules: [
                    {
                      required: true,
                      message: "Please input Were there comp.'s, machines and other dev.'s operating?"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Were there comp.'s, machines and other dev.'s operating?*"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('typeoFbuilding')}
                label="Type of building. from where biz. is being operated">
                {getFieldDecorator('typeoFbuilding', {})(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Type of bldg. from where biz. is being operated">
                    <Select.Option key="Retail (Mall, shopping center)" value="Retail (Mall, shopping center)">
                      Retail (Mall, shopping center)
                    </Select.Option>
                    <Select.Option key="Business Center" value="Business Center">
                      Business Center
                    </Select.Option>
                    <Select.Option key="Commercial" value="Commercial">
                      Commercial
                    </Select.Option>
                    <Select.Option key="Residential Building (other than retail under the bldg)" value="Residential Building (other than retail under the bldg)">
                      Residential Building (other than retail under the bldg)
                    </Select.Option>
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('typeoFbuildingOther')}
                label="Other type of building">
                {getFieldDecorator('typeoFbuildingOther', {
                  rules: [
                    {
                      required: getFieldValue('typeoFbuilding') === 'Other'
                        ? true
                        : false,
                      message: "Other type of building"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other type of building"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('diffLocate')}
                label="How difficult was to locate the premises?*">
                {getFieldDecorator('diffLocate', {
                  rules: [
                    { required: true, message: 'Please select the How difficult was to locate the premises?' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="How difficult was to locate the premises?*">
                    <Select.Option key="Easy" value="Easy">
                      Easy
                    </Select.Option>
                    <Select.Option key="Difficult" value="Difficult">
                      Difficult
                    </Select.Option>
                    <Select.Option key="Was located after checking locally" value="Was located after checking locally">
                      Was located after checking locally
                    </Select.Option>
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('diffLocateOther')}
                label="Other locate the premises ">
                {getFieldDecorator('diffLocateOther', {
                  rules: [
                    {
                      required: getFieldValue('diffLocate') === 'Other'
                        ? true
                        : false,
                      message: "Other locate the premises"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other locate the premises"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('noOfEmpSeen')}
                label="Number of employees. seen during visit*">
                {getFieldDecorator('noOfEmpSeen', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the Number of employees. seen during visit'
                    }
                  ]
                })(<Input placeholder="Number of employees. seen during visit" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('typeOfBusinessObs')}
                label="Type of business observed*">
                {getFieldDecorator('typeOfBusinessObs', {
                  rules: [
                    { required: true, message: 'Please select the Type of business observed' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Type of business observed*">
                    <Select.Option key="Trading" value="Trading">
                      Trading
                    </Select.Option>
                    <Select.Option key="Retail" value="Retail">
                      Retail
                    </Select.Option>
                    <Select.Option key="Manufacturing" value="Manufacturing">
                      Manufacturing
                    </Select.Option>
                    <Select.Option key="Services" value="Services">
                      Services
                    </Select.Option>
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('typeOfBusinessObsOther')}
                label="Other type of business">
                {getFieldDecorator('typeOfBusinessObsOther', {
                  rules: [
                    {
                      required: getFieldValue('typeOfBusinessObs') === 'Other'
                        ? true
                        : false,
                      message: "Other type of business"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other type of business"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('noOfCustSeen')}
                label="Number of Customer. seen during visit, if any">
                {getFieldDecorator('noOfCustSeen', {})(
                  <Input
                    disabled={this.props.view}
                    placeholder="Number of Customer. seen during visit, if any"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('apxDailyCustFootfall')}
                label="Approx daily customer footfall (If retail)">
                {getFieldDecorator('apxDailyCustFootfall', {})(
                  <Input
                    disabled={this.props.view}
                    placeholder="Approx daily customer footfall (If retail)"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('apxAmtDealtCash')}
                label="Approx Amount Dealt In Cash">
                {getFieldDecorator('apxAmtDealtCash', {})(
                  <Input
                    disabled={this.props.view}
                    placeholder="Approx Amount Dealt In Cash"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('apxValDigSale')}
                label="Approx Value of Digital Sales - Card, Online Etc">
                {getFieldDecorator('apxValDigSale', {})(
                  <Input
                    disabled={this.props.view}
                    placeholder="Approx Value of Digital Sales - Card, Online Etc"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('stockLevel')}
                label="Stock levels/raw material observed during the visit">
                {getFieldDecorator('stockLevel', {})(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Stock levels / raw material observed during the visit">
                    <Select.Option key="Average" value="Average">
                      Average
                    </Select.Option>
                    <Select.Option key="Low" value="Low">
                      Low
                    </Select.Option>
                    <Select.Option key="well stocked" value="well stocked">
                      well stocked
                    </Select.Option>
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('stockLevelOther')}
                label="Other stock levels">
                {getFieldDecorator('stockLevelOther', {
                  rules: [
                    {
                      required: getFieldValue('stockLevel') === 'Other'
                        ? true
                        : false,
                      message: "Other stock levels"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other stock levels"
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('personMetExplBusn')}
                label="Was the person met able to explain the business details">
                {getFieldDecorator('personMetExplBusn', {})(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Was the person met able to explain the business details">
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                colon={false}
                className={this.haveValue('personMetExplBusnOther')}
                label="Other to explain the business details">
                {getFieldDecorator('personMetExplBusnOther', {
                  rules: [
                    {
                      required: getFieldValue('personMetExplBusn') === 'Other'
                        ? true
                        : false,
                      message: "Other to explain the business details"
                    }
                  ]
                })(
                  <Input
                    disabled={this.props.view}
                    placeholder="Other to explain the business details"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row className="button-row">
            <Button style={{ marginRight: '8px' }} onClick={this.prev}>
              <Icon type="left" />
              Previous
            </Button>
            <Button type="primary" htmlType="submit">
              Next
              <Icon type="right" />
            </Button>
          </Row>
        </Form>
      </StyledJobInfoForm>
    )
  }
}
export default Form.create()(LnddoBusinessInfo)
