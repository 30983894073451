import React, { Component } from 'react'
import { Avatar, Row, Col, Tabs, Divider, Tag } from 'antd'
import style from 'styled-components'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { themes } from '../../utils/style-utils'
const TabPane = Tabs.TabPane

let InfoColumn = props => (
  <Col xs={24} sm={12}>
    <div className="info-group">
      <Tag>{props.title}</Tag>
      <span>{props.info}</span>
    </div>
  </Col>
)

let StyledProfileModal = style.div`
  height:100%;
  & .ant-tabs-vertical{
    display:flex;
    flex-direction:row;
    height:100%;  
  }
  & .ant-tabs-vertical>div.ant-tabs-content.ant-tabs-content-animated{
    padding:1.5em;      
  }
  & .ant-tabs-tab-active.ant-tabs-tab{
    background-color:#FFFFFF;
  }
  & .ant-tabs-content.ant-tabs-content-animated{
    background-color:#FFFFFF;
    width:100%;
  }
  & .ant-tag{
    color:${props => themes[props.theme].textAndIcons};
    border-color:${props => themes[props.theme].primary};
    background:${props => themes[props.theme].primary};
    margin:.5em 0;
    min-width:120px;
  }
  & .info-group{
    margin:0 .5em;
  }
  & .ant-tabs-bar{
    border:none;
  }
  & .profession-tabs .ant-tabs-tab-active.ant-tabs-tab{
    border-bottom:2px solid #1890ff;
  }
  & .big-avatar{
    width:100px;
    height:100px;
    margin:0;
    border-radius:0;
    margin-right:35px;
  }
  & .small-avatar{
    width:40px;
    height:40px;
    border-radius:50px;
    margin-left:1em;
  }
  & .image-label{
    font-weight:bold;
    margin-left:1em;
  }
  h3{
    font-weight:bold;
    font-size:20px;
  }
  & .profession-tabs{
    height:100% !important;
  }
  & .ant-row{
    margin:1em 0;
  }
  & .info-group{
    display:flex;
    flex-direction:column;
    span{
      font-weight:bold;
      font-size:14px;
    }
  }
  @media screen and (min-width: 768px) {
    & .ant-tag{
      align-self:flex-start;
    }
  }
`
class EmployeeProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: null
    }
  }

  componentWillMount() {
    let { profile } = this.props.location.state
    this.setState({ employee: profile })
  }

  render() {
    let { employee } = this.state
    return (
      <StyledProfileModal theme={this.props.theme}>
        <Tabs
          tabBarStyle={{ border: 'none' }}
          defaultActiveKey="1"
          tabPosition={'right'}>
          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">PERSONAL INFO</span>
                <span className="show-mobile">PI</span>
              </div>
            }
            key="1">
            <Row className="flex-row">
              {employee.displayPic ? (
                <Avatar
                  className="big-avatar flex-center"
                  size="large"
                  src={
                    employee.displayPic ? employee.displayPic.original : null
                  }
                />
              ) : (
                <Avatar
                  className="big-avatar flex-center"
                  size="large"
                  icon="user"
                />
              )}
              {employee.displayPic ? (
                <div className="flex-row flex-between show-desktop">
                  <Avatar
                    className="small-avatar flex-center"
                    size="small"
                    src={
                      employee.displayPic ? employee.displayPic.original : null
                    }
                  />
                  <span className="image-label flex-center">Preview</span>
                </div>
              ) : (
                <div className="flex-row flex-between show-desktop">
                  <span className="image-label">Preview</span>
                  <Avatar
                    className="small-avatar flex-center"
                    size="small"
                    icon="user"
                  />
                </div>
              )}
            </Row>
            <h3>PERSONAL INFORMATION</h3>
            <Row>
              <InfoColumn title="FIRST NAME" info={employee.firstName} />
              <InfoColumn title="LAST NAME" info={employee.lastName} />
            </Row>
            <Row>
              <InfoColumn title="EMAIL" info={employee.email} />
              <InfoColumn
                title="PERSONAL EMAIL"
                info={employee.personalEmail}
              />
            </Row>
            <Row>
              <InfoColumn
                title="CONTACT NUMBER 1"
                info={employee.contactNumber1}
              />
              {employee.contactNumber2 && (
                <InfoColumn
                  title="CONTACT NUMBER 2"
                  info={employee.contactNumber2}
                />
              )}
            </Row>
            <Row>
              <InfoColumn title="GENDER" info={employee.gender} />
              <InfoColumn
                title="DATE OF BIRTH"
                info={moment(employee.dateOfBirth).format('DD-MM-YYYY h:mm')}
              />
            </Row>
            <Row>
              <InfoColumn title="NATIONALITY" info={employee.nationality} />
              {employee.marital && (
                <InfoColumn title="MARITAL" info={employee.marital} />
              )}
            </Row>
          </TabPane>
          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">JOB INFO</span>
                <span className="show-mobile">JI</span>
              </div>
            }
            key="2">
            <h3>JOB INFORMATION</h3>
            <Row>
              <InfoColumn
                title="DATE OF JOIN"
                info={moment(employee.dateOfJoin).format('DD-MM-YYYY h:mm')}
              />
              {employee.branch && (
                <InfoColumn title="BRANCH" info={employee.branch.name} />
              )}
            </Row>
            <Row>
              {employee.department && (
                <InfoColumn
                  title="DEPARTMENT"
                  info={employee.department.name}
                />
              )}
              {employee.jobTitle && (
                <InfoColumn title="JOB TITLE" info={employee.jobTitle.name} />
              )}
            </Row>
            <Row>
              {employee.manager && (
                <InfoColumn
                  title="MANAGER"
                  info={`${employee.manager.firstName} ${employee.manager.lastName}`}
                />
              )}
              {employee.teamLead && (
                <InfoColumn
                  title="TEAM LEAD"
                  info={`${employee.manager.firstName} ${employee.manager.lastName}`}
                />
              )}
            </Row>
            <Row>
              {employee.trainer && (
                <InfoColumn
                  title="TRAINER"
                  info={`${employee.manager.firstName} ${employee.manager.lastName}`}
                />
              )}
              {employee.hiredBy && (
                <InfoColumn
                  title="HIRED BY"
                  info={`${employee.manager.firstName} ${employee.manager.lastName}`}
                />
              )}
            </Row>
            <Row>
              {employee.referedBy && (
                <InfoColumn
                  title="REFERED BY"
                  info={`${employee.manager.firstName} ${employee.manager.lastName}`}
                />
              )}
            </Row>
            {employee.visaDetials && (
              <div>
                <Divider />
                <h3>VISA DETAILS</h3>
                <Row>
                  <InfoColumn
                    title="JOB TITLE"
                    info={employee.visaDetials.jobTitle}
                  />
                  <InfoColumn
                    title="SPONSER"
                    info={employee.visaDetials.visaSponser}
                  />
                </Row>
                <Row>
                  <InfoColumn
                    title="START DATE"
                    info={moment(employee.visaDetials.visaStartDate).format(
                      'DD-MM-YYYY h:mm'
                    )}
                  />
                  <InfoColumn
                    title="END DATE"
                    info={moment(employee.visaDetials.visaEndDate).format(
                      'DD-MM-YYYY h:mm'
                    )}
                  />
                </Row>
              </div>
            )}

            {employee.identityDetails && employee.identityDetails.length > 0 && (
              <TabPane tab="Education" key="3">
                <h3>IDENTITY DETAILS</h3>
                <Tabs
                  defaultActiveKey="0"
                  tabPosition="horizontal"
                  style={{ height: 220 }}
                  className="profession-tabs">
                  {employee.identityDetails.map((identity, i) => (
                    <TabPane tab={`Identity ${i + 1}`} key={i}>
                      <Row>
                        <InfoColumn title="NAME" info={identity.identityName} />
                        <InfoColumn title="NUMBER" info={identity.identityNo} />
                      </Row>
                    </TabPane>
                  ))}
                </Tabs>
              </TabPane>
            )}
          </TabPane>
          {(employee.education && employee.education.length > 0) ||
            (employee.workExperience && employee.workExperience.length > 0 && (
              <TabPane
                tab={
                  <div className="tab-title">
                    <span className="show-desktop">PROFESION INFO</span>
                    <span className="show-mobile">EI</span>
                  </div>
                }
                key="3">
                {employee.education && employee.education.length > 0 && (
                  <div>
                    <h3>EDUCATION</h3>
                    <Tabs
                      defaultActiveKey="0"
                      tabPosition="horizontal"
                      style={{ height: 220 }}
                      className="profession-tabs">
                      {employee.education.map((education, i) => (
                        <TabPane tab={`Education ${i + 1}`} key={i}>
                          <Row>
                            <InfoColumn title="LEVEL" info={education.level} />
                            <InfoColumn
                              title="SPECIALIZATION"
                              info={education.specialization}
                            />
                          </Row>
                          <Row>
                            <InfoColumn
                              title="UNIVERSITY"
                              info={education.university}
                            />
                            <InfoColumn
                              title="LOCATION"
                              info={education.location}
                            />
                          </Row>
                          <Row>
                            <InfoColumn title="SCORE" info={education.score} />
                            {education.description && (
                              <InfoColumn
                                title="DESCRIPTION"
                                info={education.description}
                              />
                            )}
                          </Row>
                          <Row>
                            <InfoColumn
                              title="START PERIOD"
                              info={moment(education.startPeriod).format(
                                'DD-MM-YYYY h:mm'
                              )}
                            />
                            <InfoColumn
                              title="END PERIOD"
                              info={moment(education.endPeriod).format(
                                'DD-MM-YYYY h:mm'
                              )}
                            />
                          </Row>
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                )}
                {employee.workExperience && employee.workExperience.length > 0 && (
                  <div>
                    <Divider />
                    <h3>WORK EXPERIENCE</h3>
                    <Tabs
                      defaultActiveKey="0"
                      tabPosition="horizontal"
                      className="profession-tabs">
                      {employee.workExperience.map((workExperience, i) => (
                        <TabPane tab={`Work Experience ${i + 1}`} key={i}>
                          <Row>
                            <InfoColumn
                              title="LEVEL"
                              info={workExperience.level}
                            />
                            <InfoColumn
                              title="SPECIALIZATION"
                              info={workExperience.specialization}
                            />
                          </Row>
                          <Row>
                            <InfoColumn
                              title="UNIVERSITY"
                              info={workExperience.university}
                            />
                            <InfoColumn
                              title="LOCATION"
                              info={workExperience.location}
                            />
                          </Row>
                          <Row>
                            <InfoColumn
                              title="SCORE"
                              info={workExperience.score}
                            />
                            {workExperience.description && (
                              <InfoColumn
                                title="DESCRIPTION"
                                info={workExperience.description}
                              />
                            )}
                          </Row>
                          <Row>
                            <InfoColumn
                              title="START PERIOD"
                              info={moment(workExperience.startPeriod).format(
                                'DD-MM-YYYY h:mm'
                              )}
                            />
                            <InfoColumn
                              title="END PERIOD"
                              info={moment(workExperience.endPeriod).format(
                                'DD-MM-YYYY h:mm'
                              )}
                            />
                          </Row>
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                )}
              </TabPane>
            ))}

          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">ADDRESS INFO</span>
                <span className="show-mobile">AI</span>
              </div>
            }
            key="4">
            {employee.homeAddress && (
              <div>
                <h3>UAE ADDRESS</h3>
                <Row>
                  <InfoColumn
                    title="ADDRESS"
                    info={employee.homeAddress.address}
                  />
                  <InfoColumn title="CITY" info={employee.homeAddress.city} />
                </Row>
                <Row>
                  <InfoColumn title="STATE" info={employee.homeAddress.state} />
                  {employee.homeAddress.zip && (
                    <InfoColumn title="ZIP" info={employee.homeAddress.zip} />
                  )}
                </Row>
                <Row>
                  <InfoColumn
                    title="CONTACT"
                    info={employee.homeAddress.contact}
                  />
                  <InfoColumn
                    title="TEAM LEAD"
                    info={employee.homeAddress.teamLead}
                  />
                </Row>
              </div>
            )}
            {employee.permanentAddress && (
              <div>
                <Divider />
                <h3>HOME COUNTRY ADDRESS</h3>
                <Row>
                  <InfoColumn
                    title="ADDRESS"
                    info={employee.permanentAddress.address}
                  />
                  <InfoColumn
                    title="CITY"
                    info={employee.permanentAddress.city}
                  />
                </Row>
                <Row>
                  <InfoColumn
                    title="STATE"
                    info={employee.permanentAddress.state}
                  />
                  {employee.permanentAddress.zip && (
                    <InfoColumn
                      title="ZIP"
                      info={employee.permanentAddress.zip}
                    />
                  )}
                </Row>
                <Row>
                  <InfoColumn
                    title="CONTACT"
                    info={employee.permanentAddress.contact}
                  />
                  <InfoColumn
                    title="TEAM LEAD"
                    info={employee.permanentAddress.teamLead}
                  />
                </Row>
              </div>
            )}
            {employee.social && (
              <div>
                <Divider />
                <h3>SOCIAL</h3>
                <Row>
                  <InfoColumn title="FACEBOOK" info={employee.social.fb} />
                  <InfoColumn title="TWITTER" info={employee.social.twitter} />
                </Row>
                <Row>
                  <InfoColumn
                    title="LINKED IN"
                    info={employee.social.linkedin}
                  />
                  <InfoColumn
                    title="GOOGLE PLUS"
                    info={employee.social.googleplus}
                  />
                </Row>
              </div>
            )}
          </TabPane>
        </Tabs>
      </StyledProfileModal>
    )
  }
}
export default withRouter(EmployeeProfile)
