import React, { Component } from 'react'
import { Form, Icon, Row, Button } from 'antd'
import WorkExperienceForm from './WorkExperienceForm'
import EducationForm from './EducationForm'

class ProfessionInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      workExperience: null,
      education: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.prev = this.prev.bind(this)
    this.haveValue = this.haveValue.bind(this)
    this.update = this.update.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data,
        workExperience: this.props.data.workExperience,
        education: this.props.data.education
      })
    }
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  update(key, data) {
    this.setState({ [key]: data })
  }

  handleSubmit() {
    let { workExperience, education } = this.state
    this.props.onSubmit({ workExperience, education })
  }

  prev() {
    this.props.onPrev()
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <EducationForm
            view={this.props.view}
            theme={this.props.theme}
            data={this.props.data ? this.props.data.education : null}
            update={this.update}
          />
        </Row>
        <Row>
          <WorkExperienceForm
            view={this.props.view}
            theme={this.props.theme}
            selectData={this.props.selectData}
            data={this.props.data ? this.props.data.workExperience : null}
            update={this.update}
          />
        </Row>
        <Row className="button-row">
          <Button style={{ marginRight: '8px' }} onClick={this.prev}>
            <Icon type="left" />
            Previous
          </Button>
          <Button type="primary" onClick={this.handleSubmit}>
            Next
            <Icon type="right" />
          </Button>
        </Row>
      </Form>
    )
  }
}
export default Form.create()(ProfessionInfo)
