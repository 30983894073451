import React, { Component } from 'react'
import { Form, Select, Card, Button, Row, Col, InputNumber } from 'antd'
import request from '../../utils/requestWrapper'
import style from 'styled-components'
import { getMessages } from '../../utils/utils'
import StructureForm from './StructureForm'
import Spin from '../../utils/Spinner'

const FormItem = Form.Item

let StyledSalaryForm = style.div`
  & .ant-form-explain{
    color:#f04134;
  }
  @media screen and (min-width: 768px){
    & .ant-col-xs-24.ant-col-md-6 {
      padding-right:30px;
    }
  }
`

class SalaryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      selectData: [],
      visible: false,
      disabled: false,
      icon: 'plus',
      action: 'Add',
      buttonType: 'primary',
      classButton: null,
      structureError: false,
      loading: false,
      structure: []
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.haveValue = this.haveValue.bind(this)
    this.update = this.update.bind(this)
  }

  componentDidMount() {
    this.getAllEmployees()
    if (this.props.edit || this.props.view) {
      this.setState({ loading: true, showLogin: false })
      let endPoint = `/salary/${this.props.match.params.id}`
      request(endPoint).then(response => {
        this.setState({ loading: false })
        if (response.status === 401) {
        } else if (response.status === 200) {
          let values = response.data
          let formData = {}
          formData['payType'] = values.payType
          formData['salary'] = values.salary
          formData['currency'] = values.currency
          formData['employee'] = values.employee._id
          this.setState({ structure: values.structure })
          this.props.form.setFieldsValue(formData)
        }
      })
    }
  }

  reloadData() {
    this.getAllEmployees()
  }

  update(data) {
    this.setState({ structure: data, structureError: false })
  }

  getAllEmployees() {
    let endPoint = '/helper/employee'
    request(endPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        let { selectData } = this.state
        selectData['employees'] = response.data
        this.setState({ selectData })
      }
    })
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (this.state.structure.length === 0) {
        this.setState({ structureError: true })
      } else if (!err) {
        let requestObject = {}
        requestObject['payType'] = values.payType
        requestObject['salary'] = values.salary
        requestObject['currency'] = values.currency
        requestObject['employee'] = values.employee

        requestObject['structure'] = this.state.structure
        let method = this.props.edit ? 'PATCH' : 'POST'
        let endPoint = this.props.edit
          ? `/salary/${this.props.match.params.id}`
          : '/salary'
        request(endPoint, method, requestObject).then(response => {
          getMessages(response)
          if (response.status === 401) {
            this.setState({ data: response.data })
          } else if (response.status === 200 || response.status === 201) {
            this.props.history.push('/home/salary/list')
          }
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <Spin loading={this.state.loading} />
        <Card
          title={
            (this.props.view ? 'View' : this.props.edit ? 'Edit' : 'Add') +
            ' Salary'
          }>
          <StyledSalaryForm>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={24} md={6}>
                  <FormItem
                    className={this.haveValue('payType')}
                    label="Pay Type">
                    {getFieldDecorator('payType', {
                      rules: [
                        { required: true, message: 'Please enter the Pay Type' }
                      ]
                    })(
                      <Select
                        optionFilterProp="children"
                        showSearch
                        disabled={this.props.view}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .replace(/,/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Pay Type">
                        <Select.Option key="monthly" value={'monthly'}>
                          Monthly
                        </Select.Option>
                        <Select.Option key="annually" value={'annually'}>
                          Annually
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={6}>
                  <FormItem className={this.haveValue('salary')} label="Salary">
                    {getFieldDecorator('salary', {
                      rules: [
                        { required: true, message: 'Please enter the salary' }
                      ]
                    })(
                      <InputNumber
                        disabled={this.props.view}
                        placeholder="Salary"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={6}>
                  <FormItem
                    className={this.haveValue('currency')}
                    label="Currency">
                    {getFieldDecorator('currency', {
                      rules: [
                        { required: true, message: 'Please enter the Currency' }
                      ]
                    })(
                      <Select
                        showSearch
                        disabled={this.props.view}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .replace(/,/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Currency">
                        <Select.Option key="1" value="1">
                          AED
                        </Select.Option>
                        <Select.Option key="2" value="2">
                          USD
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={6}>
                  <FormItem
                    className={this.haveValue('employee')}
                    label="Employee">
                    {getFieldDecorator('employee', {
                      rules: [
                        { required: true, message: 'Please enter the Employee' }
                      ]
                    })(
                      <Select
                        optionFilterProp="children"
                        showSearch
                        disabled={this.props.view}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .replace(/,/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Employee">
                        {this.state.selectData.employees
                          ? this.state.selectData.employees.map(
                              (employee, i) => {
                                return (
                                  <Select.Option
                                    key={i}
                                    value={
                                      employee._id
                                    }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                                )
                              }
                            )
                          : null}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <StructureForm
                  view={this.props.view}
                  theme={this.props.theme}
                  data={this.state.structure ? this.state.structure : null}
                  update={this.update}
                />
                {this.state.structureError ? (
                  <div
                    className="ant-form-explain"
                    style={{ color: '#f04134' }}>
                    Please add at least 1 salary structure
                  </div>
                ) : null}
              </Row>
              {!this.props.view && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={this.state.disabled}>
                  Submit
                </Button>
              )}
            </Form>
          </StyledSalaryForm>
        </Card>
      </div>
    )
  }
}

export default Form.create()(SalaryForm)
