import React, { Component } from 'react'
import {
  Form,
  Modal,
  InputNumber,
  Dropdown,
  Menu,
  Select,
  Icon,
  Button,
  Row,
  Col,
  DatePicker,
  Input,
  Tag
} from 'antd'
import style from 'styled-components'
import Table from '../../utils/TableWrapper'
import { formUtil } from '../../utils/style-utils'
import moment from 'moment'
const FormItem = Form.Item
const Option = Select.Option
const dateFormat = 'DD/MM/YYYY'
const { TextArea } = Input

let StyledEducationForm = style.div`
  ${formUtil}
  span.ant-calendar-picker, & .ant-input-number {
    width:100%;
  }
  button{
    margin:20px;
  }
  .ant-col-sm-12{
    padding-left:.5em;
    padding-right: .5em;
  }
`
let StyleBottonModal = style.div`
  margin-top:1em;
  & .edit-button{
    border: none !important;
    &:hover{
      background:transparent !important;
      border: none !important;
    }
  }
  div{
    margin-top:1.2em;
  }
`
const columns = [
  {
    title: 'Level',
    dataIndex: 'level',
    priority: 1
  },
  {
    title: 'Specialization',
    dataIndex: 'specialization',
    priority: 2
  },
  {
    title: 'University',
    dataIndex: 'university',
    priority: 3
  },
  {
    title: 'Location',
    dataIndex: 'location',
    priority: 4
  }
]
class EducationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      visible: false,
      columns,
      activeIndex: null
    }

    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleActionsMenu = this.handleActionsMenu.bind(this)
    this.reloadData = this.reloadData.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({ data: this.props.data })
    }
    let tableColumns = this.state.columns
    const haveOperations = tableColumns.some(
      item => item.dataIndex === 'actions'
    )
    if (!this.props.view && !haveOperations) {
      tableColumns.push({
        title: 'Operations',
        dataIndex: 'actions',
        key: 'actions',
        priority: 1,
        render: (text, record, index) => {
          return (
            <Dropdown
              overlay={
                <Menu
                  onClick={({ item, key, keyPath }) => {
                    this.handleActionsMenu(key, record, index)
                  }}>
                  <Menu.Item key="1">
                    <Icon style={{ marginRight: '8px' }} type="edit" />
                    Edit
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Icon style={{ marginRight: '8px' }} type="delete" />
                    Delete
                  </Menu.Item>
                </Menu>
              }>
              <Button style={{ marginLeft: 8, border: 0 }}>
                <Icon type="bars" /> <Icon type="down" />
              </Button>
            </Dropdown>
          )
        }
      })
    }

    this.setState({ columns: tableColumns })
  }

  handleActionsMenu(key, record, index) {
    if (key === '1') {
      this.showModal()
      if (record.startPeriod) {
        record.startPeriod = moment(record.startPeriod)
      }
      if (record.endPeriod) {
        record.endPeriod = moment(record.endPeriod)
      }
      this.props.form.setFieldsValue(record)
      this.setState({ activeIndex: index })
    } else if (key === '2') {
      let { data } = this.state
      data.splice(index, 1)
      this.setState({ data })
      this.props.update('education', data)
    }
  }
  showModal() {
    this.setState({
      visible: true
    })
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleCancel() {
    this.setState({ visible: false, activeIndex: null })
    this.props.form.resetFields()
  }

  reloadData() {}

  add(values) {
    let { data } = this.state
    if (this.state.activeIndex !== null) {
      data[this.state.activeIndex] = values
      this.setState({
        activeIndex: null
      })
    } else {
      data.push(values)
    }
    this.setState({ data })
    this.props.update('education', data)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.add(values)
        this.handleCancel()
      }
    })
  }

  handleSelectChange = value => {}
  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <StyleBottonModal>
          <Tag>Education</Tag>
          <div>
            {!this.props.view && (
              <Button icon="plus" type="primary" onClick={this.showModal}>
                Add
              </Button>
            )}
          </div>
        </StyleBottonModal>
        <Modal
          visible={this.state.visible}
          title="Education Info"
          width={500}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}>
          <StyledEducationForm theme={this.props.theme}>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={24} sm={12}>
                  <FormItem className={this.haveValue('level')} label="Level">
                    {getFieldDecorator('level', {
                      rules: [
                        { required: true, message: 'Please enter the Level' }
                      ]
                    })(
                      <Select placeholder="Level">
                        <Option key="High" value="High">
                          High
                        </Option>
                        <Option key="school" value="school">
                          School
                        </Option>
                        <Option key="Bachelor" value="Bachelor">
                          Bachelor
                        </Option>
                        <Option key="Masters" value="Masters">
                          Masters
                        </Option>
                        <Option key="PhD" value="PhD">
                          PhD
                        </Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue(`specialization`)}
                    label="Specialization">
                    {getFieldDecorator(`specialization`, {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the Specializarion'
                        }
                      ]
                    })(
                      <Select placeholder="Specialization">
                        <Select.Option key="option1" value="Nationatily 1">
                          Specialization 1
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue(`university`)}
                    label="University">
                    {getFieldDecorator(`university`, {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the University'
                        }
                      ]
                    })(<Input placeholder="University" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue(`location`)}
                    label="Location">
                    {getFieldDecorator(`location`, {
                      rules: [
                        { required: true, message: 'Please enter the Location' }
                      ]
                    })(<Input placeholder="Location" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue(`description`)}
                    label="Description">
                    {getFieldDecorator(
                      `description`,
                      {}
                    )(<TextArea rows={2} placeholder="Description" />)}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem className={this.haveValue(`score`)} label="Score">
                    {getFieldDecorator(`score`, {
                      rules: [
                        { required: true, message: 'Please enter the score' }
                      ]
                    })(<InputNumber placeholder="Score" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue(`startPeriod`)}
                    label="Start Period">
                    {getFieldDecorator(`startPeriod`, {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the start date'
                        }
                      ]
                    })(
                      <DatePicker
                        placeholder="Start Period"
                        format={dateFormat}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem
                    className={this.haveValue(`endPeriod`)}
                    label="End Period">
                    {getFieldDecorator(`endPeriod`, {
                      rules: [
                        { required: true, message: 'Please enter the end date' }
                      ]
                    })(
                      <DatePicker
                        placeholder="End Period"
                        format={dateFormat}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Button type="primary" onClick={e => this.handleSubmit(e)}>
                Add
              </Button>
            </Form>
          </StyledEducationForm>
        </Modal>
        {this.state.data.length > 0 ? (
          <Table
            dataSource={this.state.data}
            columns={this.state.columns}
            rowKey="_id"
            notSearch
          />
        ) : null}
      </div>
    )
  }
}

export default Form.create()(EducationForm)
