export default class RolesGlobal {
  keyValueSort(dataObj, values) {
    for (let i = dataObj.length - 1; i >= 0; i--) {
      dataObj[i].actions = []
    }
    for (let key in values) {
      let x = key.split('_')
      for (let i = dataObj.length - 1; i >= 0; i--) {
        let y = dataObj[i]
        if (x[1] === y.resource && values[key] === true) {
          dataObj[i].actions.push(x[0])
        }
      }
    }
    for (let i = dataObj.length - 1; i >= 0; i--) {
      let act = dataObj[i]
      if (act.actions.length === 0) {
        delete dataObj[i]
        dataObj = dataObj.filter(Boolean)
      }
    }
    return dataObj
  }

  referencePerm(data) {
    let dataSource = data.map(record => {
      let obj = {}
      obj.resource = record.name
      obj.isSubPath = record.isSubPath
      if (record.isSubPath) {
        obj.parentPath = record.parentPath
      }
      obj.actions = record.actions
      obj.resourceCode = record.code
      obj.path = record.path
      return obj
    })
    return dataSource
  }

  refPermVsUsersPerms(referencePerm, perms) {
    for (var i = referencePerm.length - 1; i >= 0; i--) {
      let main = referencePerm[i]
      let count = 0
      for (var j = perms.length - 1; j >= 0; j--) {
        let temp = perms[j]
        if (main.resourceCode === temp.resourceCode) {
          referencePerm[i].actions = perms[j].actions
          count++
          continue
        } else if (main.resource === temp.resource) {
          referencePerm[i].actions = perms[j].actions
          count++
          continue
        } else if (j === 0 && count === 0) {
          referencePerm[i].actions = []
          continue
        }
      }
    }
    return referencePerm
  }
}
