import 'antd/dist/antd.css'
import React, { Component } from 'react'
import { Form, DatePicker, Select, Card, Button, Row, Col, Table } from 'antd'
import request from '../../utils/requestWrapper'
import { jsUcfirst } from '../../utils/utils'
import style from 'styled-components'
import { getMessages, getDate } from '../../utils/utils'
import moment from 'moment'
const FormItem = Form.Item

const dateFormat = 'YYYY/MM/DD'
let StyledAttendanceForm = style.div`
  & .ant-checkbox-wrapper {
    opacity:1 !important;
  }
  .ant-form-explain{
    color: #f5222d;
  }
  @media screen and (min-width: 768px){
    & .ant-col-xs-24.ant-col-sm-8, & .ant-col-xs-24.ant-col-md-6 {
      padding-right:30px;
    }
  }
`
const typeOptions = ['in', 'out', 'leave', 'holiday']
const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    priority: 1
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    priority: 2
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    priority: 3,
    render: text => moment(text).format('DD-MM-YYYY h:mm')
  }
]

class AttendanceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selectedRowKeys: [],
      visible: false,
      disabled: false,
      icon: 'plus',
      action: 'Add',
      buttonType: 'primary',
      classButton: null
    }

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.reloadData = this.reloadData.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }
  componentDidMount() {
    if (!this.props.edit) {
      this.getAllEmployees()
    } else {
      this.getEmployeeDetail()
    }
  }
  getEmployeeDetail() {
    this.setState({ loading: true })
    let endPoint = `/attendance/${this.props.match.params.id}`
    request(endPoint).then(response => {
      let employeeData = {
        type: response.data.type,
        time: moment(getDate(response.data.time), 'YYYY-MM-DD')
      }
      this.setState({ loading: false, data: [response.data.employee] })
      this.props.form.setFieldsValue(employeeData)
    })
  }
  reloadData() {
    this.getAllEmployees()
  }

  getAllEmployees() {
    let endPoint = '/helper/employee'
    this.setState({ loading: true })
    request(endPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true, action: this.reloadData })
      } else if (response.status === 200) {
        this.setState({ data: response.data, loading: false })
        if (this.props.edit) {
          this.getEmployeeDetail()
        }
      }
    })
  }
  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }
  handleCancel() {
    this.setState({ visible: false })
    if (!this.props.data) {
      this.props.form.resetFields()
    }
  }
  handleSubmit(e) {
    e.preventDefault()
    this.setState({ employeError: false })
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (this.state.selectedRowKeys.length === 0) {
        this.setState({ employeError: true })
      } else if (!err) {
        let requestObject = {}
        requestObject['time'] = values.time
          ? values.time.format('YYYY/MM/DD')
          : null || moment().format('YYYY/MM/DD')
        requestObject['type'] = values.type
        let employees = []
        this.state.data.forEach((employee, i) => {
          if (this.state.selectedRowKeys.indexOf(i) !== -1) {
            employees.push({
              time: requestObject['time'],
              type: requestObject['type'],
              employee: employee._id
            })
          }
        })
        requestObject['employee'] = employees
        let method = this.props.edit ? 'PATCH' : 'POST'
        let endPoint = this.props.edit
          ? `/attendance/${this.props.match.params.id}`
          : '/attendance'
        request(endPoint, method, employees).then(response => {
          getMessages(response)
          if (response.status === 401) {
            this.setState({ data: response.data })
          } else if (response.status === 200 || response.status === 201) {
            this.props.history.push('/home/attendance/list')
          }
        })
      }
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelect: this.onSelectRow
    }
    return (
      <div>
        <StyledAttendanceForm theme={this.props.theme}>
          <Card title="Add attendance">
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={24} sm={8}>
                  <FormItem
                    colon={false}
                    className={this.haveValue('time')}
                    label="Time">
                    {getFieldDecorator(
                      'time',
                      {}
                    )(
                      <DatePicker
                        showTime
                        disabled={this.state.loading}
                        format={dateFormat}
                        placeholder="Select Time"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={8}>
                  <FormItem className={this.haveValue('type')} label="Type">
                    {getFieldDecorator('type', {
                      rules: [
                        { required: true, message: 'Please enter the Type' }
                      ]
                    })(
                      <Select disabled={this.state.loading} placeholder="Type">
                        {typeOptions.map(type => (
                          <Select.Option key={type} value={type}>
                            {jsUcfirst(type)}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Table
                    rowSelection={!this.props.edit ? rowSelection : null}
                    dataSource={this.state.data}
                    columns={columns}
                    loading={this.state.loading}
                    pagination={this.props.edit ? false : true}
                  />
                  {this.state.employeError && (
                    <div class="ant-form-explain">Please select a employee</div>
                  )}
                </Col>
              </Row>

              {!this.props.view && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={this.state.disabled}>
                  Submit
                </Button>
              )}
            </Form>
          </Card>
        </StyledAttendanceForm>
      </div>
    )
  }
}
export default Form.create()(AttendanceForm)
