/* eslint-disable */
import { message } from 'antd'
message.config({
  duration: 5,
  maxCount: 1
})
let jsUcfirst = string => string.charAt(0).toUpperCase() + string.slice(1)

let getUrl = (module, params, active = true) => {
  let endPoint = `${module}?limit=${params.pageSize}&page=${params.page}`

  if (params.sort) {
    endPoint += `&sort=${params.sort.field}:${params.sort.order}`
  }
  if (params.filter) {
    endPoint += `&${params.filter}`
    /*  endPoint += (active)?',active:true':'' */
  } else if (active) {
    /*   endPoint += '&filters=active:true' */
  }
  return endPoint
}

let scorePassword = pass => {
  let score = 0
  if (!pass) {
    return score
  }

  let letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  let variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }

  let variationCount = 0

  for (let check in variations) {
    variationCount += variations[check] === true ? 1 : 0
  }
  score += (variationCount - 1) * 10
  return parseInt(score, 10)
}

let getMessages = response => {
  message.destroy()
  if (response.status >= 200 && response.status < 300) {
    message.success(response.message)
  } else if (response.status === 400) {
    if (Object.keys(response.errors).length > 0) {
      let errors = response.errors
      Object.keys(errors).forEach(error => {
        if (
          errors[error].message !== '' &&
          errors[error].message !== undefined
        ) {
          message.error(`${errors[error].message}: ${error}`, 5)
        }
      })
    } else {
      message.error(response.message)
    }
  } else if (
    (response.status >= 403 && response.status <= 405) ||
    response.status === 500
  ) {
    window.location = `#/error-${response.status}`
  } else {
    message.error(response.message)
  }
}

let getParameterByName = name => {
  let url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  let results = regex.exec(url)
  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
let getDate = iso => {
  let date = new Date(iso)
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let dt = date.getDate()

  if (dt < 10) {
    dt = '0' + dt
  }
  if (month < 10) {
    month = '0' + month
  }
  let finaDate = year + '-' + month + '-' + dt
  return finaDate
}

let parseJwt = token => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

let processEmployeeForm = requestObject => {
  if (requestObject['middleName'] === '') {
    delete requestObject['middleName']
  }
  if (requestObject['contactNumber2'] === '') {
    delete requestObject['contactNumber2']
  }
  if (requestObject['marital'] === '') {
    delete requestObject['marital']
  }
  if (requestObject['middleName'] === '') {
    delete requestObject['middleName']
  }
  if (requestObject['teamLead'] === '') {
    delete requestObject['teamLead']
  }
  if (requestObject['trainer'] === '') {
    delete requestObject['trainer']
  }
  if (requestObject['referedBy'] === '') {
    delete requestObject['referedBy']
  }
  if (requestObject['visaDetials']['jobTitle'] === '') {
    delete requestObject['visaDetials']['jobTitle']
  }
  if (requestObject['visaDetials']['visaSponser'] === '') {
    delete requestObject['visaDetials']['visaSponser']
  }
  if (requestObject['visaDetials']['visaStartDate'] === '') {
    delete requestObject['visaDetials']['visaStartDate']
  }
  if (requestObject['visaDetials']['visaEndDate'] === '') {
    delete requestObject['visaDetials']['visaEndDate']
  }
  if (requestObject['homeAddress']['address'] === '') {
    delete requestObject['homeAddress']['address']
  }
  if (requestObject['homeAddress']['city'] === '') {
    delete requestObject['homeAddress']['city']
  }
  if (requestObject['homeAddress']['state'] === '') {
    delete requestObject['homeAddress']['state']
  }
  if (requestObject['homeAddress']['zip'] === '') {
    delete requestObject['homeAddress']['zip']
  }
  if (requestObject['homeAddress']['contact'] === '') {
    delete requestObject['homeAddress']['contact']
  }
  if (requestObject['homeAddress']['country'] === '') {
    delete requestObject['homeAddress']['country']
  }
  if (requestObject['permanentAddress']['address'] === '') {
    delete requestObject['permanentAddress']['address']
  }
  if (requestObject['permanentAddress']['city'] === '') {
    delete requestObject['permanentAddress']['city']
  }
  if (requestObject['permanentAddress']['state'] === '') {
    delete requestObject['permanentAddress']['state']
  }
  if (requestObject['permanentAddress']['zip'] === '') {
    delete requestObject['permanentAddress']['zip']
  }
  if (requestObject['permanentAddress']['contact'] === '') {
    delete requestObject['permanentAddress']['contact']
  }
  if (requestObject['permanentAddress']['country'] === '') {
    delete requestObject['permanentAddress']['country']
  }
  if (requestObject['social']['fb'] === '') {
    delete requestObject['social']['fb']
  }
  if (requestObject['social']['twitter'] === '') {
    delete requestObject['social']['twitter']
  }
  if (requestObject['social']['linkedin'] === '') {
    delete requestObject['social']['linkedin']
  }
  if (requestObject['social']['linkedin'] === '') {
    delete requestObject['social']['linkedin']
  }
  return requestObject
}

let getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export {
  jsUcfirst,
  getUrl,
  getMessages,
  scorePassword,
  getParameterByName,
  getDate,
  parseJwt,
  processEmployeeForm,
  getBase64
}
