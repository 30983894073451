import React, { Component } from 'react'
import style from 'styled-components'
import { Form, Icon, Select, Row, Col, Button, Input } from 'antd'

const FormItem = Form.Item

const StyledJobInfoForm = style.div`
  h5{
    margin-top:1em;
  }
`
class JobInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filled: false,
      Verified: false,
      branch: false,
      showOtherSector: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.prev = this.prev.bind(this)
    this.haveValue = this.haveValue.bind(this)
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.form.setFieldsValue(this.props.data)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && !this.state.filled) {
      this.props.form.setFieldsValue(nextProps.data)
      this.setState({
        filled: true
      })
    }
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  handleChange = (e, value) => {
    if (e === 'verified') {
      if (value === 'No') {
        this.setState({
          Verified: true
        })
      } else {
        this.setState({
          Verified: false
        })
      }
    }
    if (e === 'hasBranch') {
      if (value === 'Yes') {
        this.setState({
          branches: true
        })
      } else {
        this.setState({
          branches: false
        })
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { getFieldValue } = this.props.form
        let newValues = { ...values }
        if (getFieldValue('sector.name') !== 'Others') {
          newValues['sector']['other'] = ''
        }
        if (getFieldValue('personMet.type') !== 'Others') {
          newValues['personMet']['name'] = ''
        }
        if (getFieldValue('personMet.designation') !== 'Others') {
          newValues['personMet']['personDesignationOthers'] = ''
        }
        if (getFieldValue('officeLandlineVerified.verified') !== 'No') {
          newValues['officeLandlineVerified']['reason'] = undefined
        }
        this.props.onSubmit(newValues)
      }
    })
  }

  prev() {
    this.props.onPrev()
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form

    return (
      <StyledJobInfoForm>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('industry')}
                label="Industry*">
                {getFieldDecorator('industry', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select your Industry'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Industry*">
                    <Select.Option key="Trading" value="Trading">
                      Trading
                    </Select.Option>
                    <Select.Option key="Manufacturing" value="Manufacturing">
                      Manufacturing
                    </Select.Option>
                    <Select.Option key="Retailing" value="Retailing">
                      Retailing
                    </Select.Option>
                    <Select.Option key="Hospitality" value="Hospitality">
                      Hospitality
                    </Select.Option>
                    <Select.Option key="Aviation" value="Aviation">
                      Aviation
                    </Select.Option>
                    <Select.Option
                      key="Manpower Outsourcing"
                      value="Manpower Outsourcing">
                      Manpower Outsourcing
                    </Select.Option>
                    <Select.Option
                      key="Financial Services"
                      value="Financial Services">
                      Financial Services
                    </Select.Option>
                    <Select.Option key="Others" value="Others">
                      Others
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('sector.name')}
                label="Sector*">
                {getFieldDecorator('sector.name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select your Sector'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Sector*">
                    <Select.Option key="Banking" value="Banking">
                      Banking
                    </Select.Option>
                    <Select.Option key="NBFC" value="NBFC">
                      NBFC
                    </Select.Option>
                    <Select.Option
                      key="Food Stuff Trad"
                      value="Food Stuff Trad">
                      Food Stuff Trad
                    </Select.Option>
                    <Select.Option
                      key="Metal / Scrap Trad"
                      value="Metal / Scra p Trad">
                      Metal / Scrap Trad
                    </Select.Option>
                    <Select.Option
                      key="Jewellery Trading / Retailing"
                      value="Jewellery Trading / Retailing">
                      Jewellery Trading / Retailing
                    </Select.Option>
                    <Select.Option
                      key="Auto Dealer(Used)"
                      value="Auto Dealer(Used)">
                      Auto Dealer(Used)
                    </Select.Option>
                    <Select.Option key="Auto Mechanic" value="Auto Mechanic">
                      Auto Mechanic
                    </Select.Option>
                    <Select.Option
                      key="Technical / Contr.Serv"
                      value="Technical / Contr.Serv">
                      Technical / Contr.Serv
                    </Select.Option>
                    <Select.Option key="L.Sal" value="L.Sal">
                      L.Sal
                    </Select.Option>
                    <Select.Option key="G.Sal" value="G.Sal">
                      G.Sal
                    </Select.Option>
                    <Select.Option
                      key="Mobile Retailer"
                      value="Mobile Retailer">
                      Mobile Retailer
                    </Select.Option>
                    <Select.Option key="Manpowder Out" value="Manpowder Out">
                      Manpowder Out
                    </Select.Option>
                    <Select.Option key="Airport Retail" value="Airport Retail">
                      Airport Retail
                    </Select.Option>
                    <Select.Option
                      key="Beauty Prod Retail"
                      value="Beauty Prod Retail">
                      Beauty Prod Retail
                    </Select.Option>
                    <Select.Option key="Samall Ret." value="Samall Ret.">
                      Samall Ret.
                    </Select.Option>
                    <Select.Option key="Large Ret" value="Large Ret">
                      Large Ret
                    </Select.Option>
                    <Select.Option key="Others" value="Others">
                      Others
                    </Select.Option>
                  </Select>
                )}
              </FormItem>{' '}
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('sector.other')}
                label="Other Sector*">
                {getFieldDecorator('sector.other', {
                  rules: [
                    {
                      required:
                        getFieldValue('sector.name') === 'Others'
                          ? true
                          : false,
                      message: 'Please enter another Sector'
                    }
                  ]
                })(<Input placeholder="Other Sector" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('lineOfActivity')}
                label="Line of Activity">
                {getFieldDecorator('lineOfActivity')(<Input placeholder="Line of Activity" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('officeType')}
                label="Office Type*">
                {getFieldDecorator('officeType', {
                  rules: [
                    { required: true, message: 'Please select the Office Type' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select Office Type*">
                    <Select.Option key="Residential" value="Residential">
                      Residential
                    </Select.Option>
                    <Select.Option key="Commercial" value="Commercial">
                      Commercial
                    </Select.Option>
                    <Select.Option
                      key="Business Center"
                      value="Business Center">
                      Business Center
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('visitType')}
                label="Visit Type*">
                {getFieldDecorator('visitType', {
                  rules: [
                    { required: true, message: 'Please select the Visit Type' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select Office Type*">
                    <Select.Option key="HR" value="HR">
                      HR
                    </Select.Option>
                    <Select.Option
                      key="Admin/Office Manager"
                      value="Admin/Office Manager">
                      Admin/Office Manager
                    </Select.Option>
                    <Select.Option
                      key="Accounts/Finance"
                      value="Accounts/Finance">
                      Accounts/Finance
                    </Select.Option>
                    <Select.Option key="Owner" value="Owner">
                      Owner
                    </Select.Option>
                    <Select.Option key="Others" value="Others">
                      Others
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('personMet.type')}
                label="Person Type*">
                {getFieldDecorator('personMet.type', {
                  rules: [
                    { required: true, message: 'Please enter the Person Type' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select Person Type*">
                    <Select.Option key="Applicant" value="Applicant">
                      Applicant
                    </Select.Option>
                    <Select.Option key="Others" value="Others">
                      Others
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('personMet.name')}
                label="Person Name*">
                {getFieldDecorator('personMet.name', {
                  rules: [
                    {
                      required:
                        getFieldValue('personMet.type') === 'Others'
                          ? true
                          : false,
                      message: 'Please enter the Person Name'
                    }
                  ]
                })(<Input placeholder="Person Name*" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('personMet.designation')}
                label="Designation*">
                {getFieldDecorator('personMet.designation', {
                  rules: [
                    { required: true, message: 'Please select the Designation' }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select Designation*">
                    <Select.Option key="HR" value="HR">
                      HR
                    </Select.Option>
                    <Select.Option key="Admin" value="Admin">
                      Admin
                    </Select.Option>
                    <Select.Option key="Office Manager" value="Office Manager">
                      Office Manager
                    </Select.Option>
                    <Select.Option key="Receptionist" value="Receptionist">
                      Receptionist
                    </Select.Option>
                    <Select.Option key="Others" value="Others">
                      Others
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('personMet.personDesignationOthers')}
                label="Person Designation*">
                {getFieldDecorator('personMet.personDesignationOthers', {
                  rules: [
                    {
                      required:
                        getFieldValue('personMet.designation') === 'Others'
                          ? true
                          : false,
                      message: 'Please enter the Person Designation'
                    }
                  ]
                })(<Input placeholder="Person Designation" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('officeLandline')}
                label="Office Landline*">
                {getFieldDecorator('officeLandline', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the office Landline'
                    }
                  ]
                })(<Input placeholder="Office Landline*" />)}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('officeLandlineVerified.verified')}
                label="Verified*">
                {getFieldDecorator('officeLandlineVerified.verified', {
                  rules: [
                    {
                      required: true,
                      message: 'This field is required'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Verified*"
                    onChange={e => this.handleChange('verified', e)}>
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                    <Select.Option
                      key="Business Center Common Number"
                      value="Business Center Common Number">
                      Business Center Common Number
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('officeLandlineVerified.reason')}
                label="Select Not Verified Reason*">
                {getFieldDecorator('officeLandlineVerified.reason', {
                  rules: [
                    {
                      required:
                        getFieldValue('officeLandlineVerified.verified') ===
                        'No'
                          ? true
                          : false,
                      message: 'This field is required'
                    }
                  ]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select Verified Reason">
                    <Select.Option key=" Not Allowed" value=" Not Allowed">
                      Not Allowed
                    </Select.Option>
                    <Select.Option key="Big Set-up" value="Big Set-up">
                      Big Set-up
                    </Select.Option>
                    <Select.Option key="No LL" value="No LL">
                      No LL
                    </Select.Option>
                    <Select.Option key="Multiple lines" value="Multiple lines">
                      Multiple lines
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('hasBranch')}
                label="Has Branch*">
                {getFieldDecorator('hasBranch', {
                  rules: [{ required: true, message: 'This field is required' }]
                })(
                  <Select
                    optionFilterProp="children"
                    showSearch
                    disabled={this.props.view}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Branches*"
                    onChange={e => this.handleChange('hasBranch', e)}>
                    <Select.Option key="Yes" value="Yes">
                      Yes
                    </Select.Option>
                    <Select.Option key="No" value="No">
                      No
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem
                className={this.haveValue('numOfBranch')}
                label="Number of Branches*">
                {getFieldDecorator('numOfBranch', {
                  rules: [
                    {
                      required:
                        getFieldValue('hasBranch') === 'Yes' ? true : false,
                      message: 'Please enter the number of Branch'
                    }
                  ]
                })(<Input placeholder="Number of Branches" />)}
              </FormItem>
            </Col>
          </Row>
          <Row className="button-row">
            <Button style={{ marginRight: '8px' }} onClick={this.prev}>
              <Icon type="left" />
              Previous
            </Button>
            <Button type="primary" htmlType="submit">
              Next
              <Icon type="right" />
            </Button>
          </Row>
        </Form>
      </StyledJobInfoForm>
    )
  }
}
export default Form.create()(JobInfo)
