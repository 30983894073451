import React from 'react'
import { Input, Table, Row, Col, Button, Form, Checkbox, Card } from 'antd'
import request from '../../utils/requestWrapper'
import JobTitlesGlobal from './JobTitlesGlobal'
import { getMessages } from '../../utils/utils'
import style from 'styled-components'

const StyledJobTitleForm = style.div`
  & .ant-form-item{
    margin:1em 1em 1em 0;
  }
  & .first-row{
    margin-bottom:1em;
  }
  & .ant-table{
    padding:16px;
  }
  h3{
    margin:0;
  }
  .disabled-checkbox{
    background: #F5F5F5;
    border: 2px:solid #D9D9D9;
    border-radius: 2px;
    width:14px;
    height: 14px;
    cursor: not-allowed;
  }
`
const Column = Table.Column
const FormItem = Form.Item
var jobTitlesGlobal = new JobTitlesGlobal()

export class JobTitleForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      resourcesChecked: {},
      showLogin: false,
      loading: false
    }
    this.toggleRow = this.toggleRow.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.haveValue = this.haveValue.bind(this)
    this.getEditData = this.getEditData.bind(this)
  }

  componentDidMount() {
    this.getResources()
  }

  haveValue(value) {
    if (
      this.props.form.getFieldValue(value) !== undefined &&
      this.props.form.getFieldValue(value) !== ''
    ) {
      return 'with-label'
    }
    return ''
  }

  getResources() {
    let endPoint = '/resource'
    this.setState({ showLogin: false, loading: true })
    request(endPoint).then(response => {
      if (!this.props.edit) {
        this.setState({ loading: false })
      }
      if (response.status === 401) {
      } else if (response.status === 200) {
        let dataSource = jobTitlesGlobal.referencePerm(response.data)
        if (this.props.edit) {
          this.getEditData()
        }
        this.setState({ dataSource })
      }
    })
  }

  getEditData() {
    this.setState({ showLogin: false })
    let endPoint = '/resource'
    request(endPoint).then(response => {
      if (response.status === 401) {
        this.setState({ showLogin: true })
      } else if (response.status === 200) {
        let endPoint = this.props.edit
          ? `/jobtitle/${this.props.match.params.id}`
          : '/jobtitle'
        request(endPoint).then(response => {
          this.setState({ loading: false })
          if (response.status === 401) {
          } else if (response.status === 200 || response.status === 304) {
            let value = {}
            value['name'] = response.data.name
            value['description'] = response.data.description
            let permissions = response.data.permissions
            permissions.forEach(permission => {
              permission.actions.forEach(action => {
                value[`${action}_${permission.resource}`] = true
              })
            })
            this.props.form.setFieldsValue(value)
          }
        })
      }
    })
  }

  toggleRow(record) {
    let resourcesChecked = this.state.resourcesChecked
    let value = null
    if (resourcesChecked[record.resource] !== undefined) {
      value = !resourcesChecked[record.resource]
    } else {
      value = true
    }
    resourcesChecked[record.resource] = value
    let obj = {}
    record.actions.forEach(action => {
      obj[`${action}_${record.resource}`] = value
    })
    this.setState({ resourcesChecked })
    this.props.form.setFieldsValue(obj)
  }

  handleSubmit(e = null) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.setState({ showLogin: false })
    let dataSource = this.state.dataSource
    dataSource = dataSource.filter(Boolean)
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        dataSource = jobTitlesGlobal.keyValueSort(dataSource, values)
        let reqObj = {}
        reqObj.name = values.name
        reqObj.description = values.description
        reqObj.permissions = dataSource
        let method = this.props.edit ? 'PATCH' : 'POST'
        let endPoint = this.props.edit
          ? `/jobtitle/${this.props.match.params.id}`
          : '/jobtitle'
        request(endPoint, method, reqObj).then(response => {
          getMessages(response)
          if (response.status === 401) {
          } else if (response.status === 200 || response.status === 201) {
            this.props.history.push('/home/jobtitle/list')
          }
        })
      }
    })
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    return (
      <StyledJobTitleForm>
        <Card title={`${this.props.edit ? 'Edit' : 'Create'} Job Title`}>
          <Form onSubmit={this.handleSubmit}>
            <Row className="first-row">
              <Col xs={24} sm={8}>
                <FormItem
                  className={this.haveValue(`name`)}
                  colon={false}
                  label="Name"
                  hasFeedback>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please enter a name!' }]
                  })(
                    <Input disabled={this.state.loading} placeholder="Name" />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} sm={8}>
                <FormItem
                  className={this.haveValue(`description`)}
                  colon={false}
                  label="Description"
                  hasFeedback>
                  {getFieldDecorator('description', {
                    rules: [
                      { required: true, message: 'Please enter a description!' }
                    ]
                  })(
                    <Input
                      disabled={this.state.loading}
                      placeholder="Description"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Table
                className="jobTitle-table"
                rowKey="resourceCode"
                dataSource={this.state.dataSource}
                pagination={false}
                size="small"
                loading={this.state.loading}>
                <Column
                  title="Resources"
                  dataIndex="resource"
                  render={(text, record, index) => {
                    return (
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.toggleRow(record)}>
                        {record.resource}
                      </span>
                    )
                  }}
                />
                <Column
                  title="Add"
                  key="post"
                  dataIndex="post"
                  render={(text, record, index) => {
                    return (
                      <FormItem className="with-label" colon={false}>
                        {getFieldDecorator(
                          'post_' + record.resource,
                          {}
                        )(
                          record.actions.indexOf('post') !== -1 ? (
                            <Checkbox
                              checked={
                                getFieldValue('post_' + record.resource) ||
                                false
                              }
                            />
                          ) : (
                            <div className="disabled-checkbox" />
                          )
                        )}
                      </FormItem>
                    )
                  }}
                />
                <Column
                  title="List"
                  key="get"
                  dataIndex="get"
                  render={(text, record, index) => (
                    <FormItem className="with-label" colon={false}>
                      {getFieldDecorator(
                        'get_' + record.resource,
                        {}
                      )(
                        record.actions.indexOf('get') !== -1 ? (
                          <Checkbox
                            checked={
                              getFieldValue('get_' + record.resource) || false
                            }
                          />
                        ) : (
                          <div className="disabled-checkbox" />
                        )
                      )}
                    </FormItem>
                  )}
                />
                <Column
                  title="List All"
                  key="getAll"
                  dataIndex="getAll"
                  render={(text, record, index) => (
                    <FormItem className="with-label" colon={false}>
                      {getFieldDecorator(
                        'get-all_' + record.resource,
                        {}
                      )(
                        record.actions.indexOf('get-all') !== -1 ? (
                          <Checkbox
                            checked={
                              getFieldValue('get-all_' + record.resource) ||
                              false
                            }
                          />
                        ) : (
                          <div className="disabled-checkbox" />
                        )
                      )}
                    </FormItem>
                  )}
                />
                <Column
                  title="Edit"
                  key="patch"
                  dataIndex="patch"
                  render={(text, record, index) => (
                    <FormItem className="with-label" colon={false}>
                      {getFieldDecorator(
                        'patch_' + record.resource,
                        {}
                      )(
                        record.actions.indexOf('patch') !== -1 ? (
                          <Checkbox
                            checked={
                              getFieldValue('patch_' + record.resource) || false
                            }
                          />
                        ) : (
                          <div className="disabled-checkbox" />
                        )
                      )}
                    </FormItem>
                  )}
                />
                <Column
                  title="Delete"
                  key="delete"
                  dataIndex="delete"
                  render={(text, record, index) => (
                    <FormItem className="with-label" colon={false}>
                      {getFieldDecorator(
                        'delete_' + record.resource,
                        {}
                      )(
                        record.actions.indexOf('delete') !== -1 ? (
                          <Checkbox
                            checked={
                              getFieldValue('delete_' + record.resource) ||
                              false
                            }
                          />
                        ) : (
                          <div className="disabled-checkbox" />
                        )
                      )}
                    </FormItem>
                  )}
                />
              </Table>
            </Row>
            <Row>
              <Col
                style={{ paddingRight: '30px', marginTop: '20px' }}
                xs={24}
                sm={8}>
                <FormItem colon={false}>
                  <Button icon="save" type="primary" htmlType="submit">
                    Save
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Card>
      </StyledJobTitleForm>
    )
  }
}
export default Form.create()(JobTitleForm)
