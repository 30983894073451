import React from 'react'
import { Drawer, Icon, Button } from 'antd'
import styled from 'styled-components'
import request from '../../utils/requestWrapper'
import Spinner from '../../utils/Spinner'
import moment from 'moment'

const Wrapper = styled.div`
  .ant-btn {
    border: none;
    background: transparent;
    width: 100%;
    text-align: left;
    &:hover,
    &:focus,
    &:active {
      border: none;
      background: transparent;
      color: initial;
    }
  }
`
const ContentWrapper = styled.div`
  .title {
    font-size: 25px;
    margin-bottom: 0.5em;
  }
  i.anticon {
    margin-right: 0.5em;
  }
  .ant-btn {
    width: 100%;
    height: 42px;
  }
  .details {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    span {
      font-size: 18px;
      margin-bottom: 1em;
    }
  }
`

export default class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false
    }
  }
  showDrawer = () => {
    let endPoint = `/${this.props.type}/${this.props.resource}/${this.props.id}`
    this.setState({ loading: true })
    request(endPoint).then(response => {
      this.setState({
        data: response.data,
        visible: true,
        loading: false
      })
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }

  render() {
    const { data } = this.state
    let size = null
    if (data) {
      if (data.size >= 1000000) {
        size = `${(data.size / 1000000).toFixed(2)} Mb`
      } else {
        size = `${(data.size / 1000).toFixed(2)} Kb`
      }
    }
    return (
      <Wrapper>
        <Spinner loading={this.state.loading} />
        <Button icon="exclamation-circle" onClick={this.showDrawer}>
          Details
        </Button>
        <Drawer
          closable={false}
          width="35%"
          placement="right"
          onClose={this.onClose}
          visible={this.state.visible}>
          {this.state.data && (
            <ContentWrapper>
              <div className="title">
                <Icon type={this.props.resource} />
                {data.name}
              </div>
              <hr />
              <div className="details">
                <span>
                  Created on:{' '}
                  {moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>
                {data.size && <span>{`Size: ${size}`}</span>}
                <span>Type: {data.type}</span>
                {data.format && <span>Format: {data.format}</span>}
                <span>
                  Property:{' '}
                  {`${data.createdBy.firstName} ${data.createdBy.lastName}`}
                </span>
                <span>
                  Last Update :{' '}
                  {moment(data.updatedAt).format('YYYY-DD-MM HH:mm:ss')}
                </span>
              </div>
            </ContentWrapper>
          )}
        </Drawer>
      </Wrapper>
    )
  }
}

/* 

 `https://docs.google.com/viewer?url=${
                      this.state.url
                    }&embedded=true`
*/
