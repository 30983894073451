import 'antd/dist/antd.css'
import React, { Component } from 'react'
import {
  Form,
  Card,
  Select,
  Modal,
  Row,
  Col,
  InputNumber,
  Tag,
  Icon,
  Dropdown,
  Menu,
  Button
} from 'antd'
import request from '../../utils/requestWrapper'
import Table from '../../utils/TableWrapper'
import { getUrl, getMessages } from '../../utils/utils'
import moment from 'moment'
import { withRouter, Link } from 'react-router-dom'
import { formUtil, filterUtil } from '../../utils/style-utils'
import styled from 'styled-components'

const FormItem = Form.Item
const confirm = Modal.confirm

let StyledSalaryList = styled.div`
  ${formUtil} ${filterUtil};
`

const columns = [
  {
    title: 'Pay Type',
    dataIndex: 'payType',
    sorter: true,
    priority: 2
  },
  {
    title: 'Salary',
    dataIndex: 'salary',
    sorter: true,
    priority: 3
  },
  {
    title: 'Employee',
    dataIndex: 'employee',
    priority: 4,
    sorter: true,
    render: (text, record, index) => {
      return `${text.firstName} ${text.lastName}`
    }
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    priority: 5,
    sorter: true
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    priority: 6,
    sorter: true,
    render: text => moment(text).format('DD-MM-YYYY h:mm')
  }
]

class SalaryList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      action: null,
      id: null,
      loading: true,
      showLogin: false,
      data: [],
      pagination: {
        page: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      config: null,
      params: { page: 1, pageSize: 10 },
      showFilter: false,
      columns: columns
    }
    this.handleActionsMenu = this.handleActionsMenu.bind(this)
    this.getSalaries = this.getSalaries.bind(this)
    this.delete = this.delete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
    this.toggleFilter = this.toggleFilter.bind(this)
    this.onChangeSelectColumns = this.onChangeSelectColumns.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.checkString = this.checkString.bind(this)
    this.getConfig = this.getConfig.bind(this)
  }

  componentDidMount() {
    this.getConfig()
    this.getSalaries(this.state.params)
  }

  handleActionsMenu(item, key, keyPath, id) {
    if (key === '3') {
      let current = this
      confirm({
        title: 'Do you Want to delete these ?',
        onOk() {
          current.delete(id)
        },
        onCancel() {}
      })
    }
  }

  onChangeSelectColumns(values) {
    let { columns } = this.state
    columns.forEach(column => {
      if (values.indexOf(column.key) !== -1) {
        column.show = true
      } else {
        column.show = false
      }
    })
    this.setState({ columns })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let filter = ''
        Object.keys(values).forEach((key, i) => {
          if (Array.isArray(values[key]) && values[key].length > 0) {
            filter += `${key}:${values[key].toString().replace(/,/g, ';')},`
          } else if (values[key] && values[key] !== '') {
            filter += `${key}:${values[key]},`
          }
        })
        if (filter !== '') {
          filter = filter.slice(0, -1)
          filter = 'filters=' + filter
          let newFilter = Object.assign(this.state.params, { filter })
          newFilter.page = 1
          let pagination = this.state.pagination
          pagination.current = 1
          this.setState({ pagination, params: newFilter })
          this.getSalaries(newFilter)
        }
      }
    })
  }

  resetSearch() {
    let { params } = this.state
    delete params['filter']
    this.setState({ params })
    this.props.form.resetFields()
    this.getSalaries(params)
  }

  checkString(rule, value, callback) {
    if (
      value &&
      (value.includes(':') || value.includes(';') || value.includes(','))
    ) {
      callback('Please do not enter this characters ; : ,')
    } else {
      callback()
      return
    }
  }

  delete(id) {
    let endPoint = `/salary/${id}`
    this.setState({ loading: true })
    request(endPoint, 'DELETE').then(response => {
      getMessages(response)
      if (response.status === 401) {
      } else if (response.status === 200) {
        this.getSalaries({ page: 1, pageSize: 10 })
      }
    })
  }

  handleTableChange(pagination, filters, sorter) {
    const pager = Object.assign({}, this.state.pagination)
    pager.current = pagination.current
    pager.pageSize = pagination.pageSize
    this.setState({
      pagination: pager
    })
    let params = this.state.params
    params.pageSize = pagination.pageSize
    params.page = pagination.current
    if (sorter.field) {
      params['sort'] = {
        field: sorter.field,
        order: sorter.order === 'descend' ? 'desc' : 'asc'
      }
    }
    this.getSalaries(params)
  }

  toggleFilter() {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter
    }))
  }
  reloadData() {
    this.getSalaries()
  }

  getConfig() {
    let endPoint = `/employee`
    request(endPoint).then(response => {
      if (response.status === 401) {
      } else if (response.status === 200) {
        this.setState({ config: { employee: response.data } })
      }
    })
  }

  getSalaries(params) {
    this.setState({ loading: true, showLogin: false, params, id: null })
    let url = getUrl('/salary', params)
    request(url).then(response => {
      this.setState({ loading: false })
      if (response.status === 401) {
      } else if (response.status === 200) {
        const pagination = Object.assign({}, this.state.pagination)
        pagination.total = response.navigation.total
        this.setState({
          data: response.data,
          pagination
        })
      }
    })
  }

  handleClick = e => {}

  render() {
    let tableColumns = []
    const { getFieldDecorator } = this.props.form
    this.state.columns.forEach(column => {
      tableColumns.push(column)
    })

    tableColumns.push({
      title: 'Operations',
      dataIndex: 'actions',
      key: 'actions',
      priority: 1,
      render: (text, record, index) => {
        return (
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key, keyPath }) => {
                  this.handleActionsMenu(item, key, keyPath, record._id)
                }}>
                <Menu.Item key="2">
                  <Link to={{ pathname: `/home/salary/view/${record._id}` }}>
                    <Icon style={{ marginRight: '8px' }} type="eye" />
                    View
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={{ pathname: `/home/salary/edit/${record._id}` }}>
                    <Icon style={{ marginRight: '8px' }} type="edit" />
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Icon style={{ marginRight: '8px' }} type="delete" />
                  Delete
                </Menu.Item>
              </Menu>
            }>
            <Button style={{ marginLeft: 8, border: 0 }}>
              <Icon type="bars" /> <Icon type="down" />
            </Button>
          </Dropdown>
        )
      }
    })

    return (
      <StyledSalaryList theme={this.props.theme}>
        <Row>
          <Icon
            className="filter-icon"
            type="filter"
            title="Filter"
            onClick={this.toggleFilter}
          />
          {/*           <div style={{float:'right', display:'inline-flex'}}>
            <span style={{marginRight:'10px', lineHeight:'30px'}}>Columns:</span>
            <Select 
            defaultValue={columns.filter(value => value.show === true).map(column => column.key)} 
            onChange={this.onChangeSelectColumns} 
              mode='multiple' 
              className="hide-show-column-select"
              placeholder="Columns"
              style={{
                width:'140px', 
                display:'inline-table'
              }}
            >      
              {columnsOptions}
            </Select>
          </div> */}
        </Row>
        <Card
          style={{
            margin: '16px 0px',
            display: this.state.showFilter ? 'block' : 'none'
          }}
          bodyStyle={{ padding: '15px' }}>
          <Form onSubmit={this.handleSubmit}>
            <Tag>FILTERS</Tag>
            <Row>
              <Col xs={24} sm={4}>
                <FormItem>
                  {getFieldDecorator(
                    'payType',
                    {}
                  )(
                    <Select
                      optionFilterProp="children"
                      showSearch
                      disabled={this.props.view}
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .replace(/,/g, '')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pay Type">
                      <Select.Option key="monthly" value={'monthly'}>
                        Monthly
                      </Select.Option>
                      <Select.Option key="annually" value={'annually'}>
                        Annually
                      </Select.Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col xs={24} sm={4}>
                <FormItem>
                  {getFieldDecorator(
                    'salary',
                    {}
                  )(
                    <InputNumber
                      disabled={this.props.view}
                      placeholder="Salary"
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} sm={4}>
                <FormItem>
                  {getFieldDecorator(
                    'currency',
                    {}
                  )(
                    <Select
                      showSearch
                      disabled={this.props.view}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .replace(/,/g, '')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Currency">
                      <Select.Option key="1" value="1">
                        AED
                      </Select.Option>
                      <Select.Option key="2" value="2">
                        USD
                      </Select.Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col xs={24} sm={4}>
                <FormItem colon={false}>
                  {getFieldDecorator(
                    'employee',
                    {}
                  )(
                    <Select
                      optionFilterProp="children"
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .replace(/,/g, '')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Employee">
                      {this.state.config
                        ? this.state.config['employee'].map((employee, i) => {
                            return (
                              <Select.Option
                                key={i}
                                value={
                                  employee._id
                                }>{`${employee.firstName} ${employee.lastName}`}</Select.Option>
                            )
                          })
                        : null}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col xs={24} sm={3}>
                <FormItem colon={false}>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                </FormItem>
              </Col>
              <Col xs={24} sm={3}>
                {this.state.params && this.state.params.filter ? (
                  <FormItem colon={false}>
                    <Button type="default" onClick={this.resetSearch}>
                      Reset
                    </Button>
                  </FormItem>
                ) : null}
              </Col>
            </Row>
          </Form>
        </Card>
        <Card>
          <Table
            dataSource={this.state.data}
            columns={tableColumns}
            loading={this.state.loading}
            rowKey="_id"
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            component={
              <Link to="/home/salary/add">
                <Button type="primary">Add</Button>
              </Link>
            }
          />
        </Card>
      </StyledSalaryList>
    )
  }
}

export default withRouter(Form.create()(SalaryList))
