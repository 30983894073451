import React, { Component } from 'react'
import { Row, Col, Tabs, Divider, Tag, Spin, Modal, Button, Icon, Popconfirm, message } from 'antd'
import style from 'styled-components'
import { withRouter } from 'react-router-dom'
import { themes } from '../../utils/style-utils'
import Map from './MapComponent'
import request from '../../utils/requestWrapper' 

const TabPane = Tabs.TabPane

let InfoColumn = props => (
  <Col xs={24} sm={12}>
    <div className="info-group">
      <Tag>{props.title}</Tag>
      <span>{props.info}</span>
    </div>
  </Col>
)

let StyledProfileModal = style.div`
  height:100%;
  & .ant-tabs-vertical{
    display:flex;
    flex-direction:row;
    height:100%;
  }
  & .map-margin{
      margin-bottom:30px;
  }
  & .ant-tabs-vertical>div.ant-tabs-content.ant-tabs-content-animated{
    padding:1.5em;
  }
  & .ant-tabs-tab-active.ant-tabs-tab{
    background-color:#FFFFFF;
  }
  & .ant-tabs-content.ant-tabs-content-animated{
    background-color:#FFFFFF;
    width:100%;
    overflow-y: scroll;
  }
  & .ant-tag{
    color:${props => themes[props.theme].textAndIcons};
    border-color:${props => themes[props.theme].primary};
    background:${props => themes[props.theme].primary};
    margin:.5em 0;
    min-width:120px;
  }
  & .info-group{
    margin:0 .5em;
  }
  & .ant-tabs-bar{
    border:none;
  }
  & .profession-tabs .ant-tabs-tab-active.ant-tabs-tab{
    border-bottom:2px solid #1890ff;
  }
  & .big-avatar{
    width:100px;
    height:100px;
    margin:0;
    border-radius:0;
    margin-right:35px;
  }
  & .small-avatar{
    width:40px;
    height:40px;
    border-radius:50px;
    margin-left:1em;
  }
  & .image-label{
    font-weight:bold;
    margin-left:1em;
  }
  h3{
    font-weight:bold;
    font-size:20px;
  }
  & .profession-tabs{
    height:100% !important;
  }
  & .ant-row{
    margin:1em 0;
  }
  & .info-group{
    display:flex;
    flex-direction:column;
    span{
      font-weight:bold;
      font-size:14px;
    }
  }

  .images-files {
    display: flex;
    flex-direction: row;
    margin-right: 1em;
    flex-wrap: wrap;
    align-items: center;
  }
  .images-files .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    margin-bottom: 0 !important;
  }
  .delete-icon {
    cursor: pointer;
  }
  .images-files > div {
    margin-right: 1em;
  }

  .images-files img {
    height: 102px;
    width: 102px;
    margin-bottom: 1em;
   cursor: pointer;
  }
  @media screen and (min-width: 768px) {
    & .ant-tag{
      align-self:flex-start;
    }
  }
`

let StyledModalImage = style.div`
img {
   width: 100%;
}
`
class EmployeeProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: null,
      visibleImagesArray: false,
      visibleImageDocArray: false,
      loading: false,
      iconLoading: false
    }

    this.getImages = this.getImages.bind(this)
    this.showModal = this.showModal.bind(this)
    this.handleCancelModal  = this.handleCancelModal.bind(this)
    this.isSendReport       = this.loadFVPermissions()
  }

  loadFVPermissions() {
    const arPermissions = JSON.parse(localStorage.permissions);
    // resourceCode
    for (let i=0; i<arPermissions.length; i++) {
      if (arPermissions[i].resourceCode == "FV-LEAD" && (arPermissions[i].actions).indexOf("get-all") >= 0 &&
      (arPermissions[i].actions).indexOf("patch") >= 0) {
        return true;
      }
    }

    return false;
  }

  componentDidMount() {
    this.getImages(this.state.employee.images, 'imagesArray')
    this.getImages(this.state.employee.imageDoc, 'imageDocArray')
  }

  componentWillMount() {
    let { profile } = this.props.location.state
    this.setState({ employee: profile })
  }

  async getImages(array, key) {
    this.setState({ [`${key}Loading`]: true })
    let i = 0
    const newArray = []
    for (i = 0; i < array.length; i++) {
      let endPoint = `/my-drive/file/${array[i]}`
      const response = await request(endPoint)
      if (response.status === 200) {
        newArray.push({ id: response.data._id, url: response.data.url })
      }
    }
    this.setState({ [key]: newArray, [`${key}Loading`]: false })
  }

  showModal(images, url) {
    this.setState({
      [`visible${images}`]: true,
      [`${images}Url`]: url
    })
  }

  handleCancelModal(images) {
    this.setState({ [`visible${images}`]: false })
  }

  authorizeReport = async () => {
    this.setState({ iconLoading: true})
    let endPoint = `/fv-lead/${this.state.employee._id}/send-report`
    const response = await request(endPoint, 'POST');

    if (response.status == 200) {
      message.success(response.message)
    }
    else {
      message.error(response.message)
    }
    
    this.setState({ iconLoading: false})
    return;
  }

  render() {
    let {
      employee,
      imagesArray,
      imageDocArray,
      imagesArrayLoading,
      imageDocArrayLoading
    } = this.state
    const markers = [
      {
        ...employee,
        name: `${employee.firstName} ${employee.lastName}`
      }
    ]

    return (
      <StyledProfileModal theme={this.props.theme}>
        <Tabs
          tabBarStyle={{ border: 'none' }}
          defaultActiveKey="1"
          tabPosition={'right'}>
          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">PERSONAL INFO</span>
                <span className="show-mobile">PI</span>
              </div>
            }
            key="1">
            <Row className="flex-row">
            {(this.state.employee.status == 'awaiting' && this.isSendReport) ? (<Popconfirm
                title="Do you want to send report?"
                onConfirm={this.authorizeReport}
                onCancel={() => {return;}}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: '8px' }} type="primary" loading={this.state.iconLoading}>
                  <Icon type="caret-right" theme="filled" /> Send Report
                </Button>
              </Popconfirm>) : ''}
            </Row>
            <div style={{ height: '1500px' }}>
              <h3>PERSONAL INFORMATION</h3>
              <Row>
                <InfoColumn title="NAME" info={employee.name} />
                <InfoColumn title="AGENT NAME" info={[employee.createdBy.firstName, employee.createdBy.middleName, employee.createdBy.lastName].join(' ')} />
              </Row>
              <Row>
                <InfoColumn title="CIF NUMBER" info={employee.cifNumber} />
                <InfoColumn
                  title="FEEDBACK"
                  info={
                    employee.assessmentByAgent &&
                    employee.assessmentByAgent.feedback
                  }
                />
                <InfoColumn title="EMAIL" info={employee.email} />

                <InfoColumn
                  title="PERSONAL EMAIL"
                  info={employee.personalEmail}
                />
              </Row>
              <Row>
                <InfoColumn title="FAMILY IN UAE" info={employee.familyInUAE} />
                <InfoColumn title="Employee Count" info={employee.cntEmp} />
              </Row>
              <Row>
                <InfoColumn title="SEGMENT TYPE" info={employee.segmentType} />

                <InfoColumn title="OFFICE TYPE" info={employee.officeType} />
              </Row>
              <Row>
                {employee.personMet && (
                  <div>
                    <InfoColumn
                      title="PERSON TYPE"
                      info={employee.personMet.type}
                    />
                    <InfoColumn
                      title="PERSON MET DESIGNATION"
                      info={employee.personMet.designation}
                    />
                  </div>
                )}
                <InfoColumn title="PRODUCT TYPE" info={employee.productType} />
              </Row>
              <Row>
                {employee.verified === 'Yes' && (
                  <InfoColumn title="VERIFIED" info={employee.verified} />
                )}
                {employee.reason && (
                  <InfoColumn title="REASON" info={employee.reason} />
                )}
              </Row>
              <Row>
                <InfoColumn title="ADDRESS TYPE" info={employee.addressType} />
                {employee.addressType === 'new' && employee.address && (
                  <div>
                    <InfoColumn
                      title="ADDRESS 1"
                      info={employee.address.line1}
                    />
                    <InfoColumn
                      title="ADDRESS 2"
                      info={employee.address.line2}
                    />
                    <InfoColumn
                      title="ADDRESS 3"
                      info={employee.address.line3}
                    />
                    <InfoColumn
                      title="EMIRATE"
                      info={employee.address.emirate}
                    />
                  </div>
                )}
                <InfoColumn title="EMPLOYEE NAME" info={employee.employer} />
              </Row>
              <Row>
                <InfoColumn title="INDUSTRY" info={employee.industry} />
                <InfoColumn title="SECTOR" info={employee.sector.name} />
              </Row>
              <Row>
                <InfoColumn title="OFFICE TYPE" info={employee.officeType} />
                <InfoColumn title="VISIT TYPE" info={employee.visitType} />
              </Row>
              <Row>
                <InfoColumn
                  title="OFFICE LANDLINE"
                  info={employee.officeLandline}
                />
                <InfoColumn title="HAS BRANCH" info={employee.hasBranch} />
              </Row>
              <Row>
                {employee.facilities && (
                  <div>
                    <InfoColumn
                      title="HOUSING"
                      info={employee.facilities.housing}
                    />
                    <InfoColumn
                      title="TRANSPORTATION"
                      info={employee.facilities.transportation}
                    />
                    <InfoColumn
                      title="UNIFORM"
                      info={employee.facilities.uniform}
                    />
                    <InfoColumn
                      title="VARIABLE PAY"
                      info={employee.facilities.variablePay}
                    />
                  </div>
                )}
              </Row>
              <Row>
                <InfoColumn
                  title="AIR TICKET WITH FREQUENCY"
                  info={employee.airTicket}
                />
                <InfoColumn
                  title="AIR TICKET TYPE"
                  info={employee.airTicketType}
                />
              </Row>
              <Row>
                {employee.tradeLicense && employee.tradeLicense.seen && (
                  <div>
                    <InfoColumn
                      title="TRADE LICENSE SEEN"
                      info={employee.tradeLicense.seen}
                    />
                    <InfoColumn
                      title="NEIGHBOURHOOD"
                      info={employee.neighbourhood.feedback}
                    />
                  </div>
                )}
              </Row>
              <Row>
                {employee.tradeLicense && employee.tradeLicense.reason && (
                  <div>
                    <InfoColumn
                      title="TRADE LICENSE REASON"
                      info={employee.tradeLicense.reason}
                    />
                  </div>
                )}
              </Row>
              <Row>
                {employee.assessmentByAgent &&
                  employee.assessmentByAgent.feedback && (
                    <div>
                      <InfoColumn
                        title="AGENT FEEDBACK"
                        info={employee.assessmentByAgent.feedback}
                      />
                      <InfoColumn
                        title="SALARY TRANSFER DATE"
                        info={employee.salaryTransfDate}
                      />
                    </div>
                  )}
              </Row>
              <Row>
                {employee.signBoard && employee.signBoard.hasSignBoard && (
                  <div>
                    <InfoColumn
                      title="HAS SIGNBOARD"
                      info={employee.signBoard.hasSignBoard}
                    />
                  </div>
                )}
              </Row>
              <Row>
                {employee.officeAppearance && (
                  <InfoColumn
                    title="HAS SIGNBOARD"
                    info={employee.officeAppearance}
                  />
                )}

                {employee.residenceRef && employee.residenceRef.name && (
                  <InfoColumn
                    title="REFERENCE NAME"
                    info={employee.residenceRef.name}
                  />
                )}
                {employee.residenceRef && employee.residenceRef.phoneNumber && (
                  <InfoColumn
                    title="REFERENCE PHONE NUMBER"
                    info={employee.residenceRef.phoneNumber}
                  />
                )}
                {employee.residenceRef &&
                  employee.residenceRef.relationship && (
                    <InfoColumn
                      title="REFERENCE PHONE NUMBER"
                      info={employee.residenceRef.relationship}
                    />
                  )}
              </Row>
              <Row>
                {employee.homeCountryRef && employee.homeCountryRef.name && (
                  <InfoColumn
                    title="HOME COUNTRY REFERENCE NAME"
                    info={employee.homeCountryRef.name}
                  />
                )}
                {employee.homeCountryRef &&
                  employee.homeCountryRef.phoneNumber && (
                    <InfoColumn
                      title="HOME COUNTRY REFERENCE PHONE NUMBER"
                      info={employee.homeCountryRef.phoneNumber}
                    />
                  )}
                {employee.homeCountryRef &&
                  employee.homeCountryRef.relationship && (
                    <InfoColumn
                      title="HOME COUNTRY REFERENCE PHONE NUMBER"
                      info={employee.homeCountryRef.relationship}
                    />
                  )}
              </Row>
              <Row>
                {employee.feedback && employee.feedback.findLoc && (
                  <InfoColumn
                    title="TO FIND OFFICE LOCATION"
                    info={employee.feedback.findLoc}
                  />
                )}
                {employee.feedback && employee.feedback.aboutApplicant && (
                  <InfoColumn
                    title="ABOUT APPLICANT"
                    info={String(employee.feedback.aboutApplicant).replace(/\*/g, '\n*')}
                  />
                )}
                {employee.feedback && employee.feedback.aboutCompany && (
                  <InfoColumn
                    title="ABOUT COMPANY"
                    info={String(employee.feedback.aboutCompany).split("*").join("\n*")}
                  />
                )}
                {employee.feedback && employee.feedback.additionalPoints && (
                  <InfoColumn
                    title="ADDITIONAL POINTS"
                    info={employee.feedback.additionalPoints}
                  />
                )}
                {employee.feedback && employee.feedback.overallComment && (
                  <InfoColumn
                    title="OVERALL COMMENTS"
                    info={employee.feedback.overallComment}
                  />
                )}
              </Row>
              <Row>
                {employee.makaniNo && (
                  <InfoColumn title="MAKANI NO" info={employee.makaniNo} />
                )}
                {employee.liability && (
                  <InfoColumn title="Liability" info={employee.liability} />
                )}
              </Row>
              <Row>
                {employee.website && (
                  <div>
                    <InfoColumn
                      title="WEBSITE"
                      info={employee.website.hasWebsite}
                    />
                    {employee.website.hasWebsite &&
                      employee.website.hasWebsite === 'Yes' && (
                        <InfoColumn
                          title="WEBSITE URL"
                          info={employee.website.url}
                        />
                      )}
                  </div>
                )}
              </Row>
              <Row>
                {employee.images.length > 0 && <InfoColumn title="Images" />}
              </Row>
              <Row className="images-files">
                {imagesArrayLoading ? (
                  <Spin />
                ) : (
                  imagesArray &&
                  imagesArray.map(e => {
                    return (
                      <div>
                        <img
                          src={e.url}
                          onClick={() => this.showModal('ImagesArray', e.url)}
                          alt=""
                        />
                        <Modal
                          visible={this.state.visibleImagesArray}
                          width={350}
                          title="Images"
                          onOk={this.handleOk}
                          onCancel={() => this.handleCancelModal('ImagesArray')}
                          footer={null}>
                          <StyledModalImage>
                            <img src={this.state.ImagesArrayUrl} alt="" />
                          </StyledModalImage>
                        </Modal>
                      </div>
                    )
                  })
                )}
              </Row>
              <Row>
                {employee.imageDoc.length > 0 && (
                  <InfoColumn title="Images Docs" />
                )}
              </Row>
              <Row className="images-files">
                {imageDocArrayLoading ? (
                  <Spin />
                ) : (
                  imageDocArray &&
                  imageDocArray.map(e => {
                    return (
                      <div>
                        <img
                          src={e.url}
                          onClick={() => this.showModal('ImageDocArray', e.url)}
                          alt=""
                        />

                        <Modal
                          visible={this.state.visibleImageDocArray}
                          title="ImagesDoc"
                          onOk={this.handleOk}
                          onCancel={() =>
                            this.handleCancelModal('ImageDocArray')
                          }
                          footer={null}>
                          <StyledModalImage>
                            <img src={this.state.ImageDocArrayUrl} alt="" />
                          </StyledModalImage>
                        </Modal>
                      </div>
                    )
                  })
                )}
              </Row>
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">JOB INFO</span>
                <span className="show-mobile">JI</span>
              </div>
            }
            key="2">
            <h3>JOB INFORMATION</h3>

            <Row>
              <InfoColumn
                title="EMPLOYEMENT CONFIRMED"
                info={employee.employmentConfirmed}
              />
              <InfoColumn
                title="EMPLOYEE DESIGNATION"
                info={employee.designation}
              />
            </Row>
            <Row>
              {employee.loan && employee.loan.purpose && (
                <InfoColumn title="PURPOSE" info={employee.loan.purpose} />
              )}

              <InfoColumn title="OFFICER EMAIL" info={employee.officerEmail} />
            </Row>

            <Row>
              {employee.loan && employee.loan.amount && (
                <InfoColumn title="PURPOSE" info={employee.loan.amount} />
              )}
              {employee.monthlyIncome &&
                employee.monthlyIncome.isMonthlyIncomeVerified && (
                  <InfoColumn
                    title="IS MONTHLY INCOME VERIFIED"
                    info={employee.monthlyIncome.isMonthlyIncomeVerified}
                  />
                )}
              {employee.monthlyIncome &&
                employee.monthlyIncome.isMonthlyIncomeVerified === 'Yes' && (
                  <InfoColumn
                    title="MONTHLY INCOME AMOUNT"
                    info={employee.monthlyIncome.amount}
                  />
                )}
            </Row>
            <Row>
              <InfoColumn
                title="SALARY TRANS MODE"
                info={employee.salaryTransMode ? employee.salaryTransMode : ''}
              />
              <InfoColumn
                title="Date Of Join"
                info={employee.workingSince ? new Date(employee.workingSince).toDateString() : ''}
              />
            </Row>
          </TabPane>
          <TabPane
            tab={
              <div className="tab-title">
                <span className="show-desktop">ADDRESS INFO</span>
                <span className="show-mobile">AI</span>
              </div>
            }
            key="4">
            {employee.address && (
              <div style={{ height: 900 }}>
                <h3>ADDRESS</h3>
                <Row>
                  <InfoColumn
                    title="ADDRESS TYPE"
                    info={employee.addressType}
                  />
                  <InfoColumn title="Line1" info={employee.address.line1} />
                  <InfoColumn title="Line1" info={employee.address.line2} />

                  <InfoColumn title="Line1" info={employee.address.line3} />
                  <InfoColumn title="Line1" info={employee.address.emirate} />
                </Row>
                {employee.homeCountryAddress && (
                  <div>
                    <Divider />
                    <h3>HOME COUNTRY ADDRESS</h3>
                    <Row>
                      <InfoColumn
                        title="line 1"
                        info={employee.homeCountryAddress.line1}
                      />
                      <InfoColumn
                        title="line 2"
                        info={employee.homeCountryAddress.line2}
                      />
                    </Row>
                    <Row>
                      <InfoColumn
                        title="Land Mark"
                        info={employee.homeCountryAddress.landmark}
                      />
                    </Row>
                    <Row>
                      <InfoColumn
                        title="Contact Number"
                        info={employee.homeCountryAddress.contactNumber}
                      />

                      <InfoColumn
                        title="Country"
                        info={employee.homeCountryAddress.country}
                      />
                    </Row>
                  </div>
                )}
                <Row>
                  <Map markers={markers} className="map-margin" />
                </Row>
              </div>
            )}

            {employee.social && (
              <div>
                <Divider />
                <h3>SOCIAL</h3>
                <Row>
                  <InfoColumn title="FACEBOOK" info={employee.social.fb} />
                  <InfoColumn title="TWITTER" info={employee.social.twitter} />
                </Row>
                <Row>
                  <InfoColumn
                    title="LINKED IN"
                    info={employee.social.linkedin}
                  />
                  <InfoColumn
                    title="GOOGLE PLUS"
                    info={employee.social.googleplus}
                  />
                </Row>
              </div>
            )}
          </TabPane>
        </Tabs>
      </StyledProfileModal>
    )
  }
}
export default withRouter(EmployeeProfile)
